import { StylesProvider } from "@material-ui/core/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface StyledProps {
  isError?: any;
}

interface Props {
  Options: any;
  label?: string;
  defaultText?: string;
  defaultIndexValue?: number;
  name: string;
  valueKey?: string;
  triggerEmptyField?: string;
  divStyle?: any;
  tooltip?: any;
  children?: React.ReactNode;
  filterCondition?: string[];
  renderAddBtn?: React.ReactNode;
  sendValuesToParent?: (values: any) => void | undefined;
  variant?: "object";
  enableSearch?: true;
  onChange?: (value: any) => void;
  requiredSign?: boolean;
  isDisabled?: boolean;
  popOverMaxWidth?: string;
  [otherProps: string]: any;
  isInModel?: boolean;
  labelStyle?: any;
  onScroll?: any;
  disableOnChange?: boolean;
  displayLabel?: any;
  placeholder?: string;
  inputStyle?: any;
}

const AppFormSelectField: React.FC<Props> = ({
  Options,
  label,
  name,
  defaultText = "Select an option",
  renderAddBtn,
  sendValuesToParent,
  filterCondition = [""],
  divStyle,
  defaultIndexValue,
  tooltip,
  valueKey = "value",
  triggerEmptyField,
  onChange,
  enableSearch,
  variant,
  requiredSign,
  popOverMaxWidth = "600px",
  isDisabled,
  isInModel,
  labelStyle,
  onScroll,
  disableOnChange,
  displayLabel,
  placeholder,
  inputStyle ={},
  ...otherProps
}) => {
  const containsText = (option: any, searchText: string) => {
    if (option?.name) {
      return (
        option?.name?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1
      );
    }
    if (option?.question) {
      return (
        option?.question?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1
      );
    } if (option?.label) {
      return (
        option?.label?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1
      );
    }
    else {
      return option;
    }
  };

  const {
    setFieldTouched,
    errors,
    touched,
    values,
    setFieldValue
  } = useFormikContext<any>();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const fieldRef: any = useRef<any>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const displayedOptions = useMemo(
    () =>
      Options?.length
        ? Options?.filter((option: any) => containsText(option, searchText))
        : [],
    [searchText, Options]
  );
  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebouncedSearchText(searchText);
    });

    return () => {
      clearTimeout(debounceId);
    };
  }, [searchText]);
  useEffect(() => {
    document?.getElementById("searchText")?.focus();
  }, [debouncedSearchText]);

  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    disablePortal: true,
    PaperProps: {
      onScroll: onScroll,
      sx: {
        minWidth: `${fieldRef?.current?.offsetWidth}px !important`,
        marginTop: "4px",
        maxWidth: `${popOverMaxWidth} !important`,
        maxHeight: "350px !important",
        boxShadow: `0px 2px 4px rgb(0 0 0 / 10%) !important`,
        transition:
          "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
        "& ul": {
          padding: "6px 0rem 4px",
        },
        "& .MuiMenuItem-root": {
          padding: "4px 1rem",
        },
        "& li": {
          fontSize: "13px !important",
          minHeight: "30px",
          lineHeight: "22px",
          marginBottom: "2px",
          color: "#282828",
        },
      },
    },
  };
  useEffect(() => {
    if (getIn(values, name)) {
      if (variant === "object") {
        const selectedOption = Options.find((option: any) => {
          return option?.[valueKey] === getIn(values, name)?.[valueKey];
        });
        if (selectedOption) {
          setFieldValue(name, selectedOption);
          return;
        }
      }
    } else {
      setFieldValue(name, 0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof defaultIndexValue === "number") {
      setFieldValue(name, Options[defaultIndexValue]?.value);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValueChange = (event: any) => {
    setFieldTouched(name, true);
    if (triggerEmptyField) {
      setFieldValue(triggerEmptyField, 0);
    }
    if (variant === "object") {
      const selectedOption = Options.find((option: any) => {
        return option?.[valueKey] === event.target.value;
      });
      if (selectedOption) {
        setFieldValue(name, selectedOption);
        return;
      }
    } else {
      setFieldValue(name, event.target.value);
      if (onChange) {
        onChange(event.target.value);
      }
    }
  };

  return (
    <StylesProvider injectFirst>
      <Div className="dropdown-label" style={divStyle} ref={fieldRef}>
        <Label style={labelStyle}>
          {requiredSign ? (
            <>
              {label}
              <sup className="starSuperScript"> *</sup>
            </>
          ) : (
            label
          )}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        <StyledSelectInput
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onFocus={() => {
            setIsFocus(true);
            setSearchText("");
          }}
          onBlur={() => {
            setFieldTouched(name, true);
            setIsFocus(false);
            return;
          }}
          onChange={(e: any) => {
            setFieldValue(name, e.target.value);
            setSearchText("");
            if (onChange && !disableOnChange) {
              onChange(e.target.value);
            }
            return;
          }}
          placeholder={placeholder}
          defaultValue={0}
          value={getIn(values, name) ? getIn(values, name) || 0 : 0}
          style={{
            border: isFocus
              ? `1px solid ${theme.palette.default.border}`
              : getIn(errors, name) && getIn(touched, name)
                ? `1px solid  ${theme.palette.default.error}`
                : `1px solid ${theme.palette.default.border}`,
          }}
          disabled={isDisabled}
          MenuProps={!isInModel ? menuProps : {}}
          autoWidth={false}
          fullWidth={true}
          height = {inputStyle?.height ? inputStyle?.height : "28px"}
          {...otherProps}
        >
          {enableSearch && (
            <ListSubheader>
              <TextField
                size="small"
                id="searchText"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                className="searchbox"
                InputProps={
                  {
                    // startAdornment: (
                    //   <InputAdornment position="start">
                    //     <SearchIcon />
                    //   </InputAdornment>
                    // )
                  }
                }
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
          )}
          <StyledMenuItem value={0} disabled={true}>
            <p style={{ opacity: "51%" }}>
              {displayedOptions && displayedOptions?.length
                ? defaultText
                : "No Records Found"}
            </p>
          </StyledMenuItem>

          {renderAddBtn}

          {displayedOptions &&
            displayedOptions?.length > 0 &&
            displayedOptions.map((curElem: any, index: any) => {
              return (
                <StyledMenuItem
                  value={
                    curElem?.value
                      ? curElem?.value
                      : curElem?.id
                        ? curElem?.id
                        : curElem?.qid
                          ? curElem?.qid
                          : curElem?.name
                            ? curElem?.name
                            : curElem?.display_value
                  }
                  key={index}
                  disabled={
                    filterCondition?.includes(curElem.value) ? true : false
                  }
                  title={
                    curElem?.name
                      ? curElem?.name
                      : curElem?.value
                        ? curElem?.value
                        : curElem?.id
                          ? curElem?.id
                          : curElem?.question
                            ? curElem?.question
                            : curElem?.qid
                              ? curElem?.qid
                              : curElem?.display_value
                  }
                >
                  {displayLabel
                    ? curElem[displayLabel]
                    : curElem?.name
                      ? curElem?.name
                      : curElem?.label
                        ? curElem?.label
                        : curElem.question
                          ? curElem.question
                          : curElem?.display_value}
                </StyledMenuItem>
              );
            })}
        </StyledSelectInput>
        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </Div>
    </StylesProvider>
  );
};

export default AppFormSelectField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    gap: 8px;
    position: relative;
    margin-bottom: 0.5rem;
    sup {
      color: ${theme.palette.default.error};
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    width: 100%;
    display: block;
    whitespace: no-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSelectInput = styled(Select) <StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 0 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 2.4rem;

    position: relative;
    .searchbox {
      border: 1px solid #d1d1d1;
      border-radius: 5px;
      margin-bottom: 10px !important;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      /* border: 2px solid ${theme.palette.default.border} !important; */
      color: ${theme.palette.default.text};
    }
    &:focus {
      /* border: 2px solid ${theme.palette.default.border} !important; */
      color: ${theme.palette.default.text};
    }

    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 1px solid ${theme.palette.default.lightGrey};
    }

    .Mui-disabled {
      // opacity: 0.5;
      .MuiSelect-icon {
        display: none !important;
      }
    }

    .css-1ja9cqb-MuiSvgIcon-root-MuiSelect-icon,
    .css-n9yzhl-MuiSvgIcon-root-MuiSelect-icon {
      color: ${theme.palette.default.border};
      font-size: 1.2rem;
      margin-left: 2rem;
    }

    fieldset {
      border: none !important;
      outline: none !important;
      display: none !important;
    }
    legend {
      display: none !important;
    }
    // input {
    //   display: none !important;
    // }

    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
      // padding-left: 0px !important ;
      // padding: 0px 0px !important;
      padding: 0px 32px 0px 0px;
    }
  }
`;

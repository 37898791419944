import * as Yup from "yup";
export const ONLY_CHARACTER_REGEX =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð 0-9]+$/u;


export const EmailDomainValdiation = Yup.object().shape({
    domain: Yup.string().required().label("Email"),
})
export const EmailVerifyValidation = Yup.object().shape({
    emailAddress: Yup.string().required().label("Email"),
})
export const EmailDefaultValidation = Yup.object().shape({
    view_options: Yup.lazy((val: any) => {
        return (Array.isArray(val) ? Yup.array().min(1, "Please select atleast one option") : Yup.string().required())
    })

})
const MAX_FILE_SIZE = 102400; //100KB

const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

export const EmailCreateBroadcast = Yup.object().shape({
    broadcast_name: Yup.string()
        .min(1)
        .max(60)
        .required()
        .label("Broadcast Name"),
    subject: Yup.string()
        .min(1)
        .max(150)
        .required()
        .label("Email Subject"),

    from_email: Yup.string().min(2, "Please Select an From Email").required().label("From Email"),
    // email_cc: Yup.string().min(2, "Please Select an email").required().label("from_email"),
    // email_bcc: Yup.string().min(2, "Please Select an email").required().label("from_email"),
    footer: Yup.string().min(2, "Please Select an footer").required().label("Footer"),
    reply_to: Yup.string().email().label("Reply to")


})
export const EmailTemplateList = Yup.object().shape({
    broadcast_template_id: Yup.string().min(2, "Please Select an Template").required(),

})
export const EmailBroadcastScheduleStep2Schema = Yup.object().shape({
  broadcast_action: Yup.mixed()
        .required("Action is required")
        .label("Action"),
    
      formkey8: Yup.object().shape({
        totalContacts: Yup.number()
          .required("Total contacts is required")
          .min(1, "At least one contact must be present"),
      }),
    
      marketing_list: Yup.string()
        .ensure()
        .label("List")
        .when("broadcast_action", {
          is: (value :any) => value === "broadcast_marketing_list",
          then: (schema :any) => schema.required("List is a required field"),
          otherwise: (schema:any) => schema.optional(),
        }),
});

export const EmailBroadcastScheduleStep5Schema = Yup.object().shape({
    when_to_send: Yup.mixed().required().label("Action"),
    date: Yup.mixed()
        .label("Date")
        .when(["when_to_send"], (key1: any, schema: any) => {
            return key1?.value === "schedule_broadcast"
                ? schema.required()
                : schema.notRequired();
        }),
    time: Yup.mixed()
        .label("Time")
        .when(["when_to_send"], (key1: any, schema: any) => {
            return key1?.value === "schedule_broadcast"
                ? schema.required()
                : schema.notRequired();
        }),
});
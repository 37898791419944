import { Badge, Box, Checkbox, debounce, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Typography } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import { customizedFilter } from "../../Components/Broadcast/Utils/utils";
import { HocBackdropLoader } from "../../HOC/HocBackdropLoader";
import useDebounce from "../../Hooks/useDebounce";
import AppModel from "../../Hooks/useModel/AppModel";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { MarketingListNetworkService } from "../../Store/Slices/MarketingList/MarketingListNetworkService";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../Store/Slices/MarketingList/marketingList.selectors";
import {
  setMarketingListPageNumber,
  updateAllMarketingFilteredData,
  updateMarketingFilteredData,
  updateMarketingListSelecetedRowCount,
} from "../../Store/Slices/MarketingList/marketingList.slice";
import { AppMaterialIcons } from "../AppMaterialIcons";
import { LoaderComponentProps } from "../Loader";
import UploadMarketingData from "../UploadMarketingData/UploadMarketingData";
import AppErrorMessage from "./AppErrorMessage";
import AppFormSelectField from "./AppFormSelectField";
import AppFormToggleButtonFastField from "./FastField/AppFormToggleButtonFastField";
import { ErrorComponent, StyledTableToolbar } from "./Styles";
import AppPaginationComponent from "../Pagination/Pagination";
import { convertQueryToMongo, initialQuery } from "../QueryBuilder/utils/utils";
import { ContactListingPagination, ContactsListingData } from "../../Store/Slices/Contacts/contacts.selector";
import { resetContactsListData, setFilterQuery } from "../../Store/Slices/Contacts/contacts.slice";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import { getFilterQuery } from "../../Store/Slices/Contacts/contacts.selector";
import { resetContactPropertiesListData } from "../../Store/Slices/Settings/ContactProperties/contactProperties.slice";
import { DataGrid } from "@mui/x-data-grid";
import { marketColumns, SmsColumns } from "./Utils/Constants";
import { Close, Search } from "@material-ui/icons";
import { broadcastActionToggleOptions } from "../../Components/Broadcast/WhatsApp/Utils/Constants";
import NoData from "../../assets/images/noDatafound.svg";
import AppFormQueryBuilder from "./QueryBuilder/AppFormQueryBuilder";
import AppSkeletonTableLoader from "../../Components/UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
export interface Props extends LoaderComponentProps {
  type: any;
  broadcast_id?: any;
  subscriptionName?: string;

}
const AppMarketingList: React.FC<Props> = ({
  type,
  broadcast_id,
  subscriptionName,
  setLoading,
}) => {
  const formkey1 = "broadcast_action";
  const formkey2 = "marketing_list";
  const formkey3 = "filters";
  const formkey4 = "selected_rows";
  const formkey5 = "search_term";
  const dispatch = useAppDispatch();
  const queryModel: any = useModal();
  const { isShowing, toggle } = useModal();
  const { showAlert } = useNotification();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<any>();
  const {
    allMarketingListData,
    selectedRowCount,
    unsubscribeCount,
  } = useAppSelector(marketingListStore);
  const pagination = useAppSelector(ContactListingPagination);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [filterFieldData, setFilterFieldData] = useState<any>(null);
  const Listdata = useAppSelector(ContactsListingData);
  const [callCondition, setCallCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const filterQuery = useAppSelector(getFilterQuery);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    tags: "",
    search: "",
    perPage: { label: "50 per page", value: 50 },
    currentPage: 0,
    mongoQuery: "",
    filter_query: "all_contacts",
    contact_filter: "all_contacts",
    selected_view: "",
  });
  const [broadCastType] = useState<any>(
    type[0].toUpperCase() + type.slice(1) || ""
  );
  const debouncedSearchTerm = useDebounce<string>(
    getIn(values, formkey5),
    1000
  );
  useEffect(() => {
    setFieldTouched(formkey4, false);
    dispatch(marketingListActions.getAllMarketingListings(type));
    if (
      getIn(values, formkey1) === "broadcast_marketing_list" &&
      getIn(values, formkey2)
    ) {
      let abc: any = customizedFilter(filterQuery);
      let temp: any = {
        list_id: getIn(values, formkey2),
        broadcast_id: getIn(values, "id") || broadcast_id?.id,
        queryset: abc?.rules?.length > 0 ? abc : {},
        search: debouncedSearchTerm,
        limit: localValues?.perPage?.value,
        offset: localValues?.currentPage,
      };
      getMarketingTableData(temp);
    }
    return () => {
      dispatch(setMarketingListPageNumber(0));
    };
  }, []);
  useEffect(() => {
    if (localValues) {
      let abc: any = customizedFilter(filterQuery);
      let temp: any = {
        list_id: getIn(values, formkey2),
        broadcast_id: values?.id || broadcast_id?.id,
        queryset: abc?.rules?.length > 0 ? abc : {},
        search: debouncedSearchTerm,
        limit: localValues?.perPage?.value,
        offset: localValues?.currentPage,
      };
      getMarketingTableData(temp);
    }
  }, [localValues]);
  useEffect(() => {
    setCallCondition(true)
    setFieldValue('formkey8', {
      totalContacts: 0
    });
    if (values?.broadcast_action === "broadcast_to_contacts" && localValues) {
      setFieldValue(formkey4, 0)
      dispatch(resetContactPropertiesListData({}))
      if (filterQuery?.rules?.length === 0) {
        dispatch(setFilterQuery(initialQuery));
        getAllContactData(true)
      } else {
        getAllContactData(false)
      }
    } else {
      if (values?.marketing_list) {
        dispatch(marketingListActions.getAllMarketingListings(type));
        getAllContactData(false)
      }
    }
  }, [localValues, values?.broadcast_action])
  const handleSelectSearch = useCallback(
    debounce((e: any, field: any) => {
      setCallCondition(true)
      setLocalValues((prevValues: any) => ({
        ...prevValues,
        [field]: e.target.value,
        currentPage: 0
      }));
    }, 700),
    [] // The empty dependency array ensures the debounce function is not recreated on every render
  );
  useEffect(() => {
    if (debouncedSearchTerm?.length) {
      dispatch(setMarketingListPageNumber(0));
      let abc: any = customizedFilter(filterQuery);
      let temp: any = {
        list_id: getIn(values, formkey2),
        broadcast_id: values?.id || broadcast_id?.id,
        queryset: abc?.rules?.length > 0 ? abc : {},
        search: debouncedSearchTerm,
        limit: localValues?.perPage?.value,
        offset: localValues?.currentPage,
      };
      getMarketingTableData(temp);
    }
  }, [debouncedSearchTerm]);
  const getMarketingTableData = (temp: any) => {

  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      perPage: value,
      currentPage: 0,
    });
  };
  const clearCallback = (e?: any, field?: any) => {
    setInputValue('')
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };
  const onSelectChangeCallbackFn = async (value: any) => {
    const whatsappSubscriptionFilter: any = {
      combinator: "and",
      id: "45634-453345-345",
      rules: [
        {
          id: "marketing_list",
          field: "marketing_list",
          operator: "in",
          valueSource: "value",
          value: [value],
        },
      ],
    };
    await dispatch(setFilterQuery(whatsappSubscriptionFilter));
    setFieldValue(formkey3, whatsappSubscriptionFilter);

    const mongoQuery = convertQueryToMongo(whatsappSubscriptionFilter);
    setLocalValues({
      ...localValues,
      mongoQuery: mongoQuery,
    });

    setFieldValue("mongoQuery", mongoQuery);
    getMarketingTableData(whatsappSubscriptionFilter);
    setMenuIsOpen(true);
  };
  const handleResetFields = () => {
    dispatch(setMarketingListPageNumber(0));
    setFieldValue(formkey3, null);
    setFieldValue(formkey4, 0);
    setFieldTouched(formkey4, false);
    setFieldValue(formkey5, "");
    setHasMoreData(true);
    if (getIn(values, formkey1) === "broadcast_to_contacts") {
      setFieldValue(formkey2, "");
    }
  };
  const onUploadMarketingChangeCallbackFn = (value: any) => {
    setLoading(true);
    dispatch(marketingListActions.getAllMarketingListings(type)).finally(() => {
      handleResetFields();
      setTimeout(() => {
        MarketingListNetworkService.getFieldsTypeMappingList(
          value,
          getIn(values, "id") || broadcast_id?.id
        );
        setFieldValue(formkey2, value);
        onSelectChangeCallbackFn(value);
      }, 200);
    });
  };
  const handleUpdateCheckboxStatus = (temp: any, index?: boolean | number) => {
    MarketingListNetworkService.updateSelectedContactItem(temp)
      .then((res: any) => {
        setLoading(false);
        dispatch(updateMarketingListSelecetedRowCount(res?.data?.count));
        setFieldValue(formkey4, res?.data?.count);
        if (typeof index === "number") {
          dispatch(updateMarketingFilteredData(index));
        }
        if (typeof index === "boolean") {
          dispatch(updateAllMarketingFilteredData(index));
        }
      })
      .catch((error: any) => { })
      .finally(() => { });
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(formkey4, false);
    setLoading(true);
    if (event.target.checked) {
      let temp = {
        id: "all",
        list_id: getIn(values, formkey2),
        broadcast_id: getIn(values, "id") || broadcast_id?.id,
        data: {
          isSelected: true,
        },
      };
      handleUpdateCheckboxStatus(temp, true);
      return;
    }
    let temp = {
      list_id: getIn(values, formkey2),
      broadcast_id: getIn(values, "id") || broadcast_id?.id,
      id: "all",
      data: {
        isSelected: false,
      },
    };
    handleUpdateCheckboxStatus(temp, false);
  };
  const handleFilterBtnClick = () => {
    MarketingListNetworkService.getFieldsTypeMappingList(
      getIn(values, formkey2),
      getIn(values, "id") || broadcast_id?.id
    ).then((res: any) => {
      setFilterFieldData(res?.data?.response);
      if (res?.data?.response?.fields?.length) {
        queryModel?.toggle(true);
      } else {
        showAlert("Filters not found", "error");
      }
    });
  };
  const clearSelectMarketingList = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleSelectAllClick(event);
    setFieldValue(formkey2, "");
    setFieldValue(formkey4, 0);
    dispatch(setMarketingListPageNumber(0));
  };
  const [inputValue, setInputValue] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const getAllContactData = (reset?: boolean, filtersValue?: any) => {
    if (reset) {
      dispatch(setFilterQuery(initialQuery));
      setFieldValue(formkey3, initialQuery)
      setFieldValue('marketing_list', '')
    }
    setTableLoading(true)
    setSelectionModel([])
    let payload = {
      "data": {
        search: localValues?.search,
        page_number: localValues?.currentPage,
        page_size: localValues?.perPage?.value,
        columns: [
          "id",
          "name",
          "first_name",
          "last_name",
          "email",
        ],
        contact_filter: "all_contacts",
        filter_query: filterQuery ? filterQuery : initialQuery,
        mongodb_query: convertQueryToMongo(reset ? initialQuery : filterQuery ? filterQuery : initialQuery),
      }
    }
    setIsLoading(true)
    dispatch(ContactsActions.getContactsListData(payload))
      .then((res: any) => {
        setTableLoading(false)
        setCallCondition(true)
        setFieldValue(formkey4, broadcast_id?.selectedListData?.length);
        if (res?.payload?.data?.pagination?.totalRecords) {
          setFieldValue('formkey8', {
            totalContacts: res.payload?.data?.data?.length
          });
        }
        setIsLoading(false)
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
        setIsLoading(false)
      });
  }
  const onQueryChangeCallbackFn = (filtersValue: any) => {
    if (!filtersValue) {
      return null;
    }
    dispatch(setFilterQuery(filtersValue));
    setFieldValue(formkey4, 0);
    setFieldTouched(formkey4, false);
    setFieldValue(formkey5, "");
    dispatch(setMarketingListPageNumber(0));
    setLocalValues({
      ...localValues,
      currentPage: 0
    })

  };
  const resetAction = () => {
    dispatch(setFilterQuery(initialQuery));
    setLocalValues({
      ...localValues,
      currentPage: 0
    })
  }
  return (
    <>
      <AppFormToggleButtonFastField
        name={formkey1}
        label="Broadcast Action"
        Options={broadcastActionToggleOptions}
        onChangeCallbackFn={resetAction}
      />
      {getIn(values, formkey1) === "broadcast_marketing_list" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              gap: "2rem",
            }}
          >
            {allMarketingListData?.length ? (
              <AppFormSelectField
                name={formkey2}
                label="Marketing List"
                Options={allMarketingListData}
                divStyle={{ maxWidth: "70%", marginBottom: 0 }}
                defaultText="Select a List"
                enableSearch
                onChange={onSelectChangeCallbackFn}
                onClear={clearSelectMarketingList}
                menuIsOpen={menuIsOpen}
              />

            ) : (
              <p>No List Found</p>
            )}
          </div>
          {getIn(values, formkey2) ? (
            <StyledTableToolbar style={{ padding: 0 }}>
              <div className="top">
                <FormControl sx={{ m: 1, width: '347px', height: "40px" }} style={{
                  border: "1px solid lightgray",
                  backgroundColor: "#f0f2f7",
                  borderRadius: "5px",
                  width: "90%",
                  marginLeft: "1px"
                }}
                  variant="outlined">
                  <OutlinedInput
                    sx={{ height: "4ch" }}
                    id="outlined-adornment-weight"
                    placeholder="Search"
                    onChange={(e: any) => { handleSelectSearch(e, "search"); setInputValue(e.target.value) }}
                    endAdornment={
                      <InputAdornment position="end" >
                        <IconButton
                          onClick={(e: any) => clearCallback(e, "search")}
                          edge="end"
                          style={{ marginRight: "0px" }}
                        >
                          {localValues?.search ? <Close /> : <Search />}
                        </IconButton>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
                <IconButton
                  onClick={() => {
                    handleFilterBtnClick();
                    setIsDrawerOpened(true)
                  }}
                >
                  <Badge
                    badgeContent={filterQuery?.rules?.length || 0}
                    color="primary"
                  >
                    <AppMaterialIcons iconName={"FilterList"} />
                  </Badge>
                </IconButton>
              </div>
              <div className="bottom">
                <div className="displayFlex">
                  {unsubscribeCount > 0 && (
                    <p className="toolbarTextred">
                      {unsubscribeCount}
                      &nbsp;unsubscribe count
                    </p>
                  )}
                  {selectedRowCount > 0 && (
                    <p className="toolbarTextgreen">
                      {selectedRowCount}
                      &nbsp; selected
                    </p>
                  )}
                </div>
                <AppErrorMessage
                  error={getIn(errors, formkey4)}
                  visible={getIn(touched, formkey4)}
                />
              </div>
            </StyledTableToolbar>
          ) : null}
          {getIn(values, formkey1) === "broadcast_marketing_list" ?
            Listdata?.length && getIn(values, formkey2) ? <Box sx={{ height: 400, width: "100%", padding: "10px", marginBottom: "50px", "& .MuiDataGrid-footerContainer": { display: "none" }, "& .MuiDataGrid-virtualScroller": { width: '100rem' } }}>
              <DataGrid
                rows={Listdata || []}
                columns={marketColumns}
                loading={tableLoading}
              />
              <AppPaginationComponent
                totalCount={pagination?.totalRecords}
                handlePageChange={handlePageChange}
                currentPage={localValues?.currentPage}
                totalPages={pagination?.totalPages}
                rowsPerPage={localValues?.perPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                title="Contacts"
              />
            </Box> : (
              isLoading ?
                <AppSkeletonTableLoader
                  numberOfCol={4}
                  numberofRow={10}
                  skeletonHeight={30}
                  skeletonWidth={"100%"}
                  skeletonHeaderHeight={40}
                  skeletonHeaderWidth={"100%"}
                  tableHeight={`calc(100vh - 518px)`}
                />
                :
                <ErrorComponent style={{ height: "225px" }}>
                  <Box>
                    <img loading="lazy" src={NoData} width="150" alt="" />
                  </Box>
                  <Box className="err-content">
                    <Typography variant="h6" fontSize="20px">
                      No Records Found.
                    </Typography>
                  </Box>
                </ErrorComponent>
            ) : (
              (
                <ErrorComponent style={{ height: "225px" }}>
                  <Box>
                    <img loading="lazy" src={NoData} width="150" alt="" />
                  </Box>
                  <Box className="err-content">
                    <Typography variant="h6" fontSize="20px">
                      No Records Found.
                    </Typography>
                  </Box>
                </ErrorComponent>
              )
            )}
        </>
      ) : null}
      {getIn(values, formkey1) === "broadcast_to_contacts" ? (
        <>
          <div style={{
            alignItems: "end",
            gap: "2rem",
          }}>
            <div className="top">
              <FormControl sx={{ m: 1, width: '347px', height: "40px" }} style={{
                border: "1px solid lightgray",
                backgroundColor: "#f0f2f7",
                borderRadius: "5px",
                width: "90%",
                marginLeft: "1px"
              }}
                variant="outlined">
                <OutlinedInput
                  sx={{ height: "4ch" }}
                  id="outlined-adornment-weight"
                  placeholder="Search"
                  // value={inputValue}
                  onChange={(e: any) => { handleSelectSearch(e, "search"); setInputValue(e.target.value) }}

                  endAdornment={
                    <InputAdornment position="end" >
                      <IconButton
                        onClick={(e: any) => clearCallback(e, "search")}
                        edge="end"
                        style={{ marginRight: "0px" }}
                      >
                        {localValues?.search ? <Close /> : <Search />}
                      </IconButton>
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
              </FormControl>
              <IconButton
                onClick={() => {
                  handleFilterBtnClick();
                  setIsDrawerOpened(true)
                }}
              >
                <Badge
                  badgeContent={filterQuery?.rules?.length || 0}
                  color="primary"
                >
                  <AppMaterialIcons iconName={"FilterList"} />
                </Badge>
              </IconButton>
            </div>
            {Listdata?.length ? (
              <>
                <Box sx={{ height: 410, width: "100%", marginBottom: "50px", "& .MuiDataGrid-footerContainer": { display: "none" }, "& .MuiDataGrid-virtualScroller": { width: '100rem' } }}>
                  <DataGrid
                    rows={Listdata || []}
                    columns={SmsColumns}
                    initialState={{}}
                    selectionModel={selectionModel}
                    loading={tableLoading}
                  />
                </Box>
              </>
            ) : (
              isLoading ?
                <AppSkeletonTableLoader
                  numberOfCol={4}
                  numberofRow={10}
                  skeletonHeight={30}
                  skeletonWidth={"100%"}
                  skeletonHeaderHeight={40}
                  skeletonHeaderWidth={"100%"}
                  tableHeight={`calc(100vh - 518px)`}
                /> : <ErrorComponent style={{ height: "225px" }}>
                  <Box>
                    <img loading="lazy" src={NoData} width="150" alt="" />
                  </Box>
                  <Box className="err-content">
                    <Typography variant="h6" fontSize="20px">
                      No Records Found.
                    </Typography>
                  </Box>
                </ErrorComponent>
            )}
          </div>
          <div >
            <AppPaginationComponent
              totalCount={pagination?.totalRecords}
              handlePageChange={handlePageChange}
              currentPage={localValues?.currentPage}
              totalPages={pagination?.totalPages}
              rowsPerPage={localValues?.perPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              title="Contacts"
            />
          </div>
        </>
      ) : null}
      {filterFieldData?.fields?.length ? (
        <AppFormQueryBuilder
          name={formkey3}
          isShowing={queryModel?.isShowing}
          toggle={queryModel?.toggle}
          fields={filterFieldData?.fields}
          operators={filterFieldData?.type_mapping_data}
          onChangeCallbackFn={onQueryChangeCallbackFn}
          options={filterFieldData?.options}
          type={type}
          broadcast_id={undefined}
        />
      ) : null}
      <AppModel
        isShowing={isShowing}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "1000px",
          },
        }}
      >
        <AppModel
          isShowing={isShowing}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "1000px",
            },
          }}
        >
          <UploadMarketingData
            toggle={toggle}
            isShowing={isShowing}
            onChangeCallbackFn={onUploadMarketingChangeCallbackFn}
            type={broadCastType}
          />
        </AppModel>
      </AppModel>
    </>
  );
};

export default HocBackdropLoader(AppMarketingList);

import { useFormikContext } from "formik";
import styled from "styled-components";
import { AppFormField } from "../../../../../../AppComponents/Forms";
import AppFormToggleButtonField from "../../../../../../AppComponents/Forms/AppFormToggleButtonField";
import AppFormCheckboxField from "../../../../../../AppComponents/Forms/AppFormCheckboxField";
import { useEffect } from "react";

const AuthenticationTemplate = (props: any) => {
  const { disabled } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  useEffect(() => {
    setFieldValue(
      "footer_value",
      `This Code expires in ${values?.code_expiration_minutes} minutes.`
    );
  }, [values?.code_expiration_minutes]);
  return (
    <Container>
      <AppFormCheckboxField
        name={"add_security_recommendation"}
        label="Add Security Recommendation"
        onChange={(e: any) => {
          if (e.target.checked) {
            setFieldValue(
              "body_value",
              "{{1}} is your verification code. For your security, do not share this code."
            );
            setFieldValue(
              "footer_value",
              "For your security, do not share this code."
            );
          } else {
            setFieldValue("body_value", "{{1}} is your verification code.");
            setFieldValue(
              "footer_value",
              ""
            );
          }
        }}
      />

      <AppFormField
        name="code_expiration_minutes"
        label="Code Expiration Minutes"
        placeholder="Enter code expiration minutes"
        charactersLimit={2}
        disabled={disabled}
        requiredSign
      />
    </Container>
  );
};

export default AuthenticationTemplate;

const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
    .url_container {
      display: flex;
      gap: 1rem;
    }
  }
`;
const TextContent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .heading {
      font-size: 15px;
      margin: 0;
    }
    .content {
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      color: rgba(126, 131, 146, 1);
    }
  }
`;

const LimitedOfferVariablesContainer = styled.div`
  && {
    display: flex;
    gap: 1rem;
    align-items: start;
    width: 70%;
    p {
      margin-top: 0.8rem;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

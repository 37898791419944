import style from "styled-components";
import { theme } from "../../../Customization/Theme";

export const Wrapper = style.div`
 display: flex;
 align-items: stretch;
 height: calc(100vh - 125px);
 #minimap-container {
    width: 200px; /* or any appropriate size */
    height: 200px;
    border: 1px solid #ccc; /* optional */
    position: absolute;
    bottom: 10px;
    right: 10px;
    svg {
    overflow: visible;
   }
}
 * {
    user-select: none;
    font-family: "Rubik"
 }
 #paper-container {
    width: calc(100% - 275px);
    min-height: 100%;
    background: red;
    .joint-theme-default {
      height: auto !important;
      min-height: 100%;
    }
    svg {
      min-height: calc(100vh - 132px);
    }
  .red text,.red foreignObject div,.red circle,.red path {
   fill: #d36363;
   color: #d36363 !important;
  }
  .shake .card-box{
   // animation: shake 500ms ease-out;
   animation: 0.5s linear 0s slide 1;
 }

   @keyframes slide {
      0% { transform: translateX(0) }
      5% { transform: translateX(15px) }
      10% { transform: translateX(-15px) }
      15% { transform: translateX(15px) }
      20% { transform: translateX(-15px) }
      30% { transform: translateX(15px) }
      40% { transform: translateX(-15px) }
      50% { transform: translateX(15px) }
      60% { transform: translateX(-15px) }
      75% { transform: translateX(15px) }
      100% { transform: translateX(0) }
   }
 }
 #flyPaper {
   .stencil-item {
      .stencil-icon {
         top: 29px;
         left: 56px;
         transform: translateX(0%);
      }
   }
 }
 .remove-tool-icon:hover {
    circle {
        stroke: #b3d4ff;
        fill: #deebff;
        path {
            stroke: #1f62ff;
        }
    }
 }
 .MuiAccordionDetails-root {
   padding: 8px 0;
 }
 #stencil-container {
   border-top: 1px solid #d1d1d1;
   padding: 8px 0;
   margin-top: 80px;
 }
 .sideBar {
   width: 275px;
   min-height: 100%;
   background-color: ${theme.palette.default.white} !important;
   overflow: auto;
   .header {
      padding: 16px;
      border-bottom: 0px;
      border-top: 1px solid #d1d1d1;
      p {
         color: #505f79;
         font-size: 16px;
      }
   }
   h3 {
      font-size: 20px;
      line-height: 32px;
   }
   .MuiAccordionDetails-root {
      display: flex;
      flex-wrap: wrap;
   }
   .MuiAccordionSummary-content {
      margin: 10px 0;
      cursor: pointer;
      p {
         font-weight: bold;
         font-size: 1rem;
      }
   }
   .MuiAccordionSummary-root {
      min-height: 45px;
      background-color: #EBECF0;
   }
   .Mui-expanded {
      margin: 0;
   }
   .stencil-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      text-align: center;
      padding: 15px;
      width: 135px;
      p {
         font-size: 14px;
         line-height: 20px;
         margin-top: 10px;
         font-weight: 500;
      }
      * {
      pointer-events: none;
      }
      .stencil-icon {
          position: absolute;
          transform: translateX(-50%);
         margin:auto;
          svg {
            width: 25px;
          }
      }
   }
   .triggers-wrapper {
      .stencil-icon {
         top: 26px;
         left: 70px;
     }
   }
  
   .conditions-wrapper {
      .stencil-icon {
         top: 25px;
         left: 68px;
     }
   }
   .action-wrapper {
      .stencil-icon {
         top: 27px;
         left: 70px;
     }
   }
 }
`;

export const WaningContainer = style.div`
 .modal_container_header{
   padding:10px 0;
   text-align:center;
   font-size:20px;
   font-weight:500;
 }
 .text{
   font-size: 16px;
   line-height: 24px;
   color:${theme.palette.default.black};
   margin: 0;
 }
 .icon{
   margin: 0 auto;
 }
`;

export const IconContainer = style.div`
   text-align:center;
   width:50px;
   height:50px;
   padding:10px;
   background: rgb(33,28,82);
   border-radius: 50%;
   margin:0 auto;
   display:flex;
   align-items:center;
   justify-content:center;
`;

import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class WhatsappNetworkService {
  static getWhatsappTemplatesListings(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.WHATSAPP_TEMPLATE_LISTINGS}`, data
    );
  }

  static getTemplateById(id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.WHATSAPP_TEMPLATE_DATA_BY_ID}${id}`
    );
  }
  static getBroadcastById(id: string) {
    return http.post(`${consoleBaseUrl}${Endpoints.BROADCAST_BY_ID}/${id}`, {});
  }

  static getFieldsTypeMappingList(id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_FIELDS_TYPE_MAPPING_LIST}?list_id=${id}`
    );
  }

  static getContactListData() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_CONTACT_LIST_DATA}`);
  }

  static postDummyContactData(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.POST_DUMMY_CONTACT_DATA}`,
      data
    );
  }

  static updateSelectedContactItem(data: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_SELECTED_CONTACT_SINGLE_ITEM}`,
      data
    );
  }

  static postFilteredData(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.FILTERED_DATA}`, data);
  }

  static postSelectedListTable(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.POST_SELECTED_LIST_TABLE}`, payload);
  }

  static getExistingTempData(id: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_EXISTING_TEMP_DATA}/${id}`
    );
  }

  static postWhatsappBroadcast(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CREATE_WHATSAPP_BROADCAST}`,
      data
    );
  }
  static updateWhatsappBroadcast(data: any, id: string) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_WHATSAPP_BROADCAST}/${id}/update-broadcast`,
      data
    );
  }

  static postTestWhatsappMessage(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.TEST_WHATSAPP_MESSAGE}`, data);
  }

  static deleteWhatsappBroadcast(data: any) {
    const { id } = data
    const type = {
      type: data.type
    }
    return http.post(
      `${consoleBaseUrl}${Endpoints.DELETE_WHATSAPP_BROADCAST
      }/${id}`, type
    );
  }

  static sendWhatsappMessage(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.SEND_WHATSAPP_MESSAGE}`,
      data
    );
  }
}

//*whatsapp *//
class Endpoints {

  static GET_CONTACT_LIST_DATA = "/workspace/contact-list-data/";

  static POST_DUMMY_CONTACT_DATA = "/workspace/add-broadcast-list-temp-data";


  static FILTERED_DATA = "/workspace/filter_list_data";


  static GET_EXISTING_TEMP_DATA = "/workspace/broadcast-temp-data-list";
  static POST_SELECTED_LIST_TABLE = "/workspace/add-broadcast-data";


  static BROADCAST_BY_ID = "/workspace/all-whatsapp-broadcast";
  static TEST_WHATSAPP_MESSAGE = "/workspace/test-whatsapp/send"



  //new endpoints for node microservice
  static WHATSAPP_ATTACHMENT = "/workspace/broadcast/whatsapp-media-attach/";
  static WHATSAPP_TEMPLATE_LISTINGS = "/workspace/broadcast/whatsapp-template-list";
  static WHATSAPP_TEMPLATE_DATA_BY_ID = "/workspace/broadcast/whatsapp-templateById/";
 // static CREATE_WHATSAPP_BROADCAST = "/workspace/broadcast/create-whatsapp-broadcast";
  static UPDATE_WHATSAPP_BROADCAST = "/workspace/broadcast/whatsapp";
  static CREATE_WHATSAPP_BROADCAST = "/workspace/broadcast/whatsapp/create-broadcast";

  static UPDATE_SELECTED_CONTACT_SINGLE_ITEM = "/workspace/broadcast/update-broadcast-list-temp-data";
  static GET_FIELDS_TYPE_MAPPING_LIST = "/workspace/broadcast/fields_type_mapping_list";
  static DELETE_WHATSAPP_BROADCAST = "/workspace/broadcast/delete-broadcast-by-id";


  static SEND_WHATSAPP_MESSAGE = "/workspace/send-whatsapp-single-contact";

}

import { getIn, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { CreditDetailsActions } from "../../../../../Store/Slices/CreditDetails/CreditDetails.actions";
import { whatsappDataStore } from "../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import WhatsappBroadcastResolveVaribles from "../../../../Leads/contact-action/utils/WhatsappBroadcastResolveVaribles";
import { dataToTemplateFormData } from "../../Utils/utils";
import { convertQueryToMongo, initialQuery } from "../../../../../AppComponents/QueryBuilder/utils/utils";
import { getFilterQuery } from "../../../../../Store/Slices/Contacts/contacts.selector";
import { LoaderComponentProps } from "../../../../../AppComponents/Loader";

interface Props {
  formStep: number;
  sizeErrorMsg?: string;
  formatErrorMsg?: string;
  setFormStep: Dispatch<SetStateAction<number>>;
  label?: string;
  children?: React.ReactNode;
  mediaType: string;
  [otherProps: string]: any;
  onValueChange?: (res: any) => void;
  onFileChange?: (res: any) => void;
  setLoading?:any
}

const RenderFormStep3: React.FC<Props> = ({
  formStep,
  setFormStep,
  mediaType
}) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext<any>();
  const { templateByIdData }: any = useAppSelector(whatsappDataStore);
  const filterQuery = useAppSelector(getFilterQuery);

  // useEffect(() => {
  //   dispatch(CreditDetailsActions.getBroadcastCreditDetails(getIn(values, "id")))
  // }, [formStep]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //     const payload = {
  //       broadcast_template_id: values?.template?.template_id || "",
  //         mongodb_query: convertQueryToMongo(filterQuery),
  //       search: "",
  //     };
  //     dispatch(CreditDetailsActions.getBroadcastCreditDetails(payload));
  //   }, [formStep, values]);

  useEffect(() => {
    const broadcastTemplateId = values?.template?.template_id?.trim() || "";
  
    if (!broadcastTemplateId) {
      return;
    }
  
    const payload = {
      broadcast_template_id: broadcastTemplateId,
      mongodb_query: convertQueryToMongo(filterQuery ? filterQuery : initialQuery),
      search: "",
    };
    dispatch(CreditDetailsActions.getBroadcastCreditDetails(payload))
    .then((res) => {
    })
  }, [formStep, values?.template?.template_id, filterQuery]);

  async function fetchData() {
    const data = { ...await dataToTemplateFormData(templateByIdData?.original_response), components: templateByIdData?.original_response?.components };
    setFieldValue("template", data);
  }
  useEffect(() => {
    fetchData();
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <WhatsappBroadcastResolveVaribles applyValue={ values?.resolved_body_variables?.length > 0 ||
      values?.resolved_buttons_variables?.length > 0 ||
      values?.resolved_carousel_variables?.length > 0 ||
      values?.resolved_header_variables?.length > 0
    }/>
  );
};

export default RenderFormStep3;



import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class CreditDetailsNetworkService {


    // * get Broadcast Credit details  *//

    // static getBroadcastCreditDetails(id: any) {
    //     return http.get(`${consoleBaseUrl}${Endpoints.GET_BROADCAST_CREDIT_DETAILS}/${id}`);
    //   }

    static getBroadcastCreditDetails(data: any) {
        return http.post(
          `${consoleBaseUrl}${Endpoints.GET_BROADCAST_CREDIT_DETAILS}`,
          data, );
    }
static getBroadcastCreditDetailssms(data: any) {
        return http.post(
          `${consoleBaseUrl}${Endpoints.GET_BROADCAST_CREDIT_DETAILS_SMS}`,
          data,);
    }
    static getBroadcastCreditDetailsemail(data: any) {
      return http.post(
        `${consoleBaseUrl}${Endpoints.GET_BROADCAST_CREDIT_DETAILS_EMAIL}`,
        data,
      ); 
    }
}

//*Broadcast credit details endpoints *//

class Endpoints {

    //new endpoints for node microservice
   // static GET_BROADCAST_CREDIT_DETAILS = "/workspace/broadcast/broadcast-credits-calculation";
    static GET_BROADCAST_CREDIT_DETAILS = "/workspace/broadcast/whatsapp/calculate-cost";
    static GET_BROADCAST_CREDIT_DETAILS_SMS = "/workspace/broadcast/sms/calculate-cost";
    static GET_BROADCAST_CREDIT_DETAILS_EMAIL = "/workspace/broadcast/email/calculate-cost";

}

import uniqid from "uniqid";
import * as Yup from "yup";
export const BROADCAST_WHATSAPP_FORM_DATA = "BROADCAST_WHATSAPP_FORM_DATA";
export const BROADCAST_SMS_FORM_DATA = "BROADCAST_SMS_FORM_DATA";

const VARIABLE_REGEX = /\{{([^)]+)\}}/gm;

export const broadcastActionToggleOptions: IToggleFieldOptions[] = [
  {
    name: "Broadcast to Contacts",
    value: "broadcast_to_contacts",
    icon: "Group",
    id: uniqid(),
    disabled: false,
  },
  {
    name: "Broadcast Marketing list",
    value: "broadcast_marketing_list",
    icon: "BallotOutlined",
    id: uniqid(),
    disabled: false,
  },
];
export const broadcastSendOptions: any[] = [
  {
    name: "Broadcast now",
    value: "broadcast_now",
    id: uniqid(),
  },
  {
    name: "Schedule Broadcast",
    value: "schedule_broadcast",
    id: uniqid(),
  },
];
export const CheckboxTableColumns: any = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "name",
  },
  {
    id: "age",
    numeric: true,
    disablePadding: false,
    label: "Age",
  },
  {
    id: "number",
    numeric: true,
    disablePadding: false,
    label: "number",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
];

export const whatsappDefaultCategoryOptions = [
  {
    id: uniqid(),
    name: "Marketing",
    value: "marketing",
    description: null,
  },
];
export const whatsappDefaultLanguageOptions = [
  {
    id: uniqid(),
    name: "English",
    value: "en",
    description: null,
  },
];

export const MediaObj: any = ["image", "document", "video"];

export const mediaVarible: any = [
  {
    key: "{{1}}",
    value: "",
    index: 0,
    type: "media",
  },
];

export const whatsappTemplateHeaderForButtonsToggleOptions: IToggleFieldOptions[] =
  [
    {
      name: "None",
      value: "NONE",
      icon: "HighlightOff",
      id: uniqid(),
    },
    {
      name: "Text",
      value: "TEXT",
      icon: "DescriptionOutlined",
      id: uniqid(),
    },
    {
      name: "Image",
      value: "IMAGE",
      icon: "ImageOutlined",
      id: uniqid(),
    },
    {
      name: "Video",
      value: "VIDEO",
      icon: "SlideshowOutlined",
      id: uniqid(),
    },
    {
      name: "Document",
      value: "DOCUMENT",
      icon: "InsertDriveFileOutlined",
      id: uniqid(),
    },
  ];

export const whatsappTemplateHeaderNoneToggleOptions: IToggleFieldOptions[] = [
  {
    name: "None",
    value: "NONE",
    icon: "HighlightOff",
    id: uniqid(),
  },
];

export const whatsappTemplateHeaderForLTOToggleOptions: IToggleFieldOptions[] =
  [
    {
      name: "None",
      value: "NONE",
      icon: "HighlightOff",
      id: uniqid(),
    },
    {
      name: "Image",
      value: "IMAGE",
      icon: "ImageOutlined",
      id: uniqid(),
    },
    {
      name: "Video",
      value: "VIDEO",
      icon: "SlideshowOutlined",
      id: uniqid(),
    },
  ];

export const whatsappTemplateButtonTypeOptions: any[] = [
  {
    name: "None",
    value: "none",
    id: uniqid(),
  },
  {
    name: "Quick replies",
    value: "quick_reply",
    id: uniqid(),
  },
  {
    name: "Call to action",
    value: "call_to_action",
    id: uniqid(),
  },
];

export const whatsappDefaultButtonObject: IWhatsappDefaultButtonType = {
  action_type: null,
  button_text: "Click now",
  url_type: null,
  url: "",
  phone_number: "",
};

export const whatsappCtaActionType = [
  {
    id: uniqid(),
    name: "Visit Website",
    value: "URL",
    description: null,
  },
  {
    id: uniqid(),
    name: "Call Phone",
    value: "PHONE_NUMBER",
    description: null,
  },
];
export const whatsappCtaValueAction = [
  {
    id: uniqid(),
    name: "Static",
    value: "static",
    description: null,
  },
  {
    id: uniqid(),
    name: "Dynamic",
    value: "dynamic",
    description: null,
  },
];

export const WhatsappCreateTemplateSchema = Yup.object().shape({
  template_name: Yup.string()
    .ensure()
    .matches(/^[a-z0-9_]+$/, {
      message:
        "Only Lowercasae characters, numbers, and underscores are allowed",
      excludeEmptyString: true,
    })
    .required()
    .min(2)
    .max(100)
    .label("Name"),
  template_category: Yup.string()
    .ensure()
    .required()
    .min(2, "Category is a required field")
    .label("Category"),
  button_type: Yup.string()
    .ensure()
    .required("Type is a required field")
    .min(2, "Button Type is a required field")
    .label("Type"),
  template_language: Yup.string()
    .ensure()
    .required()
    .min(2, "Language is a required field")
    .label("Language"),
  header_type: Yup.mixed().required().label("Header type"),
  header_value: Yup.mixed()
    .label("Header value")
    .when(["header_type"], (key1: any, schema: any) => {
      let data = typeof key1 === "object" ? key1?.value : key1;
      return data === "NONE"
        ? schema.notRequired()
        : schema.required(`${key1} is a required field.`);
    }),
  body_value: Yup.string().when("template_category", {
    is: "AUTHENTICATION",
    then: (schema: any) => schema.notRequired(),
    otherwise: (schema: any) =>
      schema
        .required()
        .min(9, "Body Cannot be empty")
        .max(1024, "Body cannot exceed 1024 characters")
        .label("Body"),
  }),
  footer_value: Yup.string().ensure().max(60).label("Footer"),
  offer_title: Yup.string()
    .ensure()
    .max(16)
    .label("Offer Title")
    .when("button_type", {
      is: "limited_time_offer",
      then: Yup.string().required("Offer Title is required"),
      otherwise: Yup.string(),
    }),
  offer_button_text: Yup.string()
    .ensure()
    .max(25)
    .label("Offer Button text")
    .when("button_type", {
      is: "limited_time_offer",
      then: Yup.string().max(25).required("Offer coupon text is required"),
      otherwise: Yup.string(),
    }),
  offer_coupon_code: Yup.string()
    .ensure()
    .matches(
      /^(|[a-zA-Z0-9_]+)$/,
      "Offer Coupon Code should contain only alphanumeric value"
    )
    .max(15)
    .label("Offer Coupon code")
    .when("button_type", {
      is: "limited_time_offer",
      then: Yup.string().required("Offer coupon code is required"),
      otherwise: Yup.string(),
    }),
  offer_url: Yup.string()
    .ensure()
    .when("button_type", {
      is: "limited_time_offer",
      then: Yup.string()
        .matches(
          /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?(\?[^\s]*)?$/,
          "Should be a valid URL"
        )
        .label("Offer url")
        .required("Offer url is required"),
      otherwise: Yup.string(),
    }),
  coupon_code: Yup.string()
    .ensure()
    .matches(
      /^(|[a-zA-Z0-9_]+)$/,
      "Coupon Code should contain only alphanumeric value"
    )
    .max(15)
    .label("Coupon code")
    .when("copyUrlOptions", {
      is: (copyUrlOptions: any) =>
        copyUrlOptions?.some(
          (option: any) => option.name === "add_coupon_code" && option.checked
        ),
      then: Yup.string().required("Coupon code is required"),
      otherwise: Yup.string(),
      header_text: Yup.array().ensure(),
      body_text: Yup.array().ensure(),
      footer_text: Yup.array().ensure(),
      buttons_example: Yup.array().ensure(),
      phone_number: Yup.string().when("copyUrlOptions", {
        is: (copyUrlOptions: any) =>
          copyUrlOptions?.some(
            (option: any) =>
              option.name === "add_phone_number" && option.checked
          ),
        then: Yup.string().required("Phone number is required"),
      }),
    }),
  phone_number_button_text: Yup.string().when("copyUrlOptions", {
    is: (copyUrlOptions: any) =>
      copyUrlOptions?.some(
        (option: any) => option.name === "add_phone_number" && option.checked
      ),
    then: Yup.string().required("Phone number button text is required"),
  }),
  website_url: Yup.array().when("copyUrlOptions", {
    is: (copyUrlOptions: any) =>
      copyUrlOptions.some(
        (option: any) => option.name === "add_website_url" && option.checked
      ),
    then: Yup.array()
      .of(
        Yup.object().shape({
          button_text: Yup.string().required("Button text is required"),
          url_type: Yup.string().required("URL type is required"),
          url: Yup.string()
            .matches(
              /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?(\?[^\s]*)?$/,
              "Should be a valid URL"
            )
            .required("URL is required"),
        })
      )
      .required("Website URL is required"),
    otherwise: Yup.array().of(
      Yup.object().shape({
        button_text: Yup.string(),
        url_type: Yup.string(),
        url: Yup.string().url("Invalid URL"),
      })
    ),
  }),
  quick_replies: Yup.array().when("copyUrlOptions", {
    is: (copyUrlOptions: any) =>
      copyUrlOptions.some(
        (option: any) => option.name === "add_quick_replies" && option.checked
      ),
    then: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string().required("Button Type is required"),
          text: Yup.string().required("Button Text is required"),
        })
      )
      .required("Quick replies are required"),
    otherwise: Yup.array().of(
      Yup.object().shape({
        type: Yup.string(),
        text: Yup.string(),
      })
    ),
  }),

  flow: Yup.array()
    .of(
      Yup.object().shape({
        flow_name: Yup.string().required("Flow Name is required"),
        name: Yup.string().required(),
        flow_id: Yup.string().required(),
        navigate_screen: Yup.string().required(),
        text: Yup.string().required("Flow Button Name is required"),
      })
    )
    .when("button_type", {
      is: "flow",
      then: Yup.array()
        .min(1, "At least one flow object is required")
        .required("Flow is required"),
      otherwise: Yup.array(),
    }),
  copyUrlOptions: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        label: Yup.string().required(),
        checked: Yup.boolean().required(),
      })
    )
    .test(
      "at-least-one-checked",
      "At least one option must be checked",
      function (value: any, context: any) {
        const { button_type } = context.parent;
        if (button_type === "copy_code_url_quick_replies_etc") {
          return value.some((option: any) => option.checked);
        }
        return true;
      }
    ),
  variables_header: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(1)
    .label("Header variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }
      const parentArray =
        typeof ctx.parent?.header_value === "string"
          ? ctx.parent?.header_value?.match(/\{\{([1-9]|10)\}\}/gm)
          : [];
      if (!parentValues) {
        return true;
      }
      const isValid = parentArray?.filter((ele: any, index: number) => {
        if (
          parentValues?.header_text.findIndex((ele: any) =>
            ele.includes((index + 1).toString())
          ) === -1
        ) {
          return ele;
        }
      });
      let variableLength: number = parentValues?.header_text?.length || 0;
      if (isValid?.length) {
        return new Yup.ValidationError(
          `Header variables are not in a correct order.`,
          null,
          "headerVariables"
        );
      } else if (variableLength === value?.length) {
        return true;
      } else {
        if (parentValues?.header_type === "text") {
          return new Yup.ValidationError(
            `Header variables sample data is a required field`,
            null,
            "headerSampleData"
          );
        } else {
          return true;
        }
      }
    }),
  variables_body: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(10)
    .label("Body variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      const parentArray = ctx.parent?.body_value?.match(/\{\{([1-9]|10)\}\}/gm);
      if (!parentValues) {
        return true;
      }
      const isValid = parentArray?.filter((ele: any, index: number) => {
        if (
          parentValues?.body_text.findIndex((ele: any) =>
            ele.includes((index + 1).toString())
          ) === -1
        ) {
          return ele;
        }
      });
      let variableLength: number = parentValues?.body_text?.length || 0;
      if (variableLength === 0) {
        return true;
      }
      if (isValid?.length) {
        return new Yup.ValidationError(
          `Body variables are not in a correct order.`,
          null,
          "bodyVariables"
        );
      } else if (variableLength === value?.length) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Body variables sample data is a required field`,
          null,
          "bodySampleData"
        );
      }
    }),
  variables_footer: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(1)
    .label("Footer variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }

      let variableLength: number = parentValues?.footer_text?.length || 0;
      if (variableLength === value?.length) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Footer variables sample data is a required field`,
          null,
          "form"
        );
      }
    }),
  variables_button: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(1)
    .label("Button variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }

      let variableLength: number = parentValues?.buttons_example?.length || 0;
      if (variableLength === value?.length) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Button variables sample data is a required field`,
          null,
          "form"
        );
      }
    }),
  //carousel
  carousel: Yup.array().of(
    Yup.object().shape({
      card_header: Yup.object().test({
        name: "card_header",
        exclusive: true,
        message: "Required",
        test: function (value: any) {
          const { button_type } = this.options.context;
          if (button_type === "carousel") {
            return value;
          }
          return true;
        },
      }),
      text: Yup.string().test({
        name: "card_header",
        exclusive: true,
        message: "card body required",
        test: function (value: any) {
          const { button_type } = this.options.context;
          if (button_type === "carousel") {
            return value;
          }
          return true;
        },
      }),
      carousel_buttons: Yup.array()
        .of(
          Yup.object().shape({
            sub_type: Yup.string(),
            url: Yup.string().when("sub_type", {
              is: (sub_type: any) =>
                sub_type === "static" || sub_type === "dynamic",
              then: Yup.string().required("URL is required"),
              otherwise: Yup.string(),
            }),
            text: Yup.string().when("sub_type", {
              is: (sub_type: any) =>
                sub_type === "static" || sub_type === "dynamic",
              then: Yup.string().required("Button Text is required."),
              otherwise: Yup.string(),
            }),
            buttons_type: Yup.string(),
            quick_reply: Yup.string().when("buttons_type", {
              is: "QUICK_REPLY",
              then: Yup.string().required("Quick reply is required"),
              otherwise: Yup.string(),
            }),
          })
        )
        .test({
          name: "at-least-one-button",
          exclusive: true,
          message: "At least one button is required",
          test: function (value: any) {
            const { button_type } = this.options.context;
            if (button_type === "carousel") {
              return value && value.length > 0;
            }
            return true;
          },
        }),
    })
  ),
  // code_expiration_minutes: Yup.number()
  //   .typeError("Code expiration must be a number.") // Error if the input is not a number
  //   .when("button_type", {
  //     is: "AUTHENTICATION",
  //     then: Yup.number().min(1).max(90),
  //     otherwise: Yup.number(),
  //   }),
  code_expiration_minutes: Yup.number()
    .typeError("Code expiration must be a number.") // Error if the input is not a number
    .when("button_type", {
      is: "AUTHENTICATION",
      then: Yup.number()
        .min(1, "Code expiration must be at least 1 minute.")
        .max(90, "Code expiration cannot exceed 90 minutes.")
        .required("Code expiration is required for authentication."),
      otherwise: Yup.number()
        .typeError("Code expiration must be a number.")
        .nullable(), // Optional for other button types
    }),
});

export const WhatsappBroadcastScheduleStep1Schema = Yup.object().shape({
  broadcast_name: Yup.string()
    .ensure()
    .required()
    .min(3)
    .max(100)
    .label("Name"),
  broadcast_template_id: Yup.string()
    .ensure()
    .required()
    .min(2, "Template is a required field")
    .label("Template"),
});

export const WhatsappBroadcastScheduleStep2Schema = Yup.object().shape({
  broadcast_action: Yup.mixed()
    .required("Action is required")
    .label("Action"),

  formkey8: Yup.object().shape({
    totalContacts: Yup.number()
      .required("Total contacts is required")
      .min(1, "At least one contact must be present"),
  }),

  marketing_list: Yup.string()
    .ensure()
    .label("List")
    .when("broadcast_action", {
      is: (value :any) => value === "broadcast_marketing_list",
      then: (schema :any) => schema.required("List is a required field"),
      otherwise: (schema:any) => schema.optional(),
    }),
});
export const WhatsappBroadcastScheduleStep3Schema = Yup.object().shape({
  resolved_body_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .min(2, "Field is a required field")
          .max(60)
          .required()
          .label("Field"),
        value: Yup.string().ensure().min(1).max(60).required().label("Value"),
      })
    )
    .label("Body variables"),
  eligiable_contact: Yup.number().required().min(1,"Please select atleast 1 audience.").label("Contact"),
  resolved_header_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .max(60)
          .label("Field")
          .when(["type"], (key1: any, schema: any) => {
            return key1 !== "text"
              ? schema.optional().notRequired()
              : schema.required().min(2, "Field is a required field");
          }),
        value: Yup.mixed()
          .required()
          .label("Value")
          .test("mediavaluetest", null, (currentValue: any, ctx: any) => {
            // let temp = Object?.keys(currentValue)?.length || 0;
            if (currentValue && Object?.keys(currentValue)?.length < 1) {
              return new Yup.ValidationError(
                `Please enter valid Mobile number.`,
                null,
                "resolved_header_variables"
              );
            }

            return true;
          }),
      })
    )
    .max(1)
    .label("Header variables"),
  resolved_buttons_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          // .min(2, "Field is a required field")
          .max(60)
          // .required()
          .label("Field")
          .nullable(),
        value: Yup.string().ensure().min(1).max(60).required().label("Value"),
      })
    )
    .max(10)
    .nullable()
    .label("Button variables"),
});

export const WhatsappBroadcastScheduleStep4Schema = Yup.object().shape({
  when_to_send: Yup.mixed().required().label("Action"),
  date: Yup.mixed()
    .label("Date")
    .when(["when_to_send"], (key1: any, schema: any) => {
      return key1?.value === "schedule_broadcast"
        ? schema.required()
        : schema.notRequired();
    }),
  time: Yup.mixed()
    .label("Time")
    .when(["when_to_send"], (key1: any, schema: any) => {
      return key1?.value === "schedule_broadcast"
        ? schema.required()
        : schema.notRequired();
    }),
});

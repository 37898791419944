import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Box,
  IconButton,
  Typography
} from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import AppRangeFutureDatePicker from "../../../../AppComponents/AppRangeFutureDatePicker";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import { AppConfig } from "../../../../Config/app-config";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { CoreService } from "../../../../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import {
  BroadcastTopTitle,
  TableFilter,
  TableWrapper,
} from "../../../../TableStyle";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { LeftCol, RightCol, TopBar } from "../BroadcastHistory/HistoryStyles";
import {
  TemplateListWrapper
} from "../Templates/TemplatesListingCSS";
import { BROADCAST_WHATSAPP_FORM_DATA } from "../Utils/Constants";
import { PageDiv } from "./Styles";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { resetError } from "../../../../Store/Slices/Settings/WhatsappSettings/whatsappSettings.slice";
import { WhatsappActions } from "../../../../Store/Slices/Whatsapp/whatsapp.actions";
import {
  DeleteError,
  DeleteSuccess,
} from "../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import Danger from "../../../../assets/images/danger-live.svg";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { getCurrentTimeZone } from "../../../../Utils";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { DeleteCannedResponse } from "../Templates/TemplatesListing";
import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";

// interface Props extends LoaderComponentProps { }
interface Props { }

const WhatsappScheduledBroadcast: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [errorApi, setErrorApi] = useState(false);
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const has_low_balance = localStorage.getItem("has_low_balance")
  const [status] = useState<string>("scheduled");
  const [isShowingModal, toggleModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const [localValues, setLocalValues] = useState<any>({
    search: "",
    status: "",
    category: "",
    startDate: null,
    endDate: null,
  });
  const [credits, setCredits] = useState<any>([]);

  // Headers For Table
  const header = ["Name", "Recipients", "Scheduled"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Name: "broadcast_name",
    Recipients: "recipients",
    Scheduled: "sent_on",
  };
  // Width For Table Column
  const colWidth: any = {
    Name: "420",
    Recipients: "300",
    Scheduled: "500",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    // const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };
  let { showAlert } = useNotification();
  const deleteErrorMessage = useAppSelector(DeleteError);
  const deleteSuccessMessage = useAppSelector(DeleteSuccess);
  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
  }, []);
  const getPreviousData = () => {
    let startDate = null;
    let endDate = null;
    if (localValues?.startDate && localValues?.endDate) {
      startDate = format(localValues?.startDate, "yyyy-MM-dd");
      endDate = format(localValues?.endDate, "yyyy-MM-dd");
    }
    const payload: any = {
      scheduled_start: startDate,
      scheduled_end: endDate,
      limit: 20,
      offset: 0,
      status: status,
      search: localValues?.search,
      broadcast_type: "whatsapp",
      timezone:getCurrentTimeZone()
    };
    dispatch(marketingListActions.getAllBroadcastData(payload))
      .then((res: any) => {
        setList(res?.payload?.data.results);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousData();
  }, [localValues, currentPage, itemsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  };
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };

  const navigate = useNavigate();
  const editBroadcastTemplate = (id: any, view?: boolean) => {
    if (view) {
      return navigate(`/broadcast/whatsapp/edit-broadcast/1/${id}?view=true`);
    }
    navigate(`/broadcast/whatsapp/edit-broadcast/4/${id}`);
  };
  useEffect(() => {
    if (deleteErrorMessage && deleteErrorMessage?.length > 0) {
      showAlert(deleteErrorMessage, "error");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
    if (deleteSuccessMessage && deleteSuccessMessage?.length > 0) {
      showAlert(deleteSuccessMessage, "success");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
  }, [deleteSuccessMessage, deleteErrorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    CoreService.getCredits().then((response) => {
      const reversedItems = response?.data;
      setCredits(reversedItems);
    });
  }, [localValues]);
  const handleClickDelete = (id: any) => {
    const payload: any = {
      id: id,
      type: "whatsapp",
    };
    dispatch(SMSActions.deleteSmsBroadcastById(payload)).then((res: any) => {
      getPreviousData();
      toggleModal(false);
    });
  };
  return (
    <TemplateListWrapper style={{height: "inherit"}}>
      {/* Top Bar */}
      <TopBar>
        {/* Left Col */}
        <LeftCol>
          <label>To know your Messaging Limit please</label>
          <Box className="limit">
            <a
              href="https://developers.facebook.com/docs/whatsapp/messaging-limits/"
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>
          </Box>
        </LeftCol>

        {/* Right Col */}
        <RightCol>
          <Box className="contact">
            <label>
              <span className="contact-label">Connected WA No.:</span>
              <span>{credits?.whatsapp_no}</span>
            </label>
          </Box>
          <Box>
            <Box className="monthly-conversations__wrapper" >
              <Box className="conversations">
                <Box className="monthly-conversations">
                  <AppToolTip
                    tooltipProps={{
                      title: `Each WhatsApp Business Account gets 1,000 free service conversations (user-initiated conversations) each month across all of its business phone numbers. This number is refreshed at the beginning of each month, based on WhatsApp Business Account time zone. Marketing, utility and authentication conversations are not part of the free tier`,
                      placement: "right",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={<InfoOutlinedIcon />}
                  />
                  <div className="conversations-label">
                    Free Monthly conversations:<strong> {credits?.free_conversation || 0}</strong>
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="credits">
              <Box></Box>
            </Box>
          </Box>
        </RightCol>
      </TopBar>
      {/* ==== Header ==== */}

      {/* ==== Filter ==== */}
      <PageDiv style={{height: "inherit"}}>
        <BroadcastTopTitle style={{ padding: "16px 0px" }}>
          <Box className="topTitle-left">
            <Typography fontSize="16px" fontWeight="500" component="h6">
              Scheduled Broadcast
            </Typography>
          </Box>
          <Box className="topTitle-right">
            {
              credits?.whatsapp_no && (
                <ProtectedAppButton
                  moduleId="whatsapp_template"
                  specificPermissionKey="create"
                  variant="primarydark"
                  onClick={() => navigate("/broadcast/whatsapp/create-template")}
                  style={{ minWidth: "170px" }}
                >
                  Create Template
                  <AppMaterialIcons iconName="Add" />
                </ProtectedAppButton>
              )
            }
            <ProtectedAppButton
              moduleId="whatsapp_broadcast_crud"
              specificPermissionKey="create"
              variant="primarydark"
              onClick={() => navigate("/broadcast/whatsapp/create-broadcast/1")}
              style={{ minWidth: "170px" }}
              isDisabled={has_low_balance === "true"}
              title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
            >
              Create Broadcast
              <AppMaterialIcons iconName="Add" />
            </ProtectedAppButton>
          </Box>
        </BroadcastTopTitle>

        <AppForm
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => { }}
          // validationSchema={BotValidationSchema}
          divStyle={{ width: "100%" }}
        >
          <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="filter-left">
              <Typography fontSize="13px" fontWeight={"400"} component="h6">
                Sort By
              </Typography>
              <Box>
                <AppRangeFutureDatePicker
                  dateRange={{
                    startDate: localValues?.startDate,
                    endDate: localValues?.endDate,
                  }}
                  reset={true}
                  setDateRange={handleChangeDate}
                />
              </Box>
              <Box>
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  defaultBorder={true}
                  divStyle={{
                    marginBottom: "0",
                  }}
                  onChange={(e: any) => handleSelectChange(e, "search")}
                />
              </Box>
            </div>
            <div className="filter-right"></div>
          </TableFilter>
        </AppForm>

        {/* ==== Table ==== */}

        {
          loading ? <AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - 340px)`}
          /> : <TableWrapper height="calc(100vh - 268px)" firstColTextWidth={"360px"}>
            <div>
              <Box className="table-header">
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{
                          minWidth: colWidth[header] + "px",
                        }}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                  <Box
                    className="col-head"
                    sx={{
                      maxWidth: "100px",
                      minWidth: "100px",
                      height: "2.969rem",
                    }}
                  >
                    <Typography></Typography>
                  </Box>
                </Box>
              </Box>

              {
                errorApi || list?.length === 0 ? (
                  <NoRecordsFound subText="Get started by creating a broadcast first.">
                    <ProtectedAppButton
                      variant="primarydark"
                      moduleId="whatsapp_broadcast_crud"
                      specificPermissionKey="create"
                      onClick={() =>
                        navigate("/broadcast/whatsapp/create-broadcast/1")
                      }
                      isDisabled={has_low_balance === "true"}
                      title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
                    >
                      Create Broadcast
                      <AppMaterialIcons iconName="Add" />
                    </ProtectedAppButton>
                  </NoRecordsFound>
                ) : (
                  <Box className="table-body">
                    {list?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                  header === "Scheduled"
                                    ? formatDateField(row[keyToHeaderMap[header]])
                                    : row[keyToHeaderMap[header]]
                                      ? row[keyToHeaderMap[header]]
                                      : "-"
                                }
                                style={{
                                  minWidth: isActionColumn
                                    ? "400px"
                                    : colWidth[header] + "px",
                                }}
                              >
                                <Typography className="table-text">
                                  {header === "Scheduled"
                                    ? formatDateField(row[keyToHeaderMap[header]])
                                    : row[keyToHeaderMap[header]]
                                      ? row[keyToHeaderMap[header]]
                                      : "-"}
                                </Typography>
                              </Box>
                            );
                          })}
                          <Box
                            display="flex"
                            sx={{
                              maxWidth: "100px",
                              minWidth: "100px",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                            className="actionColumn"
                          >
                            <>
                              {row?.can_update && (
                                <IconButton>
                                  <EditIcon
                                    onClick={() => editBroadcastTemplate(row?.id)}
                                    fontSize="small"
                                  />
                                </IconButton>
                              )}
                              {row?.can_delete && (
                                <IconButton>
                                  <DeleteOutlineOutlinedIcon
                                    onClick={() => {
                                      setSelectedId(row?.id);
                                      toggleModal(true);
                                    }}
                                    fontSize="small"
                                  />
                                </IconButton>
                              )}
                              <IconButton>
                                <RemoveRedEyeOutlinedIcon
                                  onClick={() =>
                                    editBroadcastTemplate(row?.id, true)
                                  }
                                  fontSize="small"
                                />
                              </IconButton>
                            </>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )
              }
            </div>
          </TableWrapper>
        }
      </PageDiv>

      <AppModel
        isShowing={isShowingModal}
        onClose={toggleModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The Whatsapp Broadcast</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal(false)}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => handleClickDelete(selectedId)}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
    </TemplateListWrapper>
  );
};

// export default withLoader(WhatsappScheduledBroadcast);
export default WhatsappScheduledBroadcast;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreditDetailsNetworkService } from "./CreditDetailsNetworkService";

export class CreditDetailsActions {
  // * get Credit broadcast details *//
  // static getBroadcastCreditDetails = createAsyncThunk(
  //   "CreditDetailSlice/getBroadcastCreditDetails",
  //   (data: string, { dispatch, rejectWithValue }) => {
  //     return CreditDetailsNetworkService.getBroadcastCreditDetails(data)
  //       .then(async (response: any) => {
  //         return response;
  //       })
  //       .catch((error: { response: unknown }) => {
  //         return rejectWithValue(error?.response);
  //       })
  //       .finally(() => {});
  //   }
  // );
  // }

  //for whatsapp broadcast calculate cost
static getBroadcastCreditDetails = createAsyncThunk(
    "CreditDetailSlice/getBroadcastCreditDetails",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getBroadcastCreditDetails(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  
  //for sms broadcast calculate cost
  static getBroadcastCreditDetailssms = createAsyncThunk(
    "CreditDetailSlice/getBroadcastCreditDetailssms",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getBroadcastCreditDetailssms(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //for email broadcast calculate cost

  static getBroadcastCreditDetailsemail = createAsyncThunk(
    "CreditDetailSlice/getBroadcastCreditDetailsemail",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getBroadcastCreditDetailsemail(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );


}
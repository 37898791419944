import {
  Box,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Danger from "../../../../assets/images/danger-live.svg";

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import { FilterPannel } from "../../../../Customization/CommonElements";
import {
  ErrorComponent,
  SmsMenuFilter,
  SmsTag,
  SmsTagNumber,
  TagListToolTip,
  TemplateListWrapper,
  TemplateTableFixedFirstCol,
} from "./TemplateListingCss";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AppRangeDatePicker from "../../../../AppComponents/AppRangeDatePicker";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import TemplateAPIERROR from "../../../../assets/images/No_records_found.svg";

import { GridCloseIcon } from "@mui/x-data-grid";
import React from "react";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import { AppConfig } from "../../../../Config/app-config";
import useModal from "../../../../Hooks/useModel/useModel";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
import {
  CategoriesListOptionsData,
  CategoriesPaginationData,
  SMSDataStore,
  SMSTagOptionList,
  SMSTemplateListing,
  SMSTemplateListingCount,
  SMSTemplateListingLoading,
  SMSTemplateListingPagination,
} from "../../../../Store/Slices/SMS/sms.selectors";
import { BROADCAST_SMS_FORM_DATA } from "../../WhatsApp/Utils/Constants";

import { Close } from "@material-ui/icons";
import { FieldArray } from "formik";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import AppFormCheckboxField from "../../../../AppComponents/Forms/AppFormCheckboxField";
import { NewNativeSelect } from "../../WhatsApp/BroadcastHistory/HistoryStyles";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { format } from "date-fns";
import moment from "moment";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { CoreService } from "../../../../Services/Apis/CoreApis";
import { AppPagination, BroadcastTopTitle } from "../../../../TableStyle";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { getStatusTooltip } from "../../Utils/utils";
import { PageDiv } from "../../WhatsApp/ScheduledBroadcast/Styles";
import { DeleteCannedResponse } from "../../WhatsApp/Templates/TemplatesListing";
import UploadTemplate from "./UploadTemplate";
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";

// interface Props extends LoaderComponentProps {}
interface Props { }

const SmsTemplateListing: React.FC<Props> = () => {
  const formikRef = useRef(null);
  const { isShowing, toggle } = useModal();

  const [credits, setCredits] = useState<any>([]);
  const itemRef: any = useRef([]);
  const [errorApi, setErrorApi] = useState(false);
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    dltStatus: "",
    templateCategory: "",
    smsStatus: "",
    tags: "",
    startDate: null,
    endDate: null,
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    checkbox: [],
    checkboxAll: false,
    applyTags: [],
  });
  // const debouncedSearchTerm = debounce<string>(localValues?.search, 600);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [checked, setChecked] = useState<any>([]);
  const [shouldApply, setShouldApply] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [dltMessages, setDltMessages] = useState<any>([]);
  const [smsType, setSmsType] = useState<any>([]);
  const [templateCategory, setTemplateCategory] = useState<any>([]);
  const [isShowingModal, toggleModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useAppDispatch();
  const { smsTemplateListData } = useAppSelector(SMSDataStore);
  const navigate = useNavigate();
  let { showAlert } = useNotification();
  const listingData = useSelector(SMSTemplateListing);
  const isListingDataLoading = useSelector(SMSTemplateListingLoading);

  const categoriesData = useSelector(CategoriesListOptionsData);
  const pagination = useSelector(CategoriesPaginationData)
  const listingPagination = useSelector(SMSTemplateListingPagination)
  const listingDataCount = useSelector(SMSTemplateListingCount);
  const tagOptions = useSelector(SMSTagOptionList);
  const [loading, setLoading] = useState(false);
  const has_low_balance = localStorage.getItem("has_low_balance")
  const resetCheckAll = () => {
    setLocalValues({
      ...localValues,
      checkboxAll: false,
      checkbox: [],
      tags: [],
    });
  };
  const getPreviousData = () => {
    let start = null;
    let end = null;
    if (localValues.startDate && localValues.endDate) {
      start = format(localValues.startDate, "yyyy-MM-dd");
      end = format(localValues.endDate, "yyyy-MM-dd");
    }
    const payload: any = {
      start: start,
      limit: localValues?.itemsPerPage?.value,
      offset: localValues.currentPage,
      end: end,
      status: localValues?.status,
      template_type: localValues?.dltStatus,
      template_category: localValues?.templateCategory,
      message_type: localValues?.smsStatus,
      search: localValues?.search,
      tags: localValues?.tag ? [localValues?.tag] : [],
    };
    setLoading(true);
    dispatch(SMSActions.getSMSTemplateListData(payload))
      .then()
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTagListData = () => {
    setLoading(true);
    dispatch(SMSActions.getSMSTemplateTagListOptions())
      .then((res: any) => {
        // setTotolCount(res?.payload?.data?.count);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCreateOption = (inputValue: any) => {
    // Create a new option from the input value
    const newOption = { label: inputValue, value: inputValue };
    setSelectedOptions([...selectedOptions, newOption]);

    const payload: any = {
      name: inputValue,
    };
    setLoading(true);
    dispatch(SMSActions.getSMSTemplateTagData(payload))
      .then((res: any) => {
        dispatch(SMSActions.getSMSTemplateTagListOptions());
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPreviousDataAfterApplyTag = () => {
    let start = null;
    let end = null;
    if (localValues.startDate && localValues.endDate) {
      start = format(localValues.startDate, "yyyy-MM-dd");
      end = format(localValues.endDate, "yyyy-MM-dd");
    }
    const payload: any = {
      start: start,
      limit: localValues.itemsPerPage?.value,
      offset: localValues.currentPage,
      end: end,
      status: "",
      template_type: "",
      template_category: "",
      message_type: "",
      search: "",
      tags: [],
    };
    dispatch(SMSActions.getSMSTemplateListData(payload))
      .then((res: any) => {
        setLocalValues({
          ...localValues,
          dltStatus: "",
          status: "",
          templateCategory: "",
          smsStatus: "",
          tag: "",
          applyTags: [],
          checkboxAll: false,
          checkbox: [],
        });
        setSelectedOptions([]);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const filterTags = selectedOptions.map((item: any) => item.value);
  const filterIds = localValues.checkbox.join().split(",");

  const handleApply = () => {
    setLoading(true);

    const payload: any = {
      template_id: filterIds,
      tags: filterTags,
    };
    dispatch(SMSActions.getSMSTemplateTagUpdate(payload))
      .then((res: any) => {
        getPreviousDataAfterApplyTag();
        dispatch(SMSActions.getSMSTemplateTagListOptions());
        setSelectedOptions([]);
        setLocalValues({
          ...localValues,
          applyTags: [],
          checkboxAll: false,
          checkbox: [],
        });
        showAlert("Tags Added successfully", "success");
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id: any, tag: string) => {
    const tempId = id.toString();
    const removeTag = tag.toString();
    const payload: any = {
      template_id: tempId,
      tag: removeTag,
    };
    dispatch(SMSActions.getSMSTemplateTagDelete(payload))
      .then((res: any) => {
        getPreviousData();
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousData();
    if (selectedOptions) {
      getTagListData();
      setShouldApply(false);
    }
  }, [localValues && shouldApply]);

  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
    dispatch(SMSActions.getSMSTemplateCategories({}));
  }, []);

  useEffect(() => {
    if (listingData) {
      let dltData: any = [];
      let smsType: any = [];
      let templateCategory: any = [];
      listingData.map((res: any) => {
        if (
          !dltData?.includes(res?.template_type) ||
          !smsType?.includes(res?.message_type) ||
          !templateCategory?.includes(res?.category)
        ) {
          dltData.push(res?.template_type);
          smsType.push(res?.message_type);
          templateCategory.push(res?.category);
        }
      });
      setDltMessages(dltData);
      setSmsType(smsType);
      setTemplateCategory(templateCategory);
    }
  }, [listingData]);

  useEffect(() => {
    const allDocumentIds = listingData?.map((item: any) => item?.id);
    let idsArray: any;
    if (allDocumentIds) {
      idsArray = [...localValues.checkbox, ...allDocumentIds];
      idsArray = idsArray.filter(
        (value: any, index: number, array: any) =>
          array.indexOf(value) === index
      );
      localValues.checkboxAll &&
        setLocalValues({
          ...localValues,
          checkbox: idsArray,
        });
    }
  }, [listingData]);

  function handlePageChange(event: any, value: any) {
    const allSmsTemplateIds = listingData?.map((item: any) => item.id);
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
      // checkbox: [...localValues.checkbox, ...allSmsTemplateIds],
    });
    setShouldApply(true);
  }
  const handleChangeRowsPerPage = (value:any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage:value,
      currentPage: 0,
    });
    setShouldApply(true);
  };
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      currentPage: 0,
      startDate: data.startDate,
      endDate: data.endDate,
    });
    setShouldApply(true);
  };
  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      currentPage: 0,
      [field]: e.target.value,
    });
    setShouldApply(false);
  };
  const handleSelectChanges = (e?: any, selectedId?: any) => {
    let selectedIdsArray: Array<any> = [...localValues.checkbox];
    if (e.target.checked) {
      selectedIdsArray = [...localValues.checkbox, selectedId];
    } else {
      const idx: any = selectedIdsArray?.indexOf(selectedId);
      selectedIdsArray.splice(idx, 1);
    }
    if (selectedIdsArray?.length === listingData?.length) {
      setLocalValues({
        ...localValues,
        checkboxAll: true,
        checkbox: [...selectedIdsArray],
      });
    } else {
      setLocalValues({
        ...localValues,
        checkboxAll: false,
        checkbox: [...selectedIdsArray],
      });
    }
  };
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      currentPage: 0,
      [field]: e,
      status: "",
    });
    setShouldApply(true);
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    setShouldApply(true);
  };
  const clearAllCallback = () => {
    setLocalValues({
      ...localValues,
      status: "",
      dltStatus: "",
      templateCategory: "",
      smsStatus: "",
    });
  };

  const handleButtonClick = (formikRef: any) => {
    formikRef.current.handleReset();
  };
  const handleCheckAllChange = (e: any) => {
    if (e.target.checked) {
      const allSmsTemplateIds = listingData?.map((item: any) => item.id);
      setChecked(allSmsTemplateIds);
      setLocalValues({
        ...localValues,
        checkboxAll: true,
        checkbox: [...allSmsTemplateIds],
      });
    } else {
      setLocalValues({
        ...localValues,
        checkboxAll: false,
        checkbox: [],
      });
      setChecked([]);
    }
  };

  const renderSelectIcon = localValues.dltStatus ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconSmsType = localValues.smsStatus ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconStatus = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconTag = localValues.tag ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconCategory = localValues.templateCategory ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const toolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            paddingTop: "1rem",
            paddingBottom: "1rem",
            backgroundColor: `#fff`,
            color: `#000`,
            borderRadius: "4px",
            boxShadow: `0px 0px 2px ${theme.palette.default.darkGrey}`,
          },
          arrow: {
            color: "white",
          },
        },
      },
    },
  });

  useEffect(() => {
    CoreService.getCredits().then((response) => {
      const reversedItems = response?.data;
      setCredits(reversedItems);
    });
  }, [localValues]);
  const restFilter = () => {
    setLocalValues({
      ...localValues,
      dltStatus: "",
      status: "",
      templateCategory: "",
      smsStatus: "",
      tag: "",
      tags: [],
      checkboxAll: false,
    });
    getPreviousData();
    setShouldApply(true);
  };
  const handleClickDelete = (id: any) => {
    dispatch(SMSActions.deleteSmsTemplateById(id))
      .then((res: any) => {
        getPreviousData();
        toggleModal(false);
        showAlert("Template deleted successfully", "success");
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      });
  };

  return (
    <TemplateListWrapper>
      <AppModel
        isShowing={isShowingModal}
        onClose={toggleModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The SMS Template</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal(false)}>
              Cancel
            </AppButton>
            <ProtectedAppButton
              moduleId="sms_broadcast_crud"
              specificPermissionKey="delete"
              variant="danger-filled"
              onClick={() => handleClickDelete(selectedId)}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </ProtectedAppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
      {/* Top BAr */}
      {/* <TopBar>

        <LeftCol>


        </LeftCol>

        
        <RightCol>
          <Box className="contact">
          </Box>
          <Box>
            <Box className="conversations">
              <Box>
              </Box>
            </Box>
            <Box className="credits">
              <Box>
                <label>
                  Available Credits: <strong>{credits?.remaining_amount || 0}</strong>
                </label>
              </Box>
            </Box>
          </Box>

        </RightCol>
      </TopBar> */}

      <BroadcastTopTitle style={{ padding: "16px" }}>
        <Box className="topTitle-left">
          <Typography fontSize="16px" fontWeight="500" component="h6">
            SMS BroadCast Template
          </Typography>
        </Box>
        <Box className="topTitle-right">
          <ProtectedAppButton
            moduleId="sms_template"
            specificPermissionKey="create"
            variant="outline"
            // onClick={() => navigate("/broadcast/sms/upload-template")}
            onClick={() => toggle()}
          >
            Upload Template
          </ProtectedAppButton>
          <ProtectedAppButton
            moduleId="sms_broadcast_crud"
            specificPermissionKey="create"
            variant="primarydark"
            onClick={() => navigate("/broadcast/sms/create-broadcast/1")}
            isDisabled={has_low_balance === "true"}
            title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
          >
            Create Broadcast
            <AppMaterialIcons iconName="Add" />
          </ProtectedAppButton>
        </Box>
      </BroadcastTopTitle>

      {/* Filter Section Start */}

      <PageDiv>
        {/* Edited */}
        <Paper
          elevation={3}
          sx={{ boxShadow: "none", borderRadius: "1rem 1rem 0 0" }}
        >
          <FilterPannel style={{ maxHeight: "64px" }}>
            <Box sx={{ width: "100%" }}>
              <AppForm // requestSearch(values, submitProps);
                initialValues={{ search: "" }}
                onSubmit={(values: any, submitProps: any) => {}}
                // validationSchema={BotValidationSchema}
                divStyle={{ width: "100%" }}
              >
                <div className="fields-container">
                  <div className="sms_left">
                    <div className="">
                      <AppFormSearchField
                        name="search"
                        placeholder="Search"
                        defaultBorder={true}
                        // sendValuesToParent={(values: any) => sendValuesToParent(values)}
                        onChange={(e: any) => handleSelectSearch(e, "search")}
                        clearCallback={(e: any) => clearCallback(e, "search")}
                        divStyle={{
                          marginBottom: "0rem",
                          width: "220px",
                          minWidth: "180px",
                          height: "28px"
                        }}
                      />
                    </div>
                    {filterIds &&
                      filterIds?.length &&
                      filterIds?.[0]?.length > 0 && (
                        <div className="create-table-select">
                          <CreatableSelect
                            isMulti
                            onChange={(newValue: any) =>
                              setSelectedOptions(newValue)
                            }
                            onCreateOption={handleCreateOption}
                            value={selectedOptions}
                            getOptionLabel={(option) => option?.label}
                            getOptionValue={(option) => option?.value}
                            options={tagOptions}
                            placeholder={"Add Tags"}
                          />
                          <AppButton onClick={() => handleApply()}>
                            Apply
                          </AppButton>
                        </div>
                      )}
                  </div>
                  <div className="create-table-select">
                    <div className="">
                      {localValues.status && localValues.search && (
                        <AppButton
                          variant="outline"
                          onClick={() => {
                            clearAllCallback();
                            handleButtonClick(formikRef);
                          }}
                        >
                          Reset
                        </AppButton>
                      )}
                    </div>
                  </div>

                  <div className="sms_right">
                    <div className="sms_select">
                      <AppButton
                        variant={isFilterApplied ? "default" : "grey"}
                        style={{ width: "100%" }}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <AppMaterialIcons iconName={"Sort"} />
                        <span style={{ marginLeft: "0.7rem" }}>Filter</span>
                      </AppButton>
                      <SmsMenuFilter
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem sx={{}}>
                          <p>DLT message type</p>
                          {/* Select 1 */}
                          <NewNativeSelect>
                            <Select
                              defaultValue={undefined}
                              name="dltStatus"
                              value={localValues?.dltStatus}
                              onChange={(e: any) =>
                                handleSelectChange(e, "dltStatus")
                              }
                              displayEmpty
                              className="new-select-main"
                            >
                              <MenuItem value="" disabled>
                                {" "}
                                Select an option
                              </MenuItem>
                              <MenuItem value="Transactional">
                                {" "}
                                Transactional
                              </MenuItem>
                              <MenuItem value="Service Implicit">
                                {" "}
                                Service Implicit
                              </MenuItem>
                              <MenuItem value="Service Explicit">
                                {" "}
                                Service Explicit
                              </MenuItem>
                              <MenuItem value="Promotional">
                                {" "}
                                Promotional
                              </MenuItem>
                            </Select>
                            <div
                              className="clear-icon"
                              onClick={(e: any) =>
                                clearCallback(e, "dltStatus")
                              }
                            >
                              {renderSelectIcon}
                            </div>
                          </NewNativeSelect>
                        </MenuItem>

                        {/* Select 2 */}
                        <MenuItem>
                          <p>SMS type</p>
                          <NewNativeSelect>
                            <Select
                              defaultValue={undefined}
                              name="smsStatus"
                              value={localValues?.smsStatus}
                              onChange={(e: any) =>
                                handleSelectChange(e, "smsStatus")
                              }
                              displayEmpty
                              className="new-select-main"
                            >
                              <MenuItem value="" disabled>
                                {" "}
                                Select an option
                              </MenuItem>
                              <MenuItem value="text"> TEXT</MenuItem>
                              <MenuItem value="unicode text">
                                {" "}
                                UNICODE TEXT
                              </MenuItem>
                            </Select>
                            <div
                              className="clear-icon"
                              onClick={(e: any) =>
                                clearCallback(e, "smsStatus")
                              }
                            >
                              {renderSelectIconSmsType}
                            </div>
                          </NewNativeSelect>
                        </MenuItem>

                        {/* Select 3 */}
                        <MenuItem>
                          <p>Status</p>
                          <NewNativeSelect>
                            <Select
                              defaultValue={undefined}
                              name="status"
                              value={localValues?.status}
                              onChange={(e: any) =>
                                handleSelectChange(e, "status")
                              }
                              displayEmpty
                              className="new-select-main"
                            >
                              <MenuItem value="" disabled>
                                {" "}
                                Select an option
                              </MenuItem>
                              <MenuItem value="APPROVED"> APPROVED</MenuItem>
                              <MenuItem value="PENDING"> PENDING</MenuItem>
                              <MenuItem value="REJECTED"> REJECTED</MenuItem>
                            </Select>
                            <div
                              className="clear-icon"
                              onClick={(e: any) => clearCallback(e, "status")}
                            >
                              {renderSelectIconStatus}
                            </div>
                          </NewNativeSelect>
                        </MenuItem>

                        {/* Select 4 */}
                        <MenuItem>
                          <p>Tag</p>
                          <NewNativeSelect>
                            <Select
                              defaultValue={undefined}
                              name="tag"
                              value={localValues?.tag || "Select an option"}
                              onChange={(e: any) =>
                                handleSelectChange(e, "tag")
                              }
                              displayEmpty
                              className="new-select-main"
                            >
                              <MenuItem value="Select an option" disabled>
                                {" "}
                                Select an option
                              </MenuItem>
                              {tagOptions?.map((tags: any) => (
                                <MenuItem value={tags.label}>
                                  {" "}
                                  {tags.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <div
                              className="clear-icon"
                              onClick={(e: any) => clearCallback(e, "tag")}
                            >
                              {renderSelectIconTag}
                            </div>
                          </NewNativeSelect>
                        </MenuItem>

                        {/* Select 5 */}
                        <MenuItem>
                          <p>Template Category</p>

                          <NewNativeSelect>
                            <Select
                              defaultValue={localValues?.templateCategory}
                              name="templateCategory"
                              value={
                                localValues?.templateCategory ||
                                "Select an Template"
                              }
                              onChange={(e: any) =>
                                handleSelectChange(e, "templateCategory")
                              }
                              displayEmpty
                              className="new-select-main"
                            >
                              <MenuItem value="Select an Template" disabled>
                                {" "}
                                Select an option
                              </MenuItem>
                              {categoriesData?.map((res: any) => (
                                <MenuItem value={res?.value}>
                                  {res?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <div
                              className="clear-icon"
                              onClick={(e: any) =>
                                clearCallback(e, "templateCategory")
                              }
                            >
                              {renderSelectIconCategory}
                            </div>
                          </NewNativeSelect>
                        </MenuItem>
                        <Box className="setting-page-mainboxtopbar__right">
                          <Box
                            display="flex"
                            sx={{ gap: "1rem" }}
                            style={{ padding: "1% 8%" }}
                          >
                            <AppButton
                              style={{ minWidth: "95px" }}
                              onClick={() => {
                                setShouldApply(false);
                                restFilter();
                                handleClose();
                                setIsFilterApplied(false);
                              }}
                            >
                              Reset
                            </AppButton>
                            <AppButton
                              variant="outline"
                              style={{ minWidth: "95px" }}
                              onClick={() => {
                                setShouldApply(true);
                                handleClose();
                                resetCheckAll();
                                setIsFilterApplied(true);
                              }}
                            >
                              Apply
                            </AppButton>
                          </Box>
                        </Box>
                      </SmsMenuFilter>
                    </div>
                    <div className="sms-date_picker">
                      <AppRangeDatePicker
                        divStyle={{
                          width: "225px",
                        }}
                        dateFieldStyle={{
                          height: "28px",
                        }}
                        reset={true}
                        dateRange={localValues}
                        setDateRange={handleChangeDate}
                      />
                    </div>
                  </div>
                </div>
              </AppForm>
            </Box>
            {/* </Box> */}
          </FilterPannel>
        </Paper>


        {
          isListingDataLoading ? (<AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - 291px)`}
          />): 
          (
            <>
            {
                  listingData === null || listingData?.length === 0 ? (
                    <NoRecordsFound border={true} height="calc(100vh - 204px)" borderRadius="0px">
                      <ProtectedAppButton
                        moduleId="sms_template"
                        specificPermissionKey="create"
                        variant="primarydark"
                        // onClick={() => navigate("/broadcast/sms/upload-template")}
                        onClick={() => toggle()}
                      >
                        Upload Template
                        <AppMaterialIcons
                          style={{ marginTop: "2px" }}
                          iconName="CloudUploadOutlined"
                        />
                      </ProtectedAppButton>
                    </NoRecordsFound>
                  ) : (
<>

                        <TemplateTableFixedFirstCol>
                          <div className="table-container">
                            <AppForm
                              initialValues={localValues}
                              onSubmit={(values: any, submitProps: any) => {
                                // handleSubmit(values, submitProps);
                                // setUserInput(values);
                              }}
                            // validationSchema={loginSchema}
                            >
                              <div className="table">
                                <div
                                  className="column tbl-heading"
                                  style={{
                                    border: "4px solid !important",
                                    maxHeight: "41px",
                                  }}
                                >
                                  <AppFormCheckboxField
                                    label={""}
                                    name="checkboxAll"
                                    onChange={handleCheckAllChange}
                                    style={{
                                      padding: 0,
                                      height: "100%",
                                    }}
                                  />
                                </div>
                                <div className="column tbl-heading" style={{justifyContent: "flex-start"}}>Template Name & ID</div>
                                <div className="column tbl-heading">Template Text</div>
                                <div className="column tbl-heading">Length</div>
                                <div className="column tbl-heading">DLT Message Type</div>
                                <div className="column tbl-heading">SMS Type</div>
                                <div className="column tbl-heading">Status</div>
                                <div className="column tbl-heading">Tag</div>
                                <div className="column tbl-heading">Created at</div>
                                <div className="column tbl-heading">Template Category</div>
                              </div>
                              <FieldArray
                                name="checkbox"
                                render={(arrayHelpers) => (
                                  <div>
                                    {listingData?.map((ele: any, i: number) => {
                                      return (
                                        <div className="table tbl-heading__two" key={i}>
                                          <div
                                            className="column tbl-heading"
                                            style={{
                                              height: itemRef.current?.[i]?.clientHeight,
                                            }}
                                          >
                                            <AppFormCheckboxField
                                              label={""}
                                              name="checkbox"
                                              onChange={(e: any, ele?: any) => {
                                                handleSelectChanges(e, ele);
                                              }}
                                              isArray={true}
                                              uniqueId={ele.id}
                                              style={{ padding: 0 }}
                                            />
                                          </div>
                                          <div
                                            className="column"
                                            style={{
                                              height: itemRef.current?.[i]?.clientHeight,
                                              width: "12px",
                                              justifyContent: "flex-start"
                                            }}
                                          >
                                            {ele.name}
                                            <br /> {ele.dlt_template_id}
                                          </div>
                                          <div
                                            className="data-column"
                                            ref={(e1) => (itemRef.current[i] = e1)}
                                          >
                                            {ele.template_body}
                                          </div>
                                          <div className="data-column">{ele?.length}</div>
                                          <div className="data-column">
                                            {ele.template_type}
                                          </div>
                                          <div className="data-column">
                                            {ele.message_type}
                                          </div>
                                          <div
                                            style={{ display: "flex", gap: "6px" }}
                                            className={`data-column ${ele.status === "APPROVED"
                                                ? "success"
                                                : ele.status === "REJECTED"
                                                  ? "rejected"
                                                  : ele.status === "PENDING"
                                                    ? "pending"
                                                    : ""
                                              }`}
                                          >
                                            {ele.status}
                                            {ele.status !== "APPROVED" && (
                                              <div className="tooltip">
                                                {" "}
                                                <AppToolTip
                                                  tooltipProps={{
                                                    title: getStatusTooltip(
                                                      ele.status,
                                                      ele.detailed_status
                                                    ),
                                                  }}
                                                  iconButtonProps={{
                                                    disableRipple: true,
                                                  }}
                                                  IconComponent={
                                                    <InfoOutlinedIcon fontSize="small" />
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>

                                          <div className="data-column">
                                            <div className="tag-list">
                                              {ele.tags
                                                .slice(0, 3)
                                                .map((element: any, i: number) => {
                                                  const shortenedText =
                                                    element.slice(0, 8) +
                                                    (element?.length > 8 ? "..." : "");
                                                  return (
                                                    <SmsTag key={i}>
                                                      <div className="container">
                                                        <div className="text">
                                                          {shortenedText}
                                                        </div>
                                                        {/* <IconButton onClick={() => handleDelete(ele.id, element)}> */}
                                                        <div
                                                          className="close"
                                                          onClick={() =>
                                                            handleDelete(ele.id, element)
                                                          }
                                                        >
                                                          <Close></Close>
                                                        </div>
                                                      </div>
                                                      {/* </IconButton> */}
                                                    </SmsTag>
                                                  );
                                                })}
                                            </div>
                                            {ele.tags?.length > 3 && (
                                              <ThemeProvider theme={toolTipTheme}>
                                                <Tooltip
                                                  title={
                                                    <>
                                                      {ele.tags
                                                        .slice(3)
                                                        .map((element: any, i: number) => (
                                                          <TagListToolTip>
                                                            <MenuItem>
                                                              {element}
                                                              <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                  handleDelete(
                                                                    ele.id,
                                                                    element
                                                                  )
                                                                }
                                                              >
                                                                <Close />
                                                              </IconButton>
                                                            </MenuItem>
                                                          </TagListToolTip>
                                                        ))}
                                                    </>
                                                  }
                                                  placement="top"
                                                >
                                                  <SmsTagNumber>{`+${ele.tags?.length - 3
                                                    }`}</SmsTagNumber>
                                                </Tooltip>
                                              </ThemeProvider>
                                            )}
                                          </div>
                                          <div className="data-column">
                                            {moment(ele.created_time).format(
                                              "DD MMM, YYYY"
                                            )}
                                            <br />
                                            {moment(ele.created_time).format("hh:mm A")}
                                          </div>
                                          <div title={ele.category} className="data-column">{ele.category}</div>
                                          {ele?.status === "REJECTED" ? (
                                            <IconButton
                                              style={{
                                                top: "10px",
                                                alignSelf: "flex-start",
                                              }}
                                            >
                                              <DeleteOutlineOutlinedIcon
                                                onClick={() => {
                                                  setSelectedId(ele?.id);
                                                  toggleModal(true);
                                                }}
                                                fontSize="small"
                                              />
                                            </IconButton>
                                          ) : null}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              />
                            </AppForm>
                          </div>
                        </TemplateTableFixedFirstCol>

                        {listingData?.length ? (
                          <AppPaginationComponent
                            totalCount={listingPagination?.totalRecords}
                            handlePageChange={handlePageChange}
                            currentPage={localValues?.currentPage}
                            totalPages={listingPagination?.totalPages}
                            rowsPerPage={localValues?.itemsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        ) : null}

</>
                  )
            }
            </>
          )}



        {/* <Paper elevation={3} sx={{ height: "calc(100%)" }}> */}
        {/* {listingData === null || listingData?.length === 0 ? (
          <>
            <NoRecordsFound border={true} height="calc(100vh - 204px)" borderRadius="0px">
              <ProtectedAppButton
                        moduleId="sms_template"
                        specificPermissionKey="create"
                        variant="primarydark"
                        // onClick={() => navigate("/broadcast/sms/upload-template")}
                        onClick={() => toggle()}
                      >
                        Upload Template
                        <AppMaterialIcons
                          style={{ marginTop: "2px" }}
                          iconName="CloudUploadOutlined"
                        />
                      </ProtectedAppButton>
            </NoRecordsFound>
          </>
          // <ErrorComponent
          //   style={{
          //     height: "calc(100vh - 212px)",
          //     border: "1px solid rgba(126, 131, 146, 0.5)",
          //   }}
          // >
          //   <Box display="flex" justifyContent="center">
          //     <img width="400" src={TemplateAPIERROR} alt="" />
          //   </Box>
          //   <Box className="error-Info">
          //     <Typography variant="h6" fontSize="20px">
          //       No Records Found
          //     </Typography>
          //     <Box className="err-action">
          //       <Box>
          //         <ProtectedAppButton
          //           moduleId="sms_template"
          //           specificPermissionKey="create"
          //           variant="primarydark"
          //           // onClick={() => navigate("/broadcast/sms/upload-template")}
          //           onClick={() => toggle()}
          //         >
          //           Upload Template
          //           <AppMaterialIcons
          //             style={{ marginTop: "2px" }}
          //             iconName="CloudUploadOutlined"
          //           />
          //         </ProtectedAppButton>
          //       </Box>
          //     </Box>
          //   </Box>
          // </ErrorComponent>
        ) : (
          <>
            

            
          </>
        )} */}

        {/* </Paper > */}
      </PageDiv>
      <AppModel
        isShowing={isShowing}
        onClose={() => toggle()}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
      >
        <UploadTemplate localvalues={localValues} close={() => toggle()} />
      </AppModel>
    </TemplateListWrapper>
  );
};

// export default withLoader(SmsTemplateListing);
export default SmsTemplateListing;

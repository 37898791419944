import styled from "styled-components";
import { Toolbar } from "@mui/material";
import { theme } from "../../Customization/Theme";
import { device } from "../../Customization/BreakPoints";

export const StyledCreateBroadcastWrap = styled.div`
  && {
    background-color: ${theme.palette.default.lightPrimaryBackground};
    width: 100%;
    height: 100%;
    height: auto;
    overflow: overlay;
    padding: 0rem 0rem 0.5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    z-index: 1;
  }
`;

export const StyledTableToolbar = styled(Toolbar)`
  && {
    width: 100%;
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
    .top {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      width: 100%;
    }
    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      width: 100%;

      padding: 0 0rem 1rem;

      .toolbarText {
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 1rem;
        opacity: 85%;
        color: ${theme.palette.default.text};
        line-height: 20px;
        white-space: nowrap;
      }

      .displayFlex {
        display: flex;
      }

      .toolbarTextred {
        color: ${theme.palette.default.error} !important;
        padding-right: 1rem;
        fontweight: 500;
      }

      .toolbarTextgreen {
        color: ${theme.palette.default.success} !important;
        fontweight: 500;
      }
    }
  }
`;
export const StyledRenderQueryFilterWrap = styled.div`
  && {
    .form-heading {
      display: flex;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      padding: 0rem 1rem 0rem 1rem;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 9;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

      h6 {
        font-size: 1.05rem;
        color: ${theme.palette.default.text};
      }

      svg {
        color: ${theme.palette.default.darkGrey};
      }
    }

    .footer {
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

      .footer-btns {
        justify-content: end;
        gap: 1.3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 1rem;
        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const SchenduleBoradcastWrapper = styled.div`
  width: 100%;
  height: calc(100% - 64px);
`;

export const StyledResponsiveDivTable = styled.div`
  && {
    width: 100%;
    .MuiTablePagination-root {
      padding: 0 !important;
      overflow: hidden;
      height: 3rem;
      background-color: red;
      .MuiTablePagination-toolbar {
        padding: 0 !important;
      }
    }
    .MuiTablePagination-actions {
      svg {
        color: ${theme.palette.default.darkGrey} !important;
      }
    }
    .table {
    }
    .tableStickyHead {
      // oveflow: auto;
      // position: sticky;
      top: 0;
      z-index: 999;
      background-color: #fff !important;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      width: fit-content;
      min-width: 100%;
    }

    .tablHeadRow {
      display: flex;
      flex: 1 !important;
      width: fit-content;
      background-color: #fff;
    }
    .tableHeadCell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 220px;
      max-width: 300px;
      &:first-child {
        min-width: 50px;
      }
    }
    .tableHeadCell:first-child {
      width: 50px;
    }
    .tableBodyCell {
      min-width: 220px;
      max-width: 300px;
      word-break: break-all;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:first-child {
        min-width: 50px;
      }
    }
    .tableBodyRow {
      display: flex;
      width: fit-content;
      min-width: 100%;
      border-bottom: 1px solid ${theme.palette.default.border};
    }
    .activeRow {
      width: fit-content;
      background-color: ${theme.palette.action.selected};
      &.disabled {
        background-color: ${theme.palette.default.lightGrey};
        color: ${theme.palette.default.grey};
      }
    }
    .no-more-entries {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-weight: 400;
      font-size: 1rem;
      display: flex;
      justify-content: center;
    }
  }
`;

export const PreviewNotAvailable = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 50%;
  gap: 1rem;
  transform: translateY(-50%);
  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .not-found img {
    width: 90%;
  }
  h4 {
    font-weight: 400;
    font-size: 20px;
  }
`;

export const EditBroadcast = styled.div`
  .hedaer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid ${theme.palette.default.grey};

    .title {
      padding-left: 0.9rem;
    }

    .title h4 {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .close {
      padding-right: 0.9rem;
      display: flex;
      align-items: center;
    }

    .close svg {
      font-size: 1.4rem;
      font-weight: 500;
      color: ${theme.palette.default.darkGrey};
    }
  }

  .body {
    padding: 1rem;
    .form-wrap {
      label {
        font-weight: 500;
        font-size: 1.1rem;
        color: ${theme.palette.default.black};
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
    border-top: 1px solid ${theme.palette.default.grey};
    .save {
      padding-right: 0.9rem;
    }
  }
`;

export const WrapperContainer = styled.div`
  width: 100%;
  height: 100%;
`;
// Css for topbar
export const TopBar = styled.div`
  box-shadow: ${theme.palette.default.shadow};
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  position: relative;
`;
// Css for topbar leftCol
export const LeftCol = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  color: ${theme.palette.default.darkGrey};
  flex-wrap: wrap;
  .limit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    strong {
      color: ${theme.palette.default.text};
    }
  }
  span {
    color: ${theme.palette.default.text};
    margin-left: 5px;
  }
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-left: 5px;
  }
  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
  }
`;
// Css for topbar rightCol
export const RightCol = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  gap: 1.4rem;
  .contact {
    display: flex;
    flex-direction: column;
  }
  .conversations {
    display: flex;
    align-items: center;
  }
  .credits {
    display: flex;
    align-items: center;
    padding: 16px;
  }

  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
    gap: 0.8rem;
    .buy-credits__btn {
      button {
        width: 93px;
      }
    }
  }
`;
// Css for topbar col
export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${theme.palette.default.darkGrey};
  label {
  }
  &:first-child {
    strong {
      margin-left: 0;
    }
  }
  strong {
    color: ${theme.palette.default.text};
    font-weight: 500;
    font-size: 1rem;
    margin-left: 4px;
  }
`;
// Css for Analytics List Wrapper
export const AnalyticsBoxWrapper = styled.div`
  & {
    display: flex;
    gap: 10px;
    -webkit-box-pack: start;
    justify-content: space-between;
    overflow: auto;
    margin: 16px;

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
`;

export const AnalyticsBox = styled.div`
  & {
    display: flex;
    padding: 2rem 0;
    border-radius: 1rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    flex-grow: 1;
    max-height: 191px;
    min-height: 191px;
    background-color: ${theme.palette.default.white};
    .top {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #0d9d1b;

        .MuiSvgIcon-root {
          font-size: 22px;
          color: ${theme.palette.default.white};
        }
      }

      .send {
        .MuiSvgIcon-root {
          transform: rotate(315deg);
        }
      }

      h6 {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }

    .bottom {
      h4 {
        font-size: 2.25rem;
        font-weight: 400;
        .percent {
          font-size: 1rem;
          color: #0f5500;
        }
      }
    }

    .box-left {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      padding-left: 1rem;
      gap: 1rem;
      width: 50%;
    }

    .box-right {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      padding-left: 1rem;
      border-left: 1px dashed ${theme.palette.default.grey};
      padding-left: 1rem;
      width: 50%;
    }
  }
`;

export const AnalyticsWrapper = styled.div`
  padding: 10px 16px 6px;
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      color: ${theme.palette.default.black};
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      font-weight: 500;
    }
  }
  .analyticsDataset {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .col {
      height: 154px;
      padding: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-radius: 8px;
      margin-top: 17px;

      .iconWrapper {
        background-color: ${theme.palette.default.white};
        width: 40px;
        height: 40px;
        border-radius: 5px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        background: #ffffff;
        box-shadow: 3px 3px 6px rgba(112, 170, 112, 0.15),
          inset 2px 2px 4px rgba(177, 199, 177, 0.15);
        border-radius: 8px;
        svg {
          color: ${theme.palette.primary.main};
        }
      }
      h6 {
        display: flex;
        align-items: center;
        color: ${theme.palette.default.black};
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        .customTooltip {
          margin-left: 5px;
          max-width: 13px;
          max-height: 13px;
        }
      }
      strong {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
`;
export const BroadcastListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  padding: 0 1rem;
`;

export const BroadcastDetailListWrapper = styled.div`
  // background-color: ${theme.palette.default.lightGrey};
  margin: 1rem;
  border-radius: 4px;
  overflow: hidden;
  .detailSMStableAction-wrapepr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-wrapper {
    min-height: 400px;
  }
  .detailSMStableAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
`;
export const TemplateTable = styled.div`
  && {
    position: relative;
    overflow: hidden;
    &.details-table {
      .tabel-heading {
        padding-left: 11px;
      }
    }
    .table-wrapper {
      // border-left: 1px solid ${theme.palette.default.border};
      // border-bottom: 1px solid ${theme.palette.default.border};
      // border-right: 1px solid ${theme.palette.default.border};
      // border-bottom: 1px solid ${theme.palette.default.border};
      overflow: auto;
      .table-content,
      .table-body {
        .table-data__row {
          justify-content: stretch;
        }
        a {
          color: ${theme.palette.primary.main};
          display: block;
          &:hover {
            color: ${theme.palette.default.black};
          }
        }
      }
      .table-cell {
        border-bottom: 1px solid ${theme.palette.default.border};
        background-color: ${theme.palette.default.white};
        padding: 13px 20px;
        .MuiButtonBase-root {
          font-size: 1rem;
          padding: 3px;
        }
        p {
          font-size: 13px;
        }
      }
      .table-cell__broadcast {
        border-bottom: 1px solid ${theme.palette.default.border};
        padding: 13px 20px;
        flex: 1;
        .MuiButtonBase-root {
          font-size: 1rem;
          padding: 3px;
        }
        p {
          font-size: 13px;
        }
      }
      .tabel-heading {
        padding: 0;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${theme.palette.default.white};
        .table-cell {
          padding: 13px 20px;
        }
        h6 {
          font-size: 14px;
          font-weight: 500;
          font-family: ${theme.typography.fontFamily};
        }
        .table-head-text {
          flex: 1;
          display: flex;
          word-break: break-all;
          align-items: center;
          height: 100%;
          font-weight: 500;
        }
      }
      .pending {
        color: ${theme.palette.default.text};
        font-weight: 600;
      }
      .rejected {
        color: ${theme.palette.default.error};
        font-weight: 600;
      }
      .success {
        color: ${theme.palette.default.success};
        font-weight: 400;
      }
      .align-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .table-head {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: auto;
      }
      .table-text {
        color: ${theme.palette.default.greyText};
      }
    }
  }
`;

export const MainContainer = styled.div`
  & {
    // border: 1px solid red;
    // margin: 0 auto;
    // overflow: auto;
    padding: 1rem;
    // .table-cell, .tabel-cell {
    //   min-width: 150px;
    //   display: table-cell !important;
    //   &.align-center {
    //     p {
    //       text-align: center;
    //     }
    //   }
    // }
    // .table-content {
    //   display: table-header-group;

    //   .table-data__row {
    //     display: table-row;

    //   }
    // }
  }
`;
export const ErrorComponent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0rem;
    height: calc(100% - 48px);
    .err-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 80%;
      height: auto;
    }
    .error-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .err-content {
      gap: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title{
        font-size:24px;
        padding-top:10px;
        margin-right:15px;
      }
    }
    .error-Info {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h6 {
        color: ${theme.palette.default.black};
      }
    }
    .err-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      p {
        color: ${theme.palette.default.darkGrey};
      }
    }
  }
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  &.datePicker {
    > div > div:first-child {
      padding: 0;
      height: 28px;
    }
  }
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 180px;
    width: 210px;
    height: 35px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;
    .MuiSelect-select {
      padding: 5px;
      font-size: 14px;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Skeleton } from "@mui/material";
import uniqid from "uniqid";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppCustomTooltip from "../../../../AppComponents/AppTooltip";
import { getTextWithoutHtml } from "../../../../Utils";
import moment from "moment";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { EditorState, Modifier } from "draft-js";
import { AnalyticsBox } from "../BroadcastHistory/HistoryStyles";
import { format } from "path";
import { ca } from "date-fns/locale";
import { AnyIfEmpty } from "react-redux";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";

export const renderSkeleton = () => {
  return (
    <Box
      display="flex"
      sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
      className="tabel-heading"
    >
      <Box width="33.33%" p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
      <Box width="33.33%" p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
      <Box width="33.33%" p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
    </Box>
  );
};

export const renderAnalyticsData = (data: any) => {
  return data.map((item: any) => {
    return (
      <AnalyticsBox
        width={item?.middle}
        style={{
          border: `1px solid ${item?.borderBottom}`,
          borderBottom: `4px solid ${item?.borderBottom}`,
        }}
      >
        <div className="box-left">
          <div className="top">
            <div
              className="icon"
              style={{ background: `${item?.left?.bgColor}` }}
            >
              <AppMaterialIcons
                iconName={`${item?.left?.icon}`}
                style={{ color: `${item?.left?.iconColor}` }}
              />
            </div>
            <h6>{item?.left?.name}</h6>
            <AppToolTip
              tooltipProps={{
                title: `${item?.left?.tooltiptext}`,
                placement: "right",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<InfoOutlinedIcon fontSize="small" />}
            />
          </div>
          <div className="bottom">
            <h4>
              {item?.left?.count !== undefined ? (
                item?.left?.count
              ) : (
                <Skeleton width={110} />
              )}
              {/* <span className="percent">+20%</span> */}
            </h4>
          </div>
        </div>
        {item?.middle ? (
          <div className="box-middle" style={{ paddingLeft: "0px" }}>
            <div className="top">
              <div
                className="icon"
                style={{ background: `${item?.middle?.bgColor}` }}
              >
                <AppMaterialIcons
                  iconName={`${item?.middle?.icon}`}
                  style={{ color: `${item?.middle?.iconColor}` }}
                />
              </div>
              <h6>{item?.middle?.name}</h6>
              <AppToolTip
                tooltipProps={{
                  title: `${item?.middle?.tooltiptext}`,
                  placement: "right",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<InfoOutlinedIcon fontSize="small" />}
              />
            </div>
            <div className="bottom">
              <h4>
                {item?.middle?.count !== undefined ? (
                  item?.middle?.count
                ) : (
                  <Skeleton width={110} />
                )}
                {/* <span className="percent">+20%</span> */}
              </h4>
            </div>
          </div>
        ) : null}

        <div className="box-right">
          <div className="top">
            <div
              className="icon"
              style={{ background: `${item?.right?.bgColor}` }}
            >
              <AppMaterialIcons
                iconName={`${item?.right?.icon}`}
                style={{ color: `${item?.right?.iconColor}` }}
              />
            </div>
            <h6>{item?.right?.name}</h6>
            <AppToolTip
              tooltipProps={{
                title: `${item?.right?.tooltiptext}`,
                placement: "right",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<InfoOutlinedIcon fontSize="small" />}
            />
          </div>
          <div className="bottom">
            <h4>
              {item?.right?.count !== undefined ? (
                item?.right?.count
              ) : (
                <Skeleton width={110} />
              )}
              {/* <span className="percent">+20%</span> */}
            </h4>
          </div>
        </div>
      </AnalyticsBox>
    );
  });
};

// Render WhatsApp Analytics Statistics
export const renderAnalyticsColumn = (data: any, apiData?: any) => {
  return data.map((col: any) => {
    return (
      <Grid item xs={3} md={3} lg={2} xl={1.5}>
        <Box
          key={uniqid()}
          className="col"
          sx={{ backgroundColor: `${col.background}` }}
        >
          <span className="iconWrapper">
            {typeof col.icon === "string" ? (
              <AppMaterialIcons
                iconName={col.icon}
                style={{ color: `${col.color}` }}
              />
            ) : (
              col.icon
            )}
          </span>
          <h6>
            {col.label}{" "}
            <AppToolTip
              tooltipProps={{
                title: col.info,
                placement: "right",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<InfoOutlinedIcon fontSize="small" />}
            />
          </h6>
          <strong>{apiData[col.key]}</strong>
        </Box>
      </Grid>
    );
  });
};

export const convertHtmlToWhatsappText = (text: string) => {
  if (!text) return;

  let strBold = text;
  let replacedBold = strBold
    ?.replace(/<strong>/g, "*")
    ?.replace(/<\/strong>/g, "*");

  let strItalic = replacedBold;
  let replacedItalic = strItalic
    ?.replace(/<em>/g, "_")
    ?.replace(/<\/em>/g, "_");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    ?.replace(/<del>/g, "~")
    ?.replace(/<\/del>/g, "~");

  let strMono = replacedStrike;
  let replacedMono = strMono
    ?.replace(/<tt>/g, "```")
    ?.replace(/<\/tt>/g, "```")
    ?.replace(/<code>/g, "```")
    ?.replace(/<\/code>/g, "```");
  let whatsappText: string = getTextWithoutHtml(replacedMono || "").replace(
    /&nbsp;/g,
    " "
  );
  return whatsappText;
};

export const insertEditorText = (text: any, editorValue: any) => {
  const currentContent = editorValue.getCurrentContent();
  const currentSelection = editorValue.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    text
  );

  const newEditorState = EditorState.push(
    editorValue,
    newContent,
    "insert-characters"
  );
  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter()
  );
};

export const convertWhatsappTextToHtml = (text: string) => {
  if (!text) return;

  let strBold = text;
  // let replacedBold = strBold
  //   .replace(/\*(?=\w)/, "<strong>")
  //   .replace(/(?<=\w)\*/, "</strong>");
  let replacedBold = strBold.replace(/\*(.*?)\*/g, "<strong>$1</strong>");

  let strItalic = replacedBold;
  let replacedItalic = strItalic
    .replace(/\_(?=\w)/, "<em>")
    .replace(/(?<=\w)\_/, "</em>");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    .replace(/\~(?=\w)/, "<del>")
    .replace(/(?<=\w)\~/, "</del>");

  return replacedStrike;
};

export const VariableRegexReplace = (
  originalString: string,
  replacementArray: string[]
) => {
  // Define the regex pattern and replacement array
  const regexPattern = /\{\{([1-9]|10)\}\}/gm;

  // Function to handle the replacement
  const handleReplacement = (match: string, index: number) => {
    // Get the index from the match
    const matchIndex = parseInt(match?.slice(2, -2), 10);

    // Check if the index is within the range of the replacement array
    if (matchIndex >= 1 && matchIndex <= replacementArray?.length) {
      // Return the replacement string from the array
      return replacementArray[matchIndex - 1];
    } else {
      // Return the original match if the index is out of range
      return match;
    }
  };
};
// export const updateVariableStatus = (
//   inputString: string,
//   variableLimit: number = 10
// ) => {

// var tempArray = inputString?.split(" ");
// var variablesArray = [];
// for (var i = 0; i < tempArray.length; i++) {
//   if (/\{{([^)]+)\}}/gm.test(tempArray[i])) {
//     if (variableLimit >= variablesArray?.length) {
//       variablesArray.push(tempArray[i]);
//     }
//   }
// }
// return variablesArray;
// };

// carousel component conversion

function transformCarouselObject(input: any) {
  return {
    type: "CAROUSEL",
    cards: input?.map((item: any, cardIndex: number) => {
      const headerType = item.card_header_type.toLowerCase();
      return {
        components: [
          {
            type: "HEADER",
            format: headerType,
            example: {
              header_handle: [item?.card_header?.id],
            },
          },
          {
            type: "BODY",
            text: item?.text,
            ...(item?.carousel_example?.length > 0 && {
              example: {
                body_text: [
                  item?.carousel_example?.map(
                    (variable: any) => variable.resolve
                  ),
                ],
              },
            }),
          },
          item?.carousel_buttons?.length > 0 && {
            type: "BUTTONS",
            buttons: item?.carousel_buttons?.map((button: any) => {
              if (button?.buttons_type === "QUICK_REPLY") {
                return {
                  type: "QUICK_REPLY",
                  text: button?.quick_reply,
                };
              } else if (["dynamic", "static"].includes(button?.sub_type)) {
                return {
                  type: "URL",
                  text: button?.text,
                  url: button?.url,
                  ...(button?.dyanmic_variable_for_url_mapping?.length > 0 && {
                    example: button?.dyanmic_variable_for_url_mapping?.map(
                      (variable: any) => variable.resolve
                    ),
                  }),
                };
              } else {
                return {
                  type: "PHONE_NUMBER",
                  text: button?.phone_number_text,
                  phone_number: button?.phone_number,
                };
              }
            }),
          },
        ],
      };
    }),
  };
}
async function fetchImageAsFile(url: string): Promise<File | undefined> {
  try {
    // Fetch the image as a blob
    const response = await fetch(url);
    if (!response.ok) throw new Error("Failed to fetch image");

    const blob = await response.blob();

    // Create a File object
    const file = new File([blob], "image.png", {
      type: blob.type,
      lastModified: Date.now(), // Set the lastModified date to current time
    });

    return file;
  } catch (error) {
    console.error("Error fetching and converting image to File:", error);
    return undefined;
  }
}

async function uploadImage(file: File | undefined) {
  if (!file) {
    console.error("No file to upload");
    return;
  }

  try {
    const formData = new FormData();
    formData.append("source", file);

    // Replace with your actual upload service
    const response = await ChatbotConsoleService.uploadWhatsappMedia(
      formData,
      false
    );
    return response;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Re-throw to handle it in the calling code
  }
}

// Example usage
async function handleImageUpload(imageUrl: string) {
  try {
    const file = await fetchImageAsFile(imageUrl);
    const response = await uploadImage(file);
    return response;
  } catch (error) {
    console.error("Error handling image upload:", error);
  }
}

export const dataToWhatsappTemplatePayload = async (
  values: any,
  isDefaultTemplate: boolean
) => {
  let textHeader: any = {};
  let mediaHeader: any = {};
  let payloadObject: any = {};
  let body: any = {};
  let footer: any = {};
  //Common for all template types
  //body creation
  body = {
    type: "BODY",
    text: convertHtmlToWhatsappText(values?.body_value),
    ...(values?.body_value &&
      values.variables_body?.length > 0 && {
        example: {
          body_text: [values.variables_body],
        },
      }),
  };

  //footer creation
  footer = {
    type: "FOOTER",
    text: values?.footer_value,
    ...(values?.footer_value &&
      values?.variables_footer?.length > 0 && {
        example: {
          body_text: [values?.variables_footer],
        },
      }),
  };
  //text headers creation

  textHeader = {
    type: "HEADER",
    format:
      typeof values?.header_type === "object"
        ? values?.header_type?.value
        : values?.header_type,
    text: values?.header_value,
    ...(values?.variables_header &&
      values?.variables_header?.length > 0 && {
        example: {
          header_text: values?.variables_header,
        },
      }),
  };
  //media header creation
  if (values?.header_value?.id?.includes("https://")) {
    const file = await handleImageUpload(values?.header_value?.id);
    mediaHeader = {
      type: "HEADER",
      format:
        typeof values?.header_type === "object"
          ? values?.header_type?.value
          : values?.header_type,
      example: {
        header_handle: [file?.data?.id],
      },
    };
  } else {
    mediaHeader = {
      type: "HEADER",
      format:
        typeof values?.header_type === "object"
          ? values?.header_type?.value
          : values?.header_type,
      example: {
        header_handle: [values?.header_value?.id],
      },
    };
  }

  if (values?.button_type === "copy_code_url_quick_replies_etc") {
    // Buttons creation
    const buttons = values.copyUrlOptions?.reduce(
      (acc: any[], item: any, index: number) => {
        if (item?.add_quick_replies && item.checked) {
          acc.push(...values.quick_replies);
        }
        if (item?.add_coupon_code && item.checked) {
          acc.push({
            type: "COPY_CODE",
            example: values?.coupon_code,
          });
        }
        if (item?.add_phone_number && item.checked) {
          acc.push({
            type: "PHONE_NUMBER",
            text: values?.phone_number_button_text,
            phone_number: values?.phone_number,
          });
        }
        if (item?.add_website_url && item.checked) {
          const websiteObj = values?.website_url?.map((webUrl: any) => {
            return {
              type: "URL",
              text: webUrl?.button_text,
              url: webUrl?.url,
              ...(webUrl?.dynamic_variables_for_url?.length > 0 && {
                example: webUrl?.dynamic_variables_for_url?.map(
                  (item: any) => item?.resolve
                ),
              }),
            };
          });
          acc.push(...websiteObj);
        }
        return acc;
      },
      []
    );
    const buttonComponent =
      buttons?.length > 0 ? { type: "BUTTONS", buttons } : null;

    // Construct payloadObject
    const components = [
      body,
      buttonComponent,
      values?.footer_value ? footer : null,
    ].filter(Boolean); // Filter out null or undefined components
    let type =
      typeof values?.header_type === "object"
        ? values?.header_type?.value
        : values?.header_type;
    if (type === "TEXT") components.unshift(textHeader);
    else if (["IMAGE", "VIDEO", "DOCUMENT"].includes(type))
      components.unshift(mediaHeader);

    payloadObject = {
      name: values?.template_name.replace(/\s+/g, " ").trim() || "",
      category: values?.template_category,
      language: values?.template_language,
      components,
    };
  } else if (values?.button_type === "limited_time_offer") {
    let header: any = {};
    header = {
      type: "header",
      format:
        typeof values?.header_type === "object"
          ? values?.header_type?.value
          : values?.header_type,
      example: {
        header_handle: [values?.header_value?.id],
      },
    };
    // Construct payloadObject
    let limited_time_offer_component: any = {
      type: "limited_time_offer",
      limited_time_offer: {
        text: values?.offer_title,
        has_expiration: true,
      },
    };
    /* Copy code button component optional */
    let offersObj: any = {
      type: "buttons",
      buttons: [
        {
          type: "copy_code",
          example: values?.offer_coupon_code,
        },
        {
          type: "url",
          text: values?.offer_button_text,
          url: values?.offer_url,
          ...(values?.offer_url_type === "dynamic" &&
            values?.offer_url_mapping?.length > 0 && {
              example: values?.offer_url_mapping?.map(
                (item: any) => item?.variable
              ),
            }),
        },
      ],
    };
    const components = [
      limited_time_offer_component,
      body,
      offersObj,
      values?.footer_value ? footer : null,
    ].filter(Boolean);
    let type =
      typeof values?.header_type === "object"
        ? values?.header_type?.value
        : values?.header_type;
    if (["IMAGE", "VIDEO"].includes(type)) {
      components.unshift(header);
    }
    payloadObject = {
      name: values?.template_name.replace(/\s+/g, " ").trim() || "",
      category: values?.template_category,
      language: values?.template_language,
      components,
    };
  } else if (values?.button_type === "carousel") {
    const components = [
      body?.text ? body : null,
      values?.footer_value ? footer : null,
    ].filter(Boolean); // Filter out null or undefined components
    payloadObject = {
      name: values?.template_name.replace(/\s+/g, " ").trim() || "",
      category: values?.template_category,
      language: values?.template_language,
      components,
    };
    payloadObject?.components.push(transformCarouselObject(values?.carousel));
  } else if (values?.button_type === "flow") {
    let type =
      typeof values?.header_type === "object"
        ? values?.header_type?.value
        : values?.header_type;
    let data = {
      type: "BUTTONS",
      buttons: [
        {
          type: "flow",
          flow_id: values?.flow[0]?.flow_id,
          navigate_screen: values?.flow[0]?.navigate_screen,
          text: values?.flow[0]?.text,
          flow_action: "navigate",
        },
      ],
    };
    const components = [
      body?.text ? body : null,
      values?.footer_value ? footer : null,
    ].filter(Boolean); // Filter out null or undefined components
    components.push(data);
    if (type === "TEXT") components.unshift(textHeader);
    else if (["IMAGE", "VIDEO", "DOCUMENT"].includes(type))
      components.unshift(mediaHeader);
    payloadObject = {
      name: values?.template_name.replace(/\s+/g, " ").trim() || "",
      category: values?.template_category,
      language: values?.template_language,
      components,
    };
  } else if (values?.button_type === "none") {
    const components = [
      body?.text ? body : null,
      values?.footer_value ? footer : null,
    ].filter(Boolean); // Filter out null or undefined components
    let type =
      typeof values?.header_type === "object"
        ? values?.header_type?.value
        : values?.header_type;
    if (type === "TEXT") components.unshift(textHeader);
    else if (["IMAGE", "VIDEO", "DOCUMENT"].includes(type))
      components.unshift(mediaHeader);

    payloadObject = {
      name: values?.template_name.replace(/\s+/g, " ").trim() || "",
      category: values?.template_category,
      language: values?.template_language,
      components,
    };
  }
  if (values?.template_category === "AUTHENTICATION") {
    let components = [
      {
        type: "BODY",
        add_security_recommendation: values?.add_security_recommendation,
      },
      {
        type: "FOOTER",
        code_expiration_minutes: parseInt(values?.code_expiration_minutes),
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "OTP",
            otp_type: "COPY_CODE",
          },
        ],
      },
    ];
    let payloadObject = {
      name: values?.template_name.replace(/\s+/g, " ").trim() || "",
      category: values?.template_category,
      language: values?.template_language,
      components: components,
    };
    return payloadObject;
  }
  payloadObject["template_type"] = values?.button_type;
  return payloadObject;
};

export const templateDataToUserFormData = async (response: any) => {
  let userInput: any = {
    template_name: response?.name,
    template_category: response?.category,
    template_language: response?.language,
    header_type: response?.components?.header?.value?.type,
    header_value: response?.components?.header?.value?.value,
    header_text:
      response?.components?.header?.value?.type === "text"
        ? generateResolveVariables(
            response?.components?.header?.value?.value,
            response?.components?.header?.value?.header_text
          )
        : [],
    body_value: response?.components?.body?.value,
    body_text: generateResolveVariables(
      response?.components?.body?.value,
      response?.components?.body?.body_text
    ),

    footer_value: response?.components?.footer?.value,
    footer_text: [],

    buttons_type: response?.components?.button?.button_type || "none",
    cta_buttons:
      response?.components?.button?.button_type === "call_to_action"
        ? response?.components?.button?.value
        : [],
    quick_buttons:
      response?.components?.button?.button_type === "quick_reply"
        ? response?.components?.button?.value
        : [],
    buttons_text: [],

    variables_header: response?.components?.header?.value?.header_text,
    variables_body: response?.components?.body?.body_text,
    variables_footer: [],
    variables_button: response?.components?.button?.example || [],

    id: response?.id || null,
    original_response: response || null,
  };

  if (response?.components?.header?.value?.type !== "text") {
    userInput["header_value"] = response?.components?.header?.value?.attachment;
  }

  response?.components?.button?.value?.length &&
    response?.components?.button?.value?.forEach((curItem: any) => {
      if (curItem?.url_type === "dynamic") {
        userInput["buttons_text"] = [{ key: "{{1}}", value: "", index: 0 }];
      }
    });
  return userInput;
};

export const dataToTemplateFormData = async (response: any) => {
  let userInput: any = {
    template_name: response?.name,
    template_category: response?.category,
    template_language: response?.language,
    template_id: response?.id,

    // header_type: response?.components?.header?.value?.type,
    // header_value: response?.components?.header?.value?.value,
    // header_text:
    //   response?.components?.header?.value?.type === "text"
    //     ? response?.components?.header?.value?.header_text
    //     : [],
    // body_value: response?.components?.body?.value,
    // body_text: response?.components?.body?.body_text || [],
    // footer_value: response?.components?.footer?.value,
    // footer_text: [],

    // buttons_type: response?.components?.button?.button_type || "none",
    // cta_buttons:
    //   response?.components?.button?.button_type === "call_to_action"
    //     ? response?.components?.button?.value
    //     : [],
    // quick_buttons:
    //   response?.components?.button?.button_type === "quick_reply"
    //     ? response?.components?.button?.value
    //     : [],
    // buttons_text: [],

    // variables_header: response?.components?.header?.value?.header_text,
    // variables_body: response?.components?.body?.body_text,
    // variables_footer: [],
    // variables_button:
    //   response?.components?.button?.value[0]?.example ||
    //   response?.components?.button?.value[1]?.example ||
    //   [],

    // id: response?.id || null,
    original_response: (await getInputValues(response)) || null,
  };

  // if (response?.components?.header?.value?.type !== "text") {
  //   userInput["header_value"] = response?.components?.header?.value?.attachment;
  // }

  // response?.components?.button?.value?.length &&
  //   response?.components?.button?.value?.forEach((curItem: any) => {
  //     if (curItem?.url_type === "dynamic") {
  //       userInput["buttons_text"] = [{ key: "{{1}}", value: "", index: 0 }];
  //     }
  //   });

  return userInput;
};
// File: utils.ts

export function extractVariables(text: string): string[] {
  const regex = /\{\{(\w+)\}\}/g;
  const matches: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = regex.exec(text)) !== null) {
    matches.push(match[0]);
  }

  return matches;
}

export function convertTextToHTML(value: string): string {
  let text = value;

  text = text
    .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // Bold
    .replace(/_(.*?)_/g, "<em>$1</em>"); // Italics

  const paragraphs = text
    .split("\n")
    .map((para) => para.trim())
    .filter((para) => para.length > 0);

  const htmlText = paragraphs.map((para) => `<p>${para}</p>`).join("\n");

  return htmlText;
}

type AnyType = any;
let getInputValues = (data: any) => {
  const inputValues: AnyType = {};

  let carouselImage = [
    {
      attachment_id: "5NaCdnrFY3QozHzA",
      file_name: "pic1.jpg",
      id: "4:cGljMS5qcGc=:aW1hZ2UvanBn:ARbmpNJXdnvrNsSfHLGpFZ_GEFP0qRO7Zt0CTp_7XmUVCGuFsXux9oYc4SNHWi5KeqvtLR0CLXxICAaaFQpsVY-dvjZcGoiCDLVJR_uYnoXqfQ:e:1722250271:544372894068159:61560950707754:ARYA8SBwUucLh8KJBS4",
      source: "https://assets.getalabs.com/3cSmD2_pic1.jpg",
      status: true,
    },
    {
      attachment_id: "32zaa7YTZBdKnM2k",
      file_name: "pic2.jpeg",
      id: "4:cGljMi5qcGVn:aW1hZ2UvanBlZw==:ARZT-hHE3WWmnyxYp-GP_1L8oJVXlGSJSCE_QxAr_WWxu2aG6ewgL4e_dOzWqkZC4xHt4KxbLaWFhkXxVhS8YHD_a8yAhqkXmCWkBZ5DgmJ7gg:e:1722250279:544372894068159:61560950707754:ARbkP-qKC4bnS76xHjE",
      source: "https://assets.getalabs.com/3mUwbU_pic2.jpeg",
      status: true,
    },
    {
      attachment_id: "FH6UY7CG3oQbt884",
      file_name: "pic3.jpeg",
      id: "4:cGljMy5qcGVn:aW1hZ2UvanBlZw==:ARaNCd4c7grmctYv3GBjBzj5XAGwz5dn35-EihQ6j0eqaQE1wkI0DwxkzljnsnoqdqEUhz36rzn1aqqBI_EkObcZwY4kM1IT-f9nmzA1CrBCPA:e:1722250286:544372894068159:61560950707754:ARYMaZZmsaOjRYjDttk\n4:cGljMy5qcGVn:aW1hZ2UvanBlZw==:ARZi5KywNDB3Y7PM5v08W1JNH8ncZ3WwSQE2SUdaU_43ScLY1lxxJHK_aIsN9K0zFF-iJeUxvLau3jMzCNABsum5M9oG2ltR_E8SaC-dIpa9ug:e:1722250286:544372894068159:61560950707754:ARbCG04MskdQFQoPYZw",
      source: "https://assets.getalabs.com/35n36a_pic3.jpeg",
      status: true,
    },
    {
      attachment_id: "kWPgW2D6enwb54xw",
      file_name: "pic4.jpg",
      id: "4:cGljNC5qcGc=:aW1hZ2UvanBn:ARYUxeMYP_4O-weWmwnC2A5u_b9LmOsWO2R0XqM0A5-7U2LulP_Yv6tkWMTjtbAvNgoreeDcZWd99rmBf6bWHZ6PpxVEtULoTy40oiIAlPTMEg:e:1722250305:544372894068159:61560950707754:ARZ2zTqF5AZ9KDyVQNk",
      source: "https://assets.getalabs.com/rc43mq_pic4.jpg",
      status: true,
    },
    {
      attachment_id: "AigYPXxqUFQs7oRX",
      file_name: "pic5.jpg",
      id: "4:cGljNS5qcGc=:aW1hZ2UvanBn:ARYH0SaSjJK_SR5oYCOgNJ7hlSUt7oxAoZQmljuvFjv6mPK7yliNgSX0r_f0BFy494iUt4ypsPK9ugMVZpjQuNszmmjEiG22YhgxT3jNlC0P2g:e:1722250340:544372894068159:61560950707754:ARZcDfM3orUEoqcxRVM\n4:cGljNS5qcGc=:aW1hZ2UvanBn:ARZpI7PfnxwlpiN3JCBB23Im0WbJq7aijAG6MqmB-zgDbPn1p-tC75QZ3HS-7TOPGqBzZ8mlpKBOWOH9yh3QqJZYlzw91pcK7beg4DucVzixgw:e:1722250340:544372894068159:61560950707754:ARZVSsV70eVqcAeLd6Q",
      source: "https://assets.getalabs.com/45gDsj_pic5.jpg",
      status: true,
    },
    {
      attachment_id: "mWrxA8fG5HtucM8n",
      file_name: "pic6.jpg",
      id: "4:cGljNi5qcGc=:aW1hZ2UvanBn:ARb6B2LSao5wtE5aC3GCMdD623lhhncuJknACRQdbtG50wyyVkWKZfxdn6Szcnqt_4jj6nX6HLdpK_sXQig_E40_yBx5iexMN4_Jr_S51jGTbA:e:1722250395:544372894068159:61560950707754:ARaGsG4MtGS65LYPSxY",
      source: "https://assets.getalabs.com/92eXYx_pic6.jpg",
      status: true,
    },
    {
      attachment_id: "RaZPq6FWB757a2BA",
      file_name: "pic7.jpg",
      id: "4:cGljNy5qcGc=:aW1hZ2UvanBn:ARaNlTFZtXruvEzd1lnIGZcAr-59vgct9yBCVJcWp1KawRtkuwjH7fBqoZ1kNG6BJq8vKMkF01fFJ6gwnBQYv2bwKFuDKrVRX3QzAFSZHI7Y8w:e:1722250415:544372894068159:61560950707754:ARa_ukP1BKCOIIN6kMo",
      source: "https://assets.getalabs.com/3kdepi_pic7.jpg",
      status: true,
    },
    {
      attachment_id: "FYTi7scuJ9LAgupg",
      file_name: "pic8.jpg",
      id: "4:cGljOC5qcGc=:aW1hZ2UvanBn:ARao5pu-CWh1hV51zdsgGd0s44DkUqSjEPwaf-gkSruyVLrUT7mweWCqH41vljHHJXuG-HXNJMD5L7CRkb4UxVJzTmvCy4QsTeJXRTaBEOfSWA:e:1722250460:544372894068159:61560950707754:ARY1weTUkwT34JANzjY",
      source: "https://assets.getalabs.com/B3PEVk_pic8.jpg",
      status: true,
    },
    {
      attachment_id: "apmArUjE7J2aqsbr",
      file_name: "pic9.jpg",
      id: "4:cGljOS5qcGc=:aW1hZ2UvanBn:ARY8AFUygrvoRBTKVeQVCwUW8LGQt1c6iLXQF-Z-BISqIb2l1sKVAM66fzAmCHneT7FKfhPHe0xVEovMMmY0Tg6eywWF5IpzR23GkMLcA1juDQ:e:1722250488:544372894068159:61560950707754:ARYdpuH6KebfWSQhJNQ",
      source: "https://assets.getalabs.com/3vWRBv_pic9.jpg",
      status: true,
    },
    {
      attachment_id: "CJ2uRJJWbS9zevbi",
      file_name: "pic10.jpg",
      id: "4:cGljMTAuanBn:aW1hZ2UvanBn:ARYMoxereoC7VIHPvpinhaWtJT3wQS2d-K9ZyzgISlfWkSmW5lYK-y5uixcWS66HExuC-ojJ2A7yoGFTSnr3i0wXhATzUz2MoNb_iiUlRu-1Ig:e:1722250515:544372894068159:61560950707754:ARYpyECUd4_f-PmegxM",
      source: "https://assets.getalabs.com/4PLvCP_pic10.jpg",
      status: true,
    },
  ];
  data?.components?.map((res: AnyType) => {
    if (res?.type === "HEADER") {
      if (res?.format === "TEXT") {
        inputValues["header_type"] = res?.format;
        inputValues["header_value"] = res?.text;
        inputValues["header_text"] = extractVariables(res?.text);
        inputValues["variables_header"] = res?.example?.header_text;
      } else if (res?.format === "IMAGE") {
        inputValues["header_type"] = res?.format;
        inputValues["header_text"] = extractVariables(res?.text);
        inputValues["variables_header"] = res?.example?.header_text;
        inputValues["header_value"] = res?.example?.header_handle
          ? {
              source: res?.example?.header_handle?.[0],
              file_name: "526448-colors.jpg",
              attachment_id: "6VjQjai4JoWWPpek",
              status: "true",
              id: res?.example?.header_handle?.[0],
            }
          : {
              attachment_id: "5NaCdnrFY3QozHzA",
              file_name: "pic1.jpg",
              id: "4:cGljMS5qcGc=:aW1hZ2UvanBn:ARbmpNJXdnvrNsSfHLGpFZ_GEFP0qRO7Zt0CTp_7XmUVCGuFsXux9oYc4SNHWi5KeqvtLR0CLXxICAaaFQpsVY-dvjZcGoiCDLVJR_uYnoXqfQ:e:1722250271:544372894068159:61560950707754:ARYA8SBwUucLh8KJBS4",
              source: "https://assets.getalabs.com/3cSmD2_pic1.jpg",
              status: true,
            };
      } else if (res?.format === "VIDEO") {
        inputValues["header_type"] = res?.format;
        inputValues["header_text"] = extractVariables(res?.text);
        inputValues["variables_header"] = res?.example?.header_text;
        inputValues["header_value"] = res?.example?.header_handle
          ? {
              source: res?.example?.header_handle?.[0],
              file_name: "526448-video.mp4",
              attachment_id: "MiRJXoEfe4DMpKQA",
              status: "true",
              id: res?.example?.header_handle?.[0],
            }
          : {
              attachment_id: "32zaa7YTZBdKnM2k",
              file_name: "video.mp4",
              id: "4:cGljMi5qcGVn:aW1hZ2UvanBlZw==:ARZT-hHE3WWmnyxYp-GP_1L8oJVXlGSJSCE_QxAr_WWxu2aG6ewgL4e_dOzWqkZC4xHt4KxbLaWFhkXxVhS8YHD_a8yAhqkXmCWkBZ5DgmJ7gg:e:1722250279:544372894068159:61560950707754:ARbkP-qKC4bnS76xHjE",
              source:
                "https://assets.getalabs.com/3UdpDK_AHS,_Jhon_Tanzania.mp4",
              status: true,
            };
      } else if (res?.format === "DOCUMENT") {
        inputValues["header_type"] = res?.format;
        inputValues["header_text"] = extractVariables(res?.text);
        inputValues["variables_header"] = res?.example?.header_text;
        inputValues["header_value"] = res?.example?.header_handle
          ? {
              source: res?.example?.header_handle?.[0],
              file_name: "dummy-pdf_2.pdf",
              attachment_id: "9L4kBCVy8SQSD94q",
              status: "true",
              id: res?.example?.header_handle?.[0],
            }
          : {
              attachment_id: "FH6UY7CG3oQbt884",
              file_name: "dummy-pdf_2.pdf",
              id: "4:cGljMy5qcGVn:aW1hZ2UvanBlZw==:ARaNCd4c7grmctYv3GBjBzj5XAGwz5dn35-EihQ6j0eqaQE1wkI0DwxkzljnsnoqdqEUhz36rzn1aqqBI_EkObcZwY4kM1IT-f9nmzA1CrBCPA:e:1722250286:544372894068159:61560950707754:ARYMaZZmsaOjRYjDttk\n4:cGljMy5qcGVn:aW1hZ2UvanBlZw==:ARZi5KywNDB3Y7PM5v08W1JNH8ncZ3WwSQE2SUdaU_43ScLY1lxxJHK_aIsN9K0zFF-iJeUxvLau3jMzCNABsum5M9oG2ltR_E8SaC-dIpa9ug:e:1722250286:544372894068159:61560950707754:ARbCG04MskdQFQoPYZw",
              source: "https://assets.getalabs.com/3NjACi_dummy-pdf_2.pdf",
              status: true,
            };
      }
    }
    if (res?.type === "BODY") {
      inputValues["body_value"] = convertTextToHTML(res?.text);
      inputValues["body_text"] = extractVariables(res?.text);
      inputValues["variables_body"] = res?.example?.body_text?.[0];
    }
    if (res.type === "CAROUSEL") {
      inputValues["button_type"] = "carousel";
      inputValues["carousel"] = [];
      res?.cards?.map((card: AnyType, index: number) => {
        let data: AnyType = {};
        card?.components?.map((components: AnyType) => {
          if (components.type === "HEADER") {
            data["card_header"] = components?.example?.header_handle
              ? {
                  ...carouselImage[index],
                  id: components?.example?.header_handle[0],
                  source: components?.example?.header_handle[0],
                }
              : {
                  ...carouselImage[index],
                };
            data["card_header_type"] = components?.text;
          }
          if (components.type === "BUTTONS") {
            let buttonArray: AnyType = [];
            components?.buttons.map((button: AnyType) => {
              if (button.type === "URL") {
                buttonArray.push(
                  button.example
                    ? {
                        sub_type: "dynamic",
                        dyanmic_variable_for_url_mapping: [
                          {
                            resolve: button.example[0],
                          },
                        ],
                        text: button.text,
                        url: button.url,
                      }
                    : {
                        sub_type: "static",
                        text: button?.text,
                        url: button?.url,
                      }
                );
              }
              if (button?.type === "QUICK_REPLY") {
                buttonArray.push({
                  quick_reply: button?.text,
                  buttons_type: button?.type,
                });
              }
              if (button?.type === "PHONE_NUMBER") {
                buttonArray.push({
                  phone_number_text: button?.text,
                  buttons_type: button?.type,
                  phone_number: button?.phone_number,
                });
              }
            });
            data["carousel_buttons"] = buttonArray;
          }
          if (components.type === "BODY") {
            data["text"] = components?.text;
          }
        });
        inputValues["carousel"].push(data);
      });
    }
    if (res.type === "BUTTONS") {
      let buttonData: AnyType = [];
      res?.buttons?.map((button: AnyType) => {
        if (button.type === "flow") {
          buttonData.push({ ...button });
          inputValues["flow"] = buttonData;
          inputValues["button_type"] = "flow";
        }
      });
      let copyUrlOptions: AnyType = [
        {
          name: "add_quick_replies",
          label: "Add Quick Replies",
          checked: false,
          add_quick_replies: false,
        },
        {
          name: "add_coupon_code",
          label: "Add Coupon Code",
          checked: false,
          add_coupon_code: false,
        },
        {
          name: "add_website_url",
          label: "Add Website Url",
          checked: false,
          add_website_url: false,
        },
        {
          name: "add_phone_number",
          label: "Add Phone Number",
          checked: false,
          add_phone_number: false,
        },
      ];
      let quick_replies: AnyType = [];
      let website_url: AnyType = [];
      res?.buttons?.map((button: AnyType) => {
        if (button.type === "QUICK_REPLY") {
          copyUrlOptions[0].checked = true;
          quick_replies.push({
            type: "QUICK_REPLY",
            text: button?.text,
          });
        }
        if (button.type === "COPY_CODE") {
          copyUrlOptions[1].checked = true;
          inputValues["coupon_code"] = button?.example;
        }
        if (button.type === "URL") {
          copyUrlOptions[2].checked = true;
          website_url.push(
            button.example
              ? {
                  url_type: "dynamic",
                  dynamic_variables_for_url: [
                    {
                      resolve: button.example[0],
                    },
                  ],
                  button_text: button.text,
                  url: button.url,
                }
              : {
                  url_type: "static",
                  button_text: button?.text,
                  url: button?.url,
                }
          );
        }
        if (button.type === "PHONE_NUMBER") {
          copyUrlOptions[3].checked = true;
          inputValues["phone_number"] = button?.phone_number;
          inputValues["phone_number_button_text"] = button?.text;
        }
        inputValues["button_type"] = "copy_code_url_quick_replies_etc";
      });
      inputValues["copyUrlOptions"] = copyUrlOptions;
      inputValues["quick_replies"] = quick_replies;
      inputValues["website_url"] = website_url;
    }
    // if(res.type !== "BUTTONS" && res.type !== "CAROUSEL") {
    //   inputValues['button_type'] = "NONE";
    // }

    if (!inputValues["button_type"]) {
      inputValues["button_type"] = "none";
    }
    if (!inputValues["header_type"]) {
      inputValues["header_type"] = "NONE";
    }
    if (data?.template_type) {
      inputValues["button_type"] = data?.template_type;
    }
    if (res.type === "FOOTER") {
      inputValues["footer_value"] = res?.text;
    }
  });
  console.log("inputValues", inputValues);

  return inputValues;
};
export const removeDuplicateObjects = (array: any[], property: string) => {
  const uniqueIds: any[] = [];

  const unique = array?.filter((element) => {
    const isDuplicate = uniqueIds?.includes(element[property]);

    if (!isDuplicate) {
      uniqueIds?.push(element[property]);

      return true;
    }

    return false;
  });

  return unique;
};

export const removeEmptyKeysObj = (obj: any) => {
  return Object.entries(obj).reduce(
    (a: any, [k, v]) => (v == "" || v == null ? a : ((a[k] = v), a)),
    {}
  );
};

export const convertDateTimeToUTC = (date: any, time: any) => {
  let a = moment(date).format("YYYY-MM-DD");
  let b = moment(time).format("HH:mm:ss");
  let c = moment(a + " " + b, "YYYY-MM-DD HH:mm:ss").toString();
  let utc = moment(c).utc().format();
  return utc;
};
export const convertUTCToDateTime = (utcDateTime: any) => {
  let a = moment.utc(utcDateTime).local().format();
  return a;
};

export const convertDataToWhatsappBroadcastForm = (res: any) => {
  let formData: any = {
    broadcast_name: res?.broadcast_name,
    broadcast_template_id: res?.broadcast_template_id,
    broadcast_action: "broadcast_marketing_list",
    marketing_list: 0,
    filters: null,
    selected_rows: [],
    search_term: "",

    when_to_send: "schedule_broadcast",
    date: res?.sent_on
      ? convertUTCToDateTime(res?.sent_on)
      : new Date(Date.now()),
    time: res?.sent_on
      ? convertUTCToDateTime(res?.sent_on)
      : new Date(Date.now()),

    resolved_header_variables: [],
    resolved_body_variables: [],
    resolved_buttons_variables: [],
  };
  return formData;
};

export const LanguageName = [
  { code: "ab", name: "Abkhaz" },
  { code: "aa", name: "Afar" },
  { code: "af", name: "Afrikaans" },
  { code: "ak", name: "Akan" },
  { code: "sq", name: "Albanian" },
  { code: "am", name: "Amharic" },
  { code: "ar", name: "Arabic" },
  { code: "an", name: "Aragonese" },
  { code: "hy", name: "Armenian" },
  { code: "as", name: "Assamese" },
  { code: "av", name: "Avaric" },
  { code: "ae", name: "Avestan" },
  { code: "ay", name: "Aymara" },
  { code: "az", name: "Azerbaijani" },
  { code: "bm", name: "Bambara" },
  { code: "ba", name: "Bashkir" },
  { code: "eu", name: "Basque" },
  { code: "be", name: "Belarusian" },
  { code: "bn", name: "Bengali" },
  { code: "bh", name: "Bihari" },
  { code: "bi", name: "Bislama" },
  { code: "bs", name: "Bosnian" },
  { code: "br", name: "Breton" },
  { code: "bg", name: "Bulgarian" },
  { code: "my", name: "Burmese" },
  { code: "ca", name: "Catalan; Valencian" },
  { code: "ch", name: "Chamorro" },
  { code: "ce", name: "Chechen" },
  { code: "ny", name: "Chichewa; Chewa; Nyanja" },
  { code: "zh", name: "Chinese" },
  { code: "cv", name: "Chuvash" },
  { code: "kw", name: "Cornish" },
  { code: "co", name: "Corsican" },
  { code: "cr", name: "Cree" },
  { code: "hr", name: "Croatian" },
  { code: "cs", name: "Czech" },
  { code: "da", name: "Danish" },
  { code: "dv", name: "Divehi; Dhivehi; Maldivian;" },
  { code: "nl", name: "Dutch" },
  { code: "en", name: "English" },
  { code: "eo", name: "Esperanto" },
  { code: "et", name: "Estonian" },
  { code: "ee", name: "Ewe" },
  { code: "fo", name: "Faroese" },
  { code: "fj", name: "Fijian" },
  { code: "fi", name: "Finnish" },
  { code: "fr", name: "French" },
  { code: "ff", name: "Fula; Fulah; Pulaar; Pular" },
  { code: "gl", name: "Galician" },
  { code: "ka", name: "Georgian" },
  { code: "de", name: "German" },
  { code: "el", name: "Greek, Modern" },
  { code: "gn", name: "Guaraní" },
  { code: "gu", name: "Gujarati" },
  { code: "ht", name: "Haitian; Haitian Creole" },
  { code: "ha", name: "Hausa" },
  { code: "he", name: "Hebrew (modern)" },
  { code: "hz", name: "Herero" },
  { code: "hi", name: "Hindi" },
  { code: "ho", name: "Hiri Motu" },
  { code: "hu", name: "Hungarian" },
  { code: "ia", name: "Interlingua" },
  { code: "id", name: "Indonesian" },
  { code: "ie", name: "Interlingue" },
  { code: "ga", name: "Irish" },
  { code: "ig", name: "Igbo" },
  { code: "ik", name: "Inupiaq" },
  { code: "io", name: "Ido" },
  { code: "is", name: "Icelandic" },
  { code: "it", name: "Italian" },
  { code: "iu", name: "Inuktitut" },
  { code: "ja", name: "Japanese" },
  { code: "jv", name: "Javanese" },
  { code: "kl", name: "Kalaallisut, Greenlandic" },
  { code: "kn", name: "Kannada" },
  { code: "kr", name: "Kanuri" },
  { code: "ks", name: "Kashmiri" },
  { code: "kk", name: "Kazakh" },
  { code: "km", name: "Khmer" },
  { code: "ki", name: "Kikuyu, Gikuyu" },
  { code: "rw", name: "Kinyarwanda" },
  { code: "ky", name: "Kirghiz, Kyrgyz" },
  { code: "kv", name: "Komi" },
  { code: "kg", name: "Kongo" },
  { code: "ko", name: "Korean" },
  { code: "ku", name: "Kurdish" },
  { code: "kj", name: "Kwanyama, Kuanyama" },
  { code: "la", name: "Latin" },
  { code: "lb", name: "Luxembourgish, Letzeburgesch" },
  { code: "lg", name: "Luganda" },
  { code: "li", name: "Limburgish, Limburgan, Limburger" },
  { code: "ln", name: "Lingala" },
  { code: "lo", name: "Lao" },
  { code: "lt", name: "Lithuanian" },
  { code: "lu", name: "Luba-Katanga" },
  { code: "lv", name: "Latvian" },
  { code: "gv", name: "Manx" },
  { code: "mk", name: "Macedonian" },
  { code: "mg", name: "Malagasy" },
  { code: "ms", name: "Malay" },
  { code: "ml", name: "Malayalam" },
  { code: "mt", name: "Maltese" },
  { code: "mi", name: "Māori" },
  { code: "mr", name: "Marathi (Marāṭhī)" },
  { code: "mh", name: "Marshallese" },
  { code: "mn", name: "Mongolian" },
  { code: "na", name: "Nauru" },
  { code: "nv", name: "Navajo, Navaho" },
  { code: "nb", name: "Norwegian Bokmål" },
  { code: "nd", name: "North Ndebele" },
  { code: "ne", name: "Nepali" },
  { code: "ng", name: "Ndonga" },
  { code: "nn", name: "Norwegian Nynorsk" },
  { code: "no", name: "Norwegian" },
  { code: "ii", name: "Nuosu" },
  { code: "nr", name: "South Ndebele" },
  { code: "oc", name: "Occitan" },
  { code: "oj", name: "Ojibwe, Ojibwa" },
  {
    code: "cu",
    name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
  },
  { code: "om", name: "Oromo" },
  { code: "or", name: "Oriya" },
  { code: "os", name: "Ossetian, Ossetic" },
  { code: "pa", name: "Panjabi, Punjabi" },
  { code: "pi", name: "Pāli" },
  { code: "fa", name: "Persian" },
  { code: "pl", name: "Polish" },
  { code: "ps", name: "Pashto, Pushto" },
  { code: "pt", name: "Portuguese" },
  { code: "qu", name: "Quechua" },
  { code: "rm", name: "Romansh" },
  { code: "rn", name: "Kirundi" },
  { code: "ro", name: "Romanian, Moldavian, Moldovan" },
  { code: "ru", name: "Russian" },
  { code: "sa", name: "Sanskrit (Saṁskṛta)" },
  { code: "sc", name: "Sardinian" },
  { code: "sd", name: "Sindhi" },
  { code: "se", name: "Northern Sami" },
  { code: "sm", name: "Samoan" },
  { code: "sg", name: "Sango" },
  { code: "sr", name: "Serbian" },
  { code: "gd", name: "Scottish Gaelic; Gaelic" },
  { code: "sn", name: "Shona" },
  { code: "si", name: "Sinhala, Sinhalese" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovene" },
  { code: "so", name: "Somali" },
  { code: "st", name: "Southern Sotho" },
  { code: "es", name: "Spanish; Castilian" },
  { code: "su", name: "Sundanese" },
  { code: "sw", name: "Swahili" },
  { code: "ss", name: "Swati" },
  { code: "sv", name: "Swedish" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "tg", name: "Tajik" },
  { code: "th", name: "Thai" },
  { code: "ti", name: "Tigrinya" },
  { code: "bo", name: "Tibetan Standard, Tibetan, Central" },
  { code: "tk", name: "Turkmen" },
  { code: "tl", name: "Tagalog" },
  { code: "tn", name: "Tswana" },
  { code: "to", name: "Tonga (Tonga Islands)" },
  { code: "tr", name: "Turkish" },
  { code: "ts", name: "Tsonga" },
  { code: "tt", name: "Tatar" },
  { code: "tw", name: "Twi" },
  { code: "ty", name: "Tahitian" },
  { code: "ug", name: "Uighur, Uyghur" },
  { code: "uk", name: "Ukrainian" },
  { code: "ur", name: "Urdu" },
  { code: "uz", name: "Uzbek" },
  { code: "ve", name: "Venda" },
  { code: "vi", name: "Vietnamese" },
  { code: "vo", name: "Volapük" },
  { code: "wa", name: "Walloon" },
  { code: "cy", name: "Welsh" },
  { code: "wo", name: "Wolof" },
  { code: "fy", name: "Western Frisian" },
  { code: "xh", name: "Xhosa" },
  { code: "yi", name: "Yiddish" },
  { code: "yo", name: "Yoruba" },
  { code: "za", name: "Zhuang, Chuang" },
];

export const getTooltipTitle = (media: any) => {
  if (media === "document") {
    return "The supported formats are - pdf, xlsx, and document. The document size must be smaller than 100MB.";
  } else if (media === "video") {
    return "The supported formats is mp4. The video size must be smaller than 15MB.";
  } else if (media === "image") {
    return "The supported formats are - jpg, jpeg, and png. The image size must be smaller than 5MB.";
  }
};

// Whatsapp helper methods

export const generateResolveVariables = (text: string, variables: any[]) => {
  if (!variables?.length) {
    return [];
  }

  let array: any[] = [];
  let regexArray: any = getVariablesArray(text);
  variables?.length > 0 &&
    variables?.forEach((curItem: any, index: number) => {
      let temp = {
        key: regexArray[index],
        value: "",
        index: 0,
      };
      array.push(temp);
    });
  return array;
};

export const generateButtonsResolveVariables = (variables: any[]) => {
  if (!variables?.length) {
    return [];
  }
  return variables.flatMap((item, index) => {
    const baseObject: any = {
      key: `{{${index + 1}}}`,
      value: item?.text || "",
      index: ["COPY_CODE", "QUICK_REPLY", "PHONE_NUMBER"].includes(
        item?.sub_type
      )
        ? item?.sub_type
        : 0,
      sub_type: item?.sub_type,
      text: item?.text,
      disable: ["COPY_CODE", "QUICK_REPLY", "PHONE_NUMBER"].includes(
        item?.sub_type
      )
        ? true
        : false,
    };
    // Conditionally add the `type` key
    if (item?.sub_type === "COPY_CODE") {
      baseObject.type = "coupon_code";
    }
    // Delete the `key` property if `sub_type` is one of the specified values
    if (
      ["COPY_CODE", "QUICK_REPLY", "PHONE_NUMBER"].includes(item?.sub_type) ||
      !item?.hasOwnProperty("example")
    ) {
      delete baseObject.key;
    }

    return baseObject;
  });
};

//generate carousel dynamic buttons example
export const generateCarouselResolveVariables = (carouselData: any) => {
  if (!carouselData) return;
  const carousel = carouselData?.find((item: any) => item?.type === "CAROUSEL");
  const extractedData: { type: string; value: string[] }[] = [];

  carousel.cards.forEach((card: any) => {
    const componentObj: any = { type: "", value: [] };

    card?.components?.forEach((component: any) => {
      if (component.type === "HEADER" && component.example?.header_handle) {
        componentObj.header = {
          type: component?.format,
          key: "{{1}}",
          value: component?.example?.header_handle,
        };
      } else if (component.type === "BODY" && component.example) {
        if (!componentObj.body) {
          componentObj.body = { type: component.type, value: [] };
        }
        componentObj.body.value.push(component.text);
      } else if (component.type === "BUTTONS" && component.buttons) {
        component.buttons.forEach((button: any, index: any) => {
          if (button.example) {
            if (!componentObj.buttons) {
              componentObj.buttons = [];
              componentObj.buttons.type = component.type; // Set the type outside the buttons array
            }
            componentObj.buttons.push({
              key: `{{${index + 1}}}`,
              text: button.text,
              url: button.url,
              type: button.type,
              value: button.example?.[0],
            });
          }
        });
      }
    });
    extractedData.push(componentObj);
  });

  return extractedData;
};

const getVariablesArray = (text: string): string[] => {
  // Helper function to get unique matches and sort them numerically
  const getUniqueMatches = (matches: string[]): string[] => {
    const sortedMatches = matches.sort((a, b) => {
      return parseInt(a.match(/\d+/)![0]) - parseInt(b.match(/\d+/)![0]);
    });

    const uniqueMatches: string[] = [];
    for (const match of sortedMatches) {
      if (!uniqueMatches.includes(match)) {
        uniqueMatches.push(match);
      }
    }
    return uniqueMatches;
  };

  // Updated pattern to match placeholders like {{1}}, {{2}}, {{n}}
  const pattern = /\{\{(\d+)\}\}/g;
  const matches = text.match(pattern) || [];
  const uniqueMatches = getUniqueMatches(matches);
  return uniqueMatches;
};

/* eslint-disable react-hooks/exhaustive-deps */
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography } from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import AppRangeFutureDatePicker from "../../../../AppComponents/AppRangeFutureDatePicker";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import { AppConfig } from "../../../../Config/app-config";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { CoreService } from "../../../../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import {
  resetEmailData,
  resetError,
} from "../../../../Store/Slices/email/email.slice";

import { TableFilter, TableWrapper } from "../../../../TableStyle";
import { BroadCastTitleBar } from "../../WhatsApp/BroadcastHistory/HistoryStyles";
import { BROADCAST_EMAIL_FORM_DATA } from "../Utils/Constants";
import { SchenduleBoradcastWrapper } from "./Styles";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import AppModel from "../../../../Hooks/useModel/AppModel";
import {
  DeleteError,
  DeleteSuccess,
} from "../../../../Store/Slices/email/email.selectors";
import Danger from "../../../../assets/images/danger-live.svg";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { DeleteCannedResponse } from "../../WhatsApp/Templates/TemplatesListing";
import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
// interface Props extends LoaderComponentProps { }
interface Props { }

const EmailScheduledBroadcast: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorApi, setErrorApi] = useState(false);
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage] = useState(0);
  const [currentPage] = useState(0);
  const [status] = useState<string>("scheduled");
  const has_low_balance = localStorage.getItem("has_low_balance")
  const [credits, setCredits] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [isShowingModal, toggleModal] = useState<boolean>(false);

  const deleteErrorMessage = useAppSelector(DeleteError);
  const deleteSuccessMessage = useAppSelector(DeleteSuccess);
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    status: "",
    category: "",
    startDate: null,
    endDate: null,
  });

  // Headers For Table
  const header = ["Name", "Recipients", "Scheduled"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Name: "broadcast_name",
    Recipients: "recipients",
    Scheduled: "sent_on",
  };
  // Width For Table Column
  const colWidth: any = {
    Name: "420",
    Recipients: "300",
    Scheduled: "500",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    // const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  let { showAlert } = useNotification();

  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, null);
    dispatch(resetEmailData({}));
  }, []);

  const getBroadCastData = () => {
    let startDate = null;
    let endDate = null;
    if (localValues?.startDate && localValues?.endDate) {
      startDate = format(localValues?.startDate, "yyyy-MM-dd");
      endDate = format(localValues?.endDate, "yyyy-MM-dd");
    }

    const payload: any = {
      scheduled_start: startDate,
      scheduled_end: endDate,
      limit: 20,
      offset: 0,
      status: status,
      search: localValues?.search,
      broadcast_type: "email",
    };
    setLoading(true)
    dispatch(EmailActions.getEmailAnalyticsListData(payload))
      .then((res: any) => {
        setList(res?.payload?.data.results);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBroadCastData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValues, currentPage, itemsPerPage]);

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  };

  useEffect(() => {
    if (deleteErrorMessage && deleteErrorMessage?.length > 0) {
      showAlert(deleteErrorMessage, "error");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
    if (deleteSuccessMessage && deleteSuccessMessage?.length > 0) {
      showAlert(deleteSuccessMessage, "success");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
  }, [deleteSuccessMessage, deleteErrorMessage]);

  const redirectEmailBroadcast = (id: any, view?: boolean) => {
    if (view) {
      return navigate(`/broadcast/email/edit-broadcast/1/${id}?view=true`);
    }
    navigate(`/broadcast/email/edit-broadcast/5/${id}`);
  };
  useEffect(() => {
    CoreService.getCredits().then((response) => {
      const reversedItems = response?.data;
      setCredits(reversedItems);
    });
  }, [localValues]);
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };
  const handleClickDelete = (id: any) => {
    const payload: any = {
      id: id,
      type: "email",
    };
    dispatch(SMSActions.deleteSmsBroadcastById(payload)).then((res: any) => {
      getBroadCastData();
      toggleModal(false);
    });
  };
  return (
    <>
      <SchenduleBoradcastWrapper>
        {/* ==== Header ==== */}
        <BroadCastTitleBar style={{ paddingBottom: "1rem" }}>
          <div className="left">
            <p style={{ fontWeight: 500 }}>Scheduled Broadcast</p>
          </div>

          <div className="right">
            <ProtectedAppButton
              moduleId="email_broadcast_crud"
              specificPermissionKey="create"
              onClick={() => navigate("/broadcast/email/create-broadcast/1")}
              isDisabled={has_low_balance === "true"}
              title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
            >
              Create Broadcast
            </ProtectedAppButton>
            <ProtectedAppButton
              moduleId="email_template"
              specificPermissionKey="create"
              variant="outline"
              onClick={() => navigate("/broadcast/email/create-template")}
            >
              Create Template
            </ProtectedAppButton>
          </div>
        </BroadCastTitleBar>

        <AppForm // requestSearch(values, submitProps);
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => { }}
          // validationSchema={BotValidationSchema}
          divStyle={{ width: "100%" }}
        >
          <TableFilter style={{ paddingLeft: "20px", paddingRight: "12px" }}>
            <div className="filter-left">
              <Typography fontSize="13px" component="h6">
                Sort By
              </Typography>
              <Box>
                <AppRangeFutureDatePicker
                  dateRange={{
                    startDate: localValues?.startDate,
                    endDate: localValues?.endDate,
                  }}
                  reset={true}
                  setDateRange={handleChangeDate}
                />
              </Box>
              <Box>
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  defaultBorder={true}
                  divStyle={{
                    marginBottom: "0",
                    width: "220px",
                  }}
                  onChange={(e: any) => handleSelectChange(e, "search")}
                />
              </Box>
            </div>
            <div className="filter-right"></div>
          </TableFilter>
        </AppForm>

        {/* ==== Filter ==== */}

        {/* ==== Table ==== */}

        {/* <Paper elevation={3} sx={{ height: "calc(100%)" }}> */}

        {
          loading ? <AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - 287px)`}
          /> : (
            <TableWrapper height="calc(100vh - 214px)">
              <div>
                <Box className="table-header">
                  <Box className="table-row">
                    {header?.map((header: string, index: number) => {
                      return (
                        <Box
                          className={`col-head ${header
                            .toLowerCase()
                            .replaceAll(" ", "_")}`}
                          style={{
                            // maxWidth: colWidth[header] + "px",
                            minWidth: colWidth[header] + "px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            {header}
                          </Typography>
                        </Box>
                      );
                    })}
                    <Box
                      className="col-head"
                      sx={{
                        maxWidth: "100px",
                        minWidth: "100px",
                        height: "2.969rem",
                      }}
                    >
                      <Typography></Typography>
                    </Box>
                  </Box>
                </Box>

                {
                  errorApi || list?.length === 0 ? (<NoRecordsFound height="calc(100vh - 212px)" subText="Create New Broadcast">
                    <ProtectedAppButton
                      moduleId="email_broadcast_crud"
                      specificPermissionKey="create"
                      variant="primarydark"
                      onClick={() =>
                        navigate("/broadcast/email/create-broadcast/1")
                      }
                      isDisabled={has_low_balance === "true"}
                      title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
                    >
                      Create Broadcast
                      <AppMaterialIcons iconName="Add" />
                    </ProtectedAppButton>
                  </NoRecordsFound>) : (
                    <Box className="table-body">
                      {list?.map((row: any, rowIndex: any) => {
                        return (
                          <Box key={rowIndex} className="table-row">
                            {header?.map((header: any, ColumnIndex: any) => {
                              const isActionColumn = header === "Action";
                              return (
                                <Box
                                  className={`col-body ${header
                                    .toLowerCase()
                                    .replaceAll(" ", "_")}`}
                                  title={
                                    header === "Scheduled"
                                      ? formatDateField(row[keyToHeaderMap[header]])
                                      : row[keyToHeaderMap[header]]
                                        ? row[keyToHeaderMap[header]]
                                        : "-"
                                  }
                                  style={{
                                    // maxWidth: isActionColumn
                                    //   ? "400px"
                                    //   : colWidth[header] + "px",
                                    minWidth: isActionColumn
                                      ? "400px"
                                      : colWidth[header] + "px",
                                  }}
                                >
                                  <Typography className="table-text">
                                    {header === "Scheduled"
                                      ? formatDateField(row[keyToHeaderMap[header]])
                                      : row[keyToHeaderMap[header]]
                                        ? row[keyToHeaderMap[header]]
                                        : "-"}
                                  </Typography>
                                </Box>
                              );
                            })}
                            <Box
                              display="flex"
                              sx={{
                                maxWidth: "100px",
                                minWidth: "100px",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                              className="actionColumn"
                            >
                              {row?.can_update &&
                                <IconButton>
                                  <EditIcon
                                    onClick={() => redirectEmailBroadcast(row?.broadcast_id, false)}
                                    fontSize="small"
                                  />
                                </IconButton>
                              }
                              {row?.can_delete &&
                                <IconButton>
                                  <DeleteOutlineOutlinedIcon
                                    onClick={() => {
                                      setSelectedId(row?.id);
                                      toggleModal(true);
                                    }}
                                    fontSize="small"
                                  />
                                </IconButton>
                              }

                              <IconButton>
                                <RemoveRedEyeOutlinedIcon
                                  onClick={() =>
                                    redirectEmailBroadcast(row?.broadcast_id, true)
                                  }
                                  fontSize="small"
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )
                }


              </div>
            </TableWrapper>
          )
        }



        <AppModel
          isShowing={isShowingModal}
          onClose={toggleModal}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "500px",
            },
          }}
        >
          <DeleteCannedResponse>
            <div className="header">
              <div className="header-left">
                <div className="danger">
                  <img src={Danger} alt="" />
                </div>
                <div className="text">
                  <h4>Delete The Email Broadcast</h4>
                  <p>Are you sure, you want to delete?</p>
                </div>
              </div>
              <div className="header-right">
                <span onClick={() => toggleModal(false)}>
                  <AppMaterialIcons iconName="close" />
                </span>
              </div>
            </div>
            <div className="footer">
              <AppButton variant="grey" onClick={() => toggleModal(false)}>
                Cancel
              </AppButton>
              <ProtectedAppButton
                moduleId="email_broadcast_crud"
                specificPermissionKey="delete"
                variant="danger-filled"
                onClick={() => handleClickDelete(selectedId)}
              >
                <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                Delete
              </ProtectedAppButton>
            </div>
          </DeleteCannedResponse>
        </AppModel>
      </SchenduleBoradcastWrapper>
    </>
  );
};

// export default withLoader(EmailScheduledBroadcast);
export default EmailScheduledBroadcast;

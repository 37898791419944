import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip
} from "@mui/material";
import { FastField, getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";

import { DecsText, Label } from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import { AppMaterialIcons } from "../../AppMaterialIcons";
import AppErrorMessage from "../AppErrorMessage";

interface Props {
  Options: any;
  label?: string;
  name: string;
  divStyle?: any;
  tooltip?: any;
  labelStyle?: any;
  description?: string;
  optionalText?: string;
  onChangeCallbackFn?: (value: any) => void;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppFormToggleButtonFastField: React.FC<Props> = ({
  Options,
  label,
  name,
  divStyle,
  tooltip,
  labelStyle,
  description,
  optionalText,
  onChangeCallbackFn,
  ...otherProps
}) => {
  const { errors, touched, setFieldValue, values, setFieldTouched, setFieldError } =
    useFormikContext<any>();
  useEffect(() => {
    if (getIn(values, name) === 0) {
      setFieldValue(name, Options[0]?.value);
    }
  }, []); // eslint-disable-line

  useEffect(() => { }, [values])
  const handleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: any
  ) => {
    setFieldValue("header_value", null);
    if (!newValue) {
      return null;
    }
    setFieldError("header_value", "")
    setFieldValue(name, newValue);

    if (onChangeCallbackFn) {
      onChangeCallbackFn(newValue);
    }
  };
  return (
    <Div style={divStyle}>
      <div className="textLabelOptional">
        <Label style={labelStyle}>
          {label}{" "}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        {optionalText && (
          <span style={{ color: "rgba(126, 131, 146, 1)" }}>
            {optionalText}
          </span>
        )}
      </div>

      {description && <DecsText>{description}</DecsText>}
      <FastField name={name}>
        {({ field, form, meta }: any) => {
          return (
            <ToggleButtonGroup
              {...otherProps}
              {...field}
              color="primary"
              value={getIn(values, name) || 0}
              exclusive
              onChange={(event, newValue) => {
                handleButtonChange(event, newValue);
              }}
              onBlur={() => {
                setFieldTouched(name, true);
              }}
              style={{
                margin: "1rem 0px 0.5rem 0px",
                width: "100%",
                flexWrap: "wrap",
                gap: "1.5rem",
              }}
            >
              {Options?.map((curItem: any, index: number) => {
                return (
                  <ToggleButton
                    value={curItem?.value}
                    key={index.toString()}
                    style={{
                      display: "flex",
                      gap: "0",
                      borderRadius: "3.125rem",
                      padding: "0.45rem 0.95rem",
                      border:
                        getIn(errors, name) && getIn(touched, name)
                          ? `1px solid ${theme.palette.default.error}`
                          : `1px solid ${theme.palette.default.border}`,
                      textTransform: "capitalize",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      lineHeight: "1",
                      textAlign: "left",
                    }}
                    disabled={curItem?.disabled}
                  >
                    <AppMaterialIcons
                      iconName={curItem?.icon}
                      style={{ fontSize: "1.5rem", display: "block", marginRight: "0.6rem" }}
                    />
                    {curItem?.name}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          )
        }}
      </FastField>

      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default AppFormToggleButtonFastField;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
    .MuiFormControlLabel-label {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.darkGrey};
    }
    .MuiRadio-root {
      margin: 0 0.5rem 0 0;
    }
    .MuiFormControlLabel-root {
      margin: 0 !important;
      margin-right: 1rem !important ;
    }
    .Mui-disabled {
      color: ${theme.palette.default.darkGrey};
      background-color: ${theme.palette.default.lightGrey};
    } 
    .textLabelOptional {
      display: flex;
      gap: 0.5rem;
    }
  }
`;
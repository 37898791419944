import { getIn, useFormikContext } from "formik";
import AppFormRadioFastField from "./FastField/AppFormRadioFastField";
import { broadcastSendOptions } from "./Utils/Constants";
import AppFormDatePicker from "./AppFormDatePicker";
import AppFormTimePickerMui from "./AppFormTimePickerMui";
import { useEffect, useState } from "react";
import styled from "styled-components";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSelector } from "react-redux";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../Store";
import { testWhatsappModel } from "../../Store/Slices/Whatsapp/whatsapp.selectors";
import { LocalStorage } from "../../Utils";
import { testWhatsappData } from "../../Store/Slices/Whatsapp/whatsapp.slice";
import { WhatsappActions } from "../../Store/Slices/Whatsapp/whatsapp.actions";
import { Box, Modal, Typography } from "@mui/material";
import AppButton from "../../AppComponents/AppButton";
import { convertFormToPayloadSingleWhatsappBroadcast } from "../../Components/Leads/contact-action/utils/constants";
import AppFormTextArea from "./AppFormTextArea";
import { testSMSData } from "../../Store/Slices/SMS/sms.slice";
import { SMSActions } from "../../Store/Slices/SMS/sms.actions";
import AppFormFastField from "./FastField/AppFormFastField";
import { testEmailTemplateData } from "../../Store/Slices/email/email.slice";
import { EmailActions } from "../../Store/Slices/email/email.actions";
import { theme } from "../../Customization/Theme";
import { StyledProps } from "@material-ui/core";
interface Props {
  broadcastByIdData: any
  type?: any
}
interface Props {
  openModel?: boolean
}
const ScheduleBroadcast: React.FC<Props> = ({ broadcastByIdData, openModel = false, type }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const formkey1 = "when_to_send";
  const formkey2 = "date";
  const formkey3 = "time";
  const dispatch = useAppDispatch()
  let { showAlert } = useNotification();
  const editedPhone = useSelector(testWhatsappModel);
  const [recipient, setRecipient] = useState<any>([]);
  const style = {
    position: 'fixed' as 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "662px",
    height: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: "15px",
  };
  useEffect(() => {
    setFieldValue(formkey3, new Date())
  }, [])
  const notesList = [
    "If multiple recipients, separate the phone no. by a comma.",
    "The tracking will not be enabled.",
    "Credits will be deducted according to the number of whatsapp message sent for this test",
    "Phone no. should be in these format i.e +91xxxxxxxx,+91xxxxxxxx"
  ]
  const notesListemail = [
    "if multiple recipients, separate the email addresses by a comma.",
    "make sure you save the template changes before you send the test.",
    "credits will be deducted according to the number of emails sent for this test"
]
  const validatePhone = (phone: any) => {
    let status = false;
    phone?.map((res: any) => {
      if (String(res)
        .toLowerCase()
        .match(
          /^\+\+?(\d{1,4})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}$/
        )) {
        status = true
      } else {
        status = false
      }
    })
    return status
  };
  const addPhone = () => {
    if (values?.recipient?.length > 0 && validatePhone(values?.recipient?.split(','))) {
      if (type === "Whatapp") {
        dispatch(testWhatsappData(false))
        let payload: any = {};
        payload = convertFormToPayloadSingleWhatsappBroadcast(values);
        payload = {
          ...payload,
          phone_no: values.recipient?.split(','),
          broadcast_id: values?.broadcast_template_id,
          template_id: values?.template?.template_id,
        }
        dispatch(WhatsappActions.postTestWhatsappMessage(payload))
          .then((res: any) => {
            if (res?.payload?.data?.status) {
              showAlert("Successfullly Sent!", "success");
              values.recipient = ""
            } else {
              showAlert(
                res?.payload?.data?.error || "unable to send test message please try again later!",
                "error"
              );
            }
          })
          .catch((error: any) => {
            showAlert(
              "Template Name Already Exist",
              "error"
            );
          })
          .finally(() => {
          });
      } else {
        dispatch(testSMSData(false))
        let payload = {
          broadcast_template_id: values?.broadcast_template_id,
          sender_field_value: values?.sender_id,
          components: { "body": values?.resolved_body_variables },
          phone_no: values.recipient?.split(','),
        };

        dispatch(SMSActions.postTestSMS(payload))
          .then((res: any) => {
            if (res?.payload?.data?.status) {
              showAlert(
                res?.payload?.data?.message,
                "success"
              );
              values.recipient = ""
            } else {
              showAlert(
                res?.payload?.data?.error || "Unable to send SMS at the moment please try again later",
                "error"
              );
            }

          })
          .catch((error: any) => {
            showAlert(
              "Template Name Already Exist",
              "error"
            );
          })
          .finally(() => {
          });
          
      }

    } else {
      if (type === "Whatapp") {
        showAlert("Please Enter a Valid WhatsApp Number", "error");
      } else if (type === "Sms") {
        showAlert("Please Enter a Valid SMS Number", "error");
      } else {
        showAlert("Invalid Input. Please provide valid recipient details.", "error");
      }
    }
  }
  const validateEmail = (email: any) => {
    let status = false;
    email?.map((res: any) => {
      if (String(res)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
        status = true
      } else {
        status = false
      }
    })
    return status
  };
  const addEmail = () => {
    if (recipient?.length > 0 && validateEmail(recipient?.split(','))) {
      dispatch(testEmailTemplateData(false))
      let payload = {
        template_body: JSON.stringify(localStorage.getItem("HTMLTemplate")),
        from_email: values?.from_email,
        email: recipient?.split(','),
        subject: values?.subject,
        email_attachment: values?.email_attachment?.FileName
      };
      dispatch(EmailActions.postTestEmailTemplate(payload))
        .then((res: any) => {
          if (res?.payload?.data?.status) {
            showAlert(
              res?.payload?.data?.msg,
              "success"
            );
          } else {
            showAlert(
              res?.payload?.data?.error || "Unable to send test email please try again!",
              "error"
            );
          }

        }).catch((error: any) => {
          showAlert(
            "Template Name Already Exist",
            "error"
          );
        }).finally(() => {
          setRecipient([])
        });
    } else {
      showAlert(
        "Please Enter Valid Email",
        "error"
      );
    }
  }
  useEffect(() => {
    setFieldValue("recipient", [])
  }, [])
  return (
    <ContainerWrapper>
      {type === "email" ?

        <>
          <Typography variant="body1" gutterBottom >Send a test email</Typography>
          <TestEmailNots>
            Notes:
            <TestEmailNoteList>
              <ul className="list">
                {notesListemail.map((list: any, index: number) => {
                  return <li key={"list_" + index.toString()}>{list}</li>
                })}
              </ul>
            </TestEmailNoteList>
          </TestEmailNots>
          <AppFormFastField
            name="from_email"
            label="From email (optional)"
            disabled={true}
          />
          <AppFormFastField
            name="subject"
            label="Subject"
            disabled={true}
          />
          <Label>Recipient (s)</Label>
          <Input
            rows={5}
            placeholder={"i.e: a@domain.com, b@domain.com, c@domain.com"}
            onChange={(e: any) => setRecipient(e?.target?.value)}
          />

          <TestEmailControlOpration>
            <AppButton
              variant="grey"
              onClick={() => dispatch(testEmailTemplateData(false))}
            >
              Cancel
            </AppButton>

            <Box display="flex">
              <AppButton
                variant="cta"
                endIcon="Send"
                onClick={() => addEmail()}
              >Send</AppButton>
            </Box>
          </TestEmailControlOpration>
        </> :
        <Container>
          <h1>{editedPhone}</h1>

          <TestSection>
            <Typography variant="h6" gutterBottom>
              Send a test {type} message
            </Typography>
            <Notes>

              <TestWhatsappNoteList>
                Notes:
                <ul className="list">
                  {notesList.map((list: any, index: number) => {
                    return <li key={"list_" + index.toString()}>{list}</li>
                  })}
                </ul>
              </TestWhatsappNoteList>
            </Notes>
            <label htmlFor="">Recipient (s)</label>
            <TextWrapper>
              <AppFormTextArea

                name={"recipient"}
                placeholder={"i.e: +91xxxxxxxx,+91xxxxxxxx,+91xxxxxxxx"}
                rows={4}
              />
              <ButtonContainer  >
                <Box >
                  <AppButton
                    variant="cta"
                    onClick={addPhone}
                    endIcon="Send"
                  >
                    Send Test
                  </AppButton>
                </Box>
              </ButtonContainer>
            </TextWrapper>
          </TestSection>
        </Container>}
      <Container>
        <AppFormRadioFastField
          label={"When to Send"}
          name={formkey1}
          Options={broadcastSendOptions}
          row={false}
        />
        {getIn(values, formkey1) === "schedule_broadcast" && (
          <div style={{ display: "flex", alignItems: "flex-start", gap: "2rem" }}>
            <AppFormDatePicker name={formkey2} label="Select date " minDate={new Date()} broadcastData={broadcastByIdData} />
            <AppFormTimePickerMui
              name={formkey3}
              label="Select time "
              utcTime={true}
              disablePast={true}
              broadcastByIdData={broadcastByIdData}
            />
          </div>
        )}
        <BroadcastText>
          What is the difference between now and schedule broadcast?
        </BroadcastText>
        <BroadcastInfo>
          <FiberManualRecordIcon style={actionIson} /> <p style={action}>Broadcast Now</p>is an instant delivery of a message or mail to your selected audience.
        </BroadcastInfo>
        <BroadcastInfo style={{ marginBottom: "24px" }}>
          <FiberManualRecordIcon style={actionIson} /> <p style={action}>Schedule a Broadcast</p>is planning content delivery for a specific date and time to reach a targeted audience effectively.
        </BroadcastInfo>
        {/* <TestSMSTemplate /> */}
        {/* <TestWhatsappTemplate /> */}
      </Container>
    </ContainerWrapper>
  )
}


const TestEmailNots = styled.div`
    height: auto;
    padding: 13px 18px 23px 19px;
    border-radius: 4px;
    background: rgba(91, 115, 232, 0.15);
`
const TestEmailNoteList = styled.div`
    padding-left: 2rem;
`
const TestEmailControlOpration = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;
`
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;

const Input = styled.textarea<StyledProps>`
  && {
    width: 100%;
    resize: none;
    background-color: #f5f6f8;
    max-height: 200px;
    border-radius: 4px;
    outline: none !important;
    padding: 0.5rem 2rem 0.5rem 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    border: 2px solid transparent;

    &::placeholder {
      color: ${theme.palette.default.darkGrey};
    }

    &:active {
      border: 2px solid ${theme.palette.default.border};
      color: ${theme.palette.default.text};
    }
    &:focus {
      border: 2px solid ${theme.palette.default.border} !important;
      color: ${theme.palette.default.text};
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`;
const TextWrapper = styled.div`
width:70%;
    display:flex;
`;
const TestSection = styled.div`
  background-color: "red"; 
  margin-top: 30px;
`;

const Notes = styled.div`
   width: 60%;
  background: #f1f5ff;
  // border: 1px solid #cbd6ff;
  // padding: 10px;
  border-radius: 5px;
  font-size: 17px;
  color: #7e8392;
  margin-bottom: 10px;
  

  ul {
    list-style: disc;
    margin: 0;
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
      margin: 105px 10px 0px;
 
`;
const BroadcastText = styled.p`
  color: #101010;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin : 40px 2px 3px 3px;
`
const BroadcastInfo = styled.div`
  color: #7E8392;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display:flex
`
const action = {
  color: "#101010",
  fontWeight: 500,
  margin: "0px 5px 0px 4px"
}
const actionIson = {
  fontSize: "16px",
  marginTop: "3px"
}
const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
const Container = styled.div`
  padding: 20px;
  font-family: "Roboto", sans-serif;
  background-color: #fdfdfd;
`;
const TestWhatsappNoteList = styled.div`
   width: 100%;
  background: #f1f5ff;
  border: 1px solid #cbd6ff;
  padding: 10px;
  border-radius: 5px;
  font-size: 17px;
  color: #7e8392;
  margin-bottom: 10px;
  ul {
    list-style: disc;
    margin: 0;
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`;

export default ScheduleBroadcast;
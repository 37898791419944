import { Box, Breadcrumbs, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormFastField,
  AppFormObserver,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import { LoaderComponentProps } from "../../../../AppComponents/Loader";
import { AppConfig } from "../../../../Config/app-config";
import { HocBackdropLoader } from "../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import BroadcastPreview from "../../BroadcastPreview";
import { SMS_PRIMARY_COLOR } from "../../BroadcastPreview/Constants";
import { convertDateTimeToUTC } from "../../WhatsApp/Utils/utils";
import { StyledContentBox } from "../BroadCastTemplate/Styles";
import { BreadcrumbsItem, SMSBoxStepperbar, SMSBoxTopbar, StyledCreateBroadcastWrap } from "./Styles";

import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
import { SMSDataStore, getSmsBroadcastData } from "../../../../Store/Slices/SMS/sms.selectors";
import { resetTemplateData, setSmsBroadcastData, testSMSData } from "../../../../Store/Slices/SMS/sms.slice";
import { SMSNetworkService } from "../../../../Store/Slices/SMS/smsNetworkService";
import {
  BROADCAST_SMS_FORM_DATA,
  SMSBroadcastScheduleStep1Schema,
  SMSBroadcastScheduleStep2Schema,
  SMSBroadcastScheduleStep3Schema,
  SMSBroadcastScheduleStep4Schema,
} from "./Utils/Constants";
import { convertDataToSMSBroadcastForm } from "./Utils/utils";
import RenderFormStep1 from "./component/RenderFormStep1";
import RenderFormStep2 from "./component/RenderFormStep2";
import RenderFormStep3 from "./component/RenderFormStep3";
import RenderFormStep4 from "./component/RenderFormStep4";

import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../../../Store/Slices/MarketingList/marketingList.selectors";
import { removeMarketingListData } from "../../../../Store/Slices/MarketingList/marketingList.slice";
import BroadcastNoData from "../../Utils/BroadcastNoData";

import { ReactComponent as Confirm } from "../../../../assets/images/confirm.svg";
import { ReactComponent as Recipients } from "../../../../assets/images/recipients.svg";
import { ReactComponent as Schedule } from "../../../../assets/images/schedule.svg";
import { ReactComponent as Setup } from "../../../../assets/images/setup.svg";
import { ReactComponent as Template } from "../../../../assets/images/template.svg";
import { ReactComponent as ResolveVariable } from '../../../../assets/images/ResolveVariable.svg';
import { creditDetailsStore } from "../../../../Store/Slices/CreditDetails/CreditDetails.selectors";
import BroadcastCreditDetails from "../../Utils/BroadcastCreditDetails";
import { StyledCreditBox } from "../../WhatsApp/Templates/Styles";
import { findFormSpecificPermissionKeyUsingLocation } from "../../../../Utils/rolesUtils";
import { ProtectedAppForm } from "../../../../AppComponents/ProtectedComponents";
import { theme } from "../../../../Customization/Theme";
import styled from "styled-components";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { ContactListingPagination, ContactsListingData, getFilterQuery } from "../../../../Store/Slices/Contacts/contacts.selector";
import { convertQueryToMongo } from "../../../../AppComponents/QueryBuilder/utils/utils";
import { getIn, useFormikContext } from "formik";
import { ContactsActions } from "../../../../Store/Slices/Contacts/contacts.action";
import { resetContactPropertiesListData } from "../../../../Store/Slices/Settings/ContactProperties/contactProperties.slice";
import { setFilterQuery } from "../../../../Store/Slices/Contacts/contacts.slice";
interface Props extends LoaderComponentProps { }
interface BreadCrumbItem {
  id: number;
  name: string;
  icon: JSX.Element;
}
const BroadcastCreate: React.FC<Props> = ({ setLoading }) => {
  const PREVIEW_AVAILABLE = false;
  const localStorageFormData: any = AppConfig.getUserData(
    BROADCAST_SMS_FORM_DATA
  );
  const pagination = useAppSelector(ContactListingPagination);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { templateByIdData, postSMSBroadcastData, smsTemplateListData } =
    useAppSelector(SMSDataStore);
  const { broadcastByIdData }: any = useAppSelector(marketingListStore);
  const { broadcastCreditDetails }: any = useAppSelector(creditDetailsStore);
  const broadcastData = useAppSelector(getSmsBroadcastData)
  const filterQuery: any = useAppSelector(getFilterQuery);
  const [duplicateName, setDuplicateName] = useState("");
  const [pendingPayload, setPendingPayload] = useState<any>(null);
  const parameters = new URLSearchParams(window.location.search);
  const [openNameChangeDialog, setOpenNameChangeDialog] = useState(false);
  const [limitCount, setLimitCount] = useState(0);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [broadcastNowPayload, setBroadcastNowPayload] = useState<any>(null);

  //*WILL BE USED TO SET THE FORM ACCESS TYPE 
  const [formAccessType, setFormAccessType] = useState<"create" | "read" | "update" | "delete" | undefined>();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findFormSpecificPermissionKeyUsingLocation();
    if (result === "update" && parameters?.get("view")) {
      setFormAccessType("read");
    }
    else {
      setFormAccessType(result);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [tempalteData, setTemplateData] = useState(
    templateByIdData?.body_value
  );
  const { showAlert } = useNotification();
  let { step_id, broadcast_id } = useParams();
  const [formStep, setFormStep] = useState<number>(
    step_id && broadcast_id
      ? Number(step_id)
      : step_id && templateByIdData
        ? Number(step_id)
        : 1
  );
  const [formikProps, setFormikProps] = useState<any>(null);
  const [isEditingBroadcast, setIsEditingBroadcast] = useState<boolean>(
    step_id && broadcast_id && step_id === String(4) ? true : false
  );
  const [isView, setIsView] = useState<boolean>(
    step_id && broadcast_id && step_id === String(1) ? true : false
  );
  useEffect(() => {
    setTemplateData(templateByIdData?.body_value);
  }, [templateByIdData?.body_value]);
  const { values, errors }: any = formikProps || {};
  const [isShowing, setShowing] = useState(false);
  const handleCloseModal = () => {
    setIsLimitModalOpen(false);
  };
  const [userInput, setUserInput] = useState<any>(
    localStorageFormData
      ? {
        broadcast_name: localStorageFormData?.broadcast_name,
        broadcast_template_id:
          localStorageFormData?.broadcast_template_id || 0,
        sender_id: localStorageFormData?.sender_id || 0,
        template_type: localStorageFormData?.template_type || 0,
        broadcast_action:
          localStorageFormData?.broadcast_action ||
          "broadcast_to_contacts",
        marketing_list: localStorageFormData?.marketing_list || 0,
        filters: localStorageFormData?.filters || null,
        selected_rows: 0,
        search_term: localStorageFormData?.search_term || "",

        when_to_send: localStorageFormData?.when_to_send || "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),

        resolved_header_variables:
          localStorageFormData?.resolved_header_variables || [],
        resolved_body_variables:
          localStorageFormData?.resolved_body_variables || [],
        resolved_buttons_variables:
          localStorageFormData?.resolved_buttons_variables || [],
        id: localStorageFormData?.id || null,
      }
      : {
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_to_contacts",
        marketing_list: 0,
        filters: null,
        selected_rows: 0,
        search_term: "",

        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),

        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
        id: postSMSBroadcastData?.id,
      }
  );

  useEffect(() => {
    if (localStorageFormData && localStorageFormData?.filters) {
      dispatch(setFilterQuery(localStorageFormData?.filters));
    }
  }, [])

  function replaceKeys(array: any) {
    return array.map((obj: any) => {
      return {
        field_name: obj.index,
        default_value: obj.value,
        variable: obj.key
      };
    });
  }
  const handleCloseNameDialog = () => {
    setOpenNameChangeDialog(false);
  };
useEffect(() => {
  const handleBeforeUnload = () => {
    // Clear localStorage
    AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
    
    // Clear Redux store
    dispatch(resetTemplateData({}));
    dispatch(removeMarketingListData({}));
    dispatch(setSmsBroadcastData({}));
  };
  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);
  //* HANDLE SUBMIT BTN API CALL
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (formStep === 1) {
      let data = {
        ...broadcastData,
        "broadcast_name": values?.broadcast_name,
        "broadcast_template_id": values?.broadcast_template_id,
        "sender_id": values?.sender_id
      }
      dispatch(setSmsBroadcastData(data))
      setFormStep(formStep + 1);
      SubmitProps.setSubmitting(false);
      return;
    }
    if (formStep === 2) {
      dispatch(ContactsActions.getBroadcastLimit("sms"))
        .then((response: any) => {
          let limit = response?.payload?.data?.limit
          if (pagination?.totalRecords <= limit || limit == "TIER_UNLIMITED") {
            SubmitProps.setSubmitting(false);
            AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
            setFormStep(formStep + 1);
            return;
          } else {
            setLimitCount(limit)
            setIsLimitModalOpen(true)
          }
        }).catch((error: any) => {
          console.error(error);
        });
      SubmitProps.setSubmitting(false);
    }
    if (formStep === 3) {
      const payload = payloadObject(values);
      AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
      setFormStep(formStep + 1);
      SubmitProps.setSubmitting(false);
      return;
    }
    if (formStep === 4) {
      if (!isEditingBroadcast) {
        if (values.when_to_send === "broadcast_now" && !openNameChangeDialog) {
          setBroadcastNowPayload(values)
          setShowing(true);
          SubmitProps.setSubmitting(false);
        } else {
          const payloadData = payloadObject(values);
          const payload = {
            ...payloadData,
            broadcast_name: values?.broadcast_name.replace(/\s+/g, " ").trim() || "",
            broadcast_template_id: values?.broadcast_template_id,
            template_title: values?.template?.original_response?.template_title || "",
            marketing_list_id: values?.marketing_list || "",
            marketing_list_name: values?.marketing_list_name || "",
            broadcast_action: values?.broadcast_action || "marketing_list",
            filter_query: filterQuery,
            mongodb_query: convertQueryToMongo(filterQuery),
            scheduled_now: values?.when_to_send === "broadcast_now",
            search: "",
            // scheduled_date: values?.date,
          };
          setDuplicateName(payload.broadcast_name)
          setPendingPayload(payload);
          SMSNetworkService.postSmsBroadcast(payload)
            .then((response: any) => {
              if (response.data && response.data.status) {
                showAlert("Broadcast created successfully", "success");
                navigate("/broadcast/sms/history");
              }
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
            });
        }
        SubmitProps.setSubmitting(false);
      } else {
        let payload: any = {}
        payload["scheduled_date"] = convertDateTimeToUTC(
          values?.date,
          values?.time
        );
        payload["scheduled_now"] = values?.when_to_send === "broadcast_now" ? true : false;
        SMSNetworkService.updateSMSBroadcast(payload, broadcast_id)
          .then((res: any) => {
            if (res?.data?.error) {
              showAlert(res.data.error, "error");
              return;
            }
            AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
            showAlert("Broadcast updated", "success");
            navigate("/broadcast/sms/scheduled");
          })
          .catch((err: any) => {
            showAlert(
              err?.response?.data?.error || "Something went wrong",
              "error"
            );
          })
          .finally(() => {
            SubmitProps.setSubmitting(false);
            setLoading(false);
          });
      }
      return;
    }
  };
  const submitBroadcastWithNewName = () => {
    const payloadData = payloadObject(values);
    const payload = {
      ...payloadData,
      broadcast_name: values?.broadcast_name.replace(/\s+/g, " ").trim() || "",
      broadcast_template_id: values?.broadcast_template_id,
      template_title: values?.template?.original_response?.template_title || "",
      marketing_list_id: values?.marketing_list || "",
      marketing_list_name: values?.marketing_list_name || "",
      broadcast_action: values?.broadcast_action || "broadcast_to_contacts",
      filter_query: filterQuery,
      mongodb_query: convertQueryToMongo(filterQuery),
      scheduled_now: values?.when_to_send === "broadcast_now",
      search: "",
      // scheduled_date: values?.date,
    };
    setDuplicateName(payload.broadcast_name)
    setPendingPayload(payload);
    SMSNetworkService.postSmsBroadcast(payload)
      .then((response: any) => {
        if (response.data && response.data.status) {
          showAlert("Broadcast created successfully", "success");
          navigate("/broadcast/sms/history");
        }
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (error: { response: { data: { error: any; }; }; request: any; message: any; }) => {
    if (error.response && error.response.data.error) {
      if (error.response.data.error === "Broadcast Name Already Exist.") {
        setOpenNameChangeDialog(true);
        showAlert(error.response.data.error || "Broadcast name already exists", "error");
        setShowing(false)
      } else {
        showAlert(error.response.data.error || "Error in broadcast", "error");
        setOpenNameChangeDialog(false);
        setShowing(false)
      }
    } else {
      showAlert("Something went wrong", "error");
    }
  }
  const payloadObject = (values: any) => {
    let payload: any = {
      broadcast_name: values?.broadcast_name?.trim() || "",
      broadcast_template_id: values?.broadcast_template_id,
      sender_id: values?.sender_id,
      template_type: values?.template_type,
      broadcast_action: values?.broadcast_action?.value,
      status: "draft",
      components: {},
    };

    if (values?.resolved_body_variables?.length > 0) {
      payload["components"]["body"] = {
        value: values?.resolved_body_variables,
      };
    }

    payload["broadcast_id"] = values?.id
    payload["scheduled_now"] =
      values?.when_to_send === "broadcast_now" ? true : false;
    payload["scheduled_date"] = null;
    if (values?.when_to_send === "schedule_broadcast") {
      payload["scheduled_date"] = convertDateTimeToUTC(
        values?.date,
        values?.time
      );

    }

    return payload;
  };

  //* only first time render
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcast_id) {
      dispatch(
        marketingListActions.getScheduledBroadcastByID(broadcast_id)
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
    return () => {
      dispatch(resetTemplateData({}));
      dispatch(resetContactPropertiesListData({}))
      AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
      formikProps?.handleReset();
      setUserInput({
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_to_contacts",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcastByIdData) {
      setLoading(true);
      let temp = convertDataToSMSBroadcastForm(broadcastByIdData);
      setUserInput(temp);
      setLoading(true);
      dispatch(SMSActions.getTemplateById(temp?.broadcast_template_id)).finally(
        () => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      );
    }
  }, [broadcastByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleFormNavigation(formStep);
  }, [formStep]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    return () => {
      dispatch(resetContactPropertiesListData({}))
      AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
      formikProps?.handleReset();
      setUserInput({
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_to_contacts",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
      });
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const handleFormNavigation = (formIndex: number) => {
    if (formIndex === 1) {
      setLoading(true);
      dispatch(SMSActions.getSMSTemplateListData({
        limit: 9999,
        offset: 0,
      })).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      });
    }
    if (!templateByIdData && formStep !== 1) {
      return;
    }
    if (!isView && !isEditingBroadcast) {
      let url = `/broadcast/sms/create-broadcast/${formIndex}`;
      navigate(url);
    }
  };
  const handleBackClick = (isReset?: boolean) => {
    if (isEditingBroadcast || isView) {
      let url = `/broadcast/sms/history`;
      return navigate(url);
    }
    if (formStep === 1) {
      AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
      formikProps?.handleReset();
      dispatch(removeMarketingListData({}))
      setUserInput({
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_to_contacts",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",

        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),

        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
      });
      if (!isReset || isEditingBroadcast || isView) {
        let url = `/broadcast/sms/history`;
        navigate(url);
      } else {
        setTemplateData("");
      }

      return;
    }

    if (formStep === 2) {
      setFormStep(formStep - 1);
      return;
    }
    if (formStep === 3) {
      setFormStep(formStep - 1);
      return;
    }
    if (formStep === 4) {
      setFormStep(formStep - 1);
      return;
    }
  };

  const [smsTemplateCount, setSmsTemplateCount] = useState<any>([]);

  useEffect(() => {
    setSmsTemplateCount(
      smsTemplateListData?.filter((ele: any) => ele?.status === "APPROVED")
    );
  }, [smsTemplateListData]);

  const handleButtonClick = () => {
    return navigate("/broadcast/sms/template");
  };

  const BreadCrumbsItems: BreadCrumbItem[] = [
    {
      id: 1,
      name: "Template",
      icon: <Template />,
    },
    {
      id: 2,
      name: "Recipients",
      icon: <Recipients />,
    },
    {
      id: 3,
      name: "Review Cost & Confirm",
      icon: <ResolveVariable />,
    },
    {
      id: 4,
      name: "Schedule",
      icon: <Schedule />,
    },
  ];

  return (
    <StyledCreateBroadcastWrap>
      {smsTemplateCount?.length || isEditingBroadcast || isView ? (
        <ProtectedAppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
          }}
          validationSchema={
            (formStep === 1 && SMSBroadcastScheduleStep1Schema) ||
            (formStep === 2 && SMSBroadcastScheduleStep2Schema) ||
            (formStep === 3 && SMSBroadcastScheduleStep3Schema) ||
            (formStep === 4 && SMSBroadcastScheduleStep4Schema) ||
            null
          }
          initialTouched={userInput}
          validateOnChange={true}
          moduleId="sms_broadcast_crud"
          specificPermissionKey={formAccessType}
          divStyle={{
            gap: 0
          }}
        >

          <SMSBoxTopbar>
            <div className="left">
              <p className="heading">
                {" "}
                {!isEditingBroadcast && (
                  <IconButton onClick={() => handleBackClick()}>
                    <AppMaterialIcons
                      iconName={"ArrowBackIosRounded"}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </IconButton>
                )}{" "}
                {isEditingBroadcast ? "Edit" : "Create"} Broadcast
              </p>
            </div>
            <div className="">
              <Box className="BtnsBox" display="flex" gap={"8px"} >
                {isView ? null : (
                  <AppButton
                    onClick={() =>
                      handleBackClick(formStep === 1 ? true : false)
                    }
                    variant="grey"
                    disabled={isEditingBroadcast}
                  >
                    {formStep === 1 ? "Reset" : "Back"}
                  </AppButton>
                )}
                {isView ? (
                  <AppButton
                    onClick={() => navigate("/broadcast/sms/scheduled")}
                    variant="grey"
                  >
                    Back
                  </AppButton>
                ) : null}
                {formStep === 4 ? (
                  <AppSubmitButton
                    title={
                      isEditingBroadcast
                        ? "Update"
                        : values.when_to_send === "broadcast_now"
                          ? "Broadcast Now"
                          : "Schedule Broadcast"
                    }
                  />
                ) : isView ? null : (
                  <AppSubmitButton
                    title="continue"
                    disabled={!smsTemplateListData?.length || ((formStep === 3 && (!broadcastCreditDetails?.valid)) || (broadcastCreditDetails?.credits_balance <= 0)) ? true : false}
                  />
                )}
              </Box>

            </div>
          </SMSBoxTopbar>


          <SMSBoxStepperbar>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              {BreadCrumbsItems?.map((item: BreadCrumbItem) => {
                if (isView && item?.id === 1) {
                  return (
                    <BreadcrumbsItem iconColorCondition={item?.id === formStep} key={item?.id}>
                      <div className="icon">{item?.icon}</div>
                      <div className="text">
                        <p>{item?.name}</p>
                      </div>
                    </BreadcrumbsItem>
                  );
                } else if (!isView) {
                  return (
                    <BreadcrumbsItem iconColorCondition={item?.id === formStep} key={item?.id}>
                      <div className="icon">{item?.icon}</div>
                      <div className="text">
                        <p>{item?.name}</p>
                      </div>
                    </BreadcrumbsItem>
                  );
                }
              })}
            </Breadcrumbs>

            {/* {formStep === 4 ? (
              <AppButton
                variant="outline"
                endIcon="ri-arrow-right-s-line"
                onClick={() => dispatch(testSMSData(true))}
              >
                Test SMS
              </AppButton>
            ) : null} */}
          </SMSBoxStepperbar>

          <StyledContentBox>
            <div className="formBox create-sms-broadcast" style={{ height: formStep === 1 ? "calc(100% - 222px)" : "", width: formStep === 2 ? "98%" : "65%" }}>
              {formStep === 3 ? (
                <StyledCreditBox>
                  <h3 className="titleText">Review Cost</h3>
                  <BroadcastCreditDetails creaditsData={broadcastCreditDetails} ></BroadcastCreditDetails>
                </StyledCreditBox>
              ) : null
              }

              {formStep === 1 && (
                <RenderFormStep1
                  setFormStep={setFormStep}
                  formStep={formStep}
                  isView={isView}
                />
              )}
              {formStep === 2 && <RenderFormStep2 />}
              {formStep === 3 && (
                <RenderFormStep3
                  setFormStep={setFormStep}
                  formStep={formStep}
                />
              )}
              {formStep === 4 && (
                <RenderFormStep4
                  setFormStep={setFormStep}
                  formStep={formStep}
                />
              )}
              <AppFormObserver setFormikProps={setFormikProps} />
            </div>

            {/* PREVIEW WIDGET */}
            <BroadcastPreview
              primaryColor={SMS_PRIMARY_COLOR}
              previewType="SMS"
              bodyValue={tempalteData}
            />
          </StyledContentBox>
          <Dialog
            open={isLimitModalOpen}
            onClose={handleCloseModal}
            PaperProps={{
              sx: style,
            }}
          >
            <DialogTitle>List Limit Reached</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You've reached the contact limit set by SMS. The current limit is {limitCount}  contacts.
                Please change the contact list to proceed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Box display="flex">
                <AppButton
                  variant="cta"
                  onClick={handleCloseModal}
                  endIcon="ok"
                >
                  OK
                </AppButton>
              </Box>
            </DialogActions>
          </Dialog>
          <AppModel
            isShowing={isShowing}
            onClose={() => setShowing(false)}
            divStyle={{
              padding: 0,
            }}
            PaperProps={{
              style: {
                boxShadow: "none",
                width: "450px",
                height: "150px",
              },
            }}
          >
            <ModalContainer>
              <div className="header">
                <h4>Broadcast now!</h4>
                <div className="header-right">
                  <span onClick={() => setShowing(false)}>
                    <AppMaterialIcons iconName="close" />
                  </span>
                </div>
              </div>
              <div className="body">
                <p>Are you sure, you want to broadcast this message now?</p>
              </div>
              <div className="footer">
                <AppButton
                  variant="grey"
                  onClick={() => {
                    setShowing(false);
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  variant="default"
                  onClick={() => {
                    const payloadData = payloadObject(broadcastNowPayload);
                    const payload = {
                      ...payloadData,
                      broadcast_name: broadcastNowPayload?.broadcast_name.replace(/\s+/g, " ").trim() || "",
                      broadcast_template_id: broadcastNowPayload?.broadcast_template_id,
                      template_title: broadcastNowPayload?.template?.original_response?.template_title || "",
                      marketing_list_id: broadcastNowPayload?.marketing_list || "",
                      marketing_list_name: broadcastNowPayload?.marketing_list_name || "",
                      broadcast_action: broadcastNowPayload?.broadcast_action || "marketing_list",
                      filter_query: filterQuery,
                      mongodb_query: convertQueryToMongo(filterQuery),
                      scheduled_now: broadcastNowPayload?.when_to_send === "broadcast_now",
                      search: "",
                      // scheduled_date: broadcastNowPayload?.date,
                    };
                    setDuplicateName(payload.broadcast_name)
                    setPendingPayload(payload);
                    SMSNetworkService.postSmsBroadcast(payload)
                      .then((response: any) => {
                        if (response.data && response.data.status) {
                          showAlert("Broadcast created successfully", "success");
                          navigate("/broadcast/sms/history");
                        }
                      })
                      .catch(handleError)
                      .finally(() => {
                        setLoading(false);
                      });
                  }}
                >
                  Broadcast Now
                </AppButton>
              </div>
            </ModalContainer>
          </AppModel>
          <Dialog
            open={openNameChangeDialog}
            // onClose={handleCloseNameDialog}
            PaperProps={{
              sx: style,
            }}
          >
            <DialogTitle>Change Broadcast Name</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter a different name  for your broadcast as this  name is already in use.
              </DialogContentText>
              <AppFormFastField
                name={'broadcast_name'}
                label={"Broadcast Name"}
                placeholder="Broadcast Name"
                disabled={isView ? true : false}
                require={true}
              />
            </DialogContent>
            <DialogActions>
              <Box display="flex" gap={2}>
                <AppButton
                  variant="grey"
                  onClick={handleCloseNameDialog}
                  style={{ padding: "11px 20px 11px" }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  variant="cta"
                  onClick={() => { submitBroadcastWithNewName() }}
                  disabled={!getIn(values, "broadcast_name")?.trim()}
                >
                  Submit
                </AppButton>
              </Box>
            </DialogActions>
          </Dialog>
        </ProtectedAppForm>
      ) : (
        <div style={{ height: "calc(100vh - 240px)" }}>
          <BroadcastNoData
            title="No Templates Found"
            subtitle="Please create at least one template to create the broadcast."
            onClick={handleButtonClick}
            buttonText="Create Template"
          />
        </div>
      )}
    </StyledCreateBroadcastWrap>
  );
};

export default HocBackdropLoader(BroadcastCreate);

const ModalContainer = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.875rem;
    height:40px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
      h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .body{
    p {
        padding: 0.875rem;
        color: ${theme.palette.default.darkGrey};
      }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;
const style = {
  position: 'fixed' as 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '662px',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '3px',
};
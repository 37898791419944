import { getIn } from "formik";
import moment from "moment";
import uniqid from "uniqid";
import * as Yup from "yup";
export const BROADCAST_SMS_FORM_DATA = "BROADCAST_SMS_FORM_DATA";
const VARIABLE_REGEX = /\{{([^)]+)\}}/gm;

export const broadcastActionToggleOptions: IToggleFieldOptions[] = [
  {
    name: "Broadcast to Contacts",
    value: "broadcast_to_contacts",
    icon: "Group",
    id: uniqid(),
    disabled: false,
  },
  {
    name: "Broadcast Marketing list",
    value: "broadcast_marketing_list",
    icon: "BallotOutlined",
    id: uniqid(),
    disabled: false,
  },
];
export const broadcastSendOptions: any[] = [
  {
    name: "Broadcast now",
    value: "broadcast_now",
    id: uniqid(),
  },
  {
    name: "Schedule Broadcast",
    value: "schedule_broadcast",
    id: uniqid(),
  },
];
export const CheckboxTableColumns: any = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "name",
  },
  {
    id: "age",
    numeric: true,
    disablePadding: false,
    label: "Age",
  },
  {
    id: "number",
    numeric: true,
    disablePadding: false,
    label: "number",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
];

export const smsDefaultCategoryOptions = [
  {
    id: uniqid(),
    name: "Marketing",
    value: "marketing",
    description: null,
  },
];
export const smsDefaultLanguageOptions = [
  {
    id: uniqid(),
    name: "English",
    value: "en",
    description: null,
  },
];

export const MediaObj: any = ["image", "document", "video"];

export const mediaVarible: any = [
  {
    key: "{{1}}",
    value: "",
    index: 0,
    type: "media",
  },
];

export const smsTemplateHeaderToggleOptions: IToggleFieldOptions[] = [
  {
    name: "None",
    value: "none",
    icon: "CloseOutlined",
    id: uniqid(),
  },
  {
    name: "Text",
    value: "text",
    icon: "Assignment",
    id: uniqid(),
  },
  {
    name: "Image",
    value: "image",
    icon: "ImageRounded",
    id: uniqid(),
  },
  {
    name: "Video",
    value: "video",
    icon: "VideocamOffRounded",
    id: uniqid(),
  },
  {
    name: "Document",
    value: "document",
    icon: "DescriptionOutlined",
    id: uniqid(),
  },
];

// export const whatsappTemplateButtonTypeOptions: string[] = [
//   "none",
//   "Quick replies",
//   "Call to action",
// ];

export const smsTemplateButtonTypeOptions: any[] = [
  {
    name: "None",
    value: "none",
    id: uniqid(),
  },
  {
    name: "Quick replies",
    value: "quick_reply",
    id: uniqid(),
  },
  {
    name: "Call to action",
    value: "call_to_action",
    id: uniqid(),
  },
];

export const smsDefaultButtonObject: IWhatsappDefaultButtonType = {
  action_type: null,
  button_text: "Click now",
  url_type: null,
  url: "",
  phone_number: "",
};

export const smsCtaActionType = [
  {
    id: uniqid(),
    name: "Visit Website",
    value: "URL",
    description: null,
  },
  {
    id: uniqid(),
    name: "Call Phone",
    value: "PHONE_NUMBER",
    description: null,
  },
];
export const smsCtaValueAction = [
  {
    id: uniqid(),
    name: "Static",
    value: "static",
    description: null,
  },
  {
    id: uniqid(),
    name: "Dynamic",
    value: "dynamic",
    description: null,
  },
];

export const smsCreateTemplateSchema = Yup.object().shape({
  template_name: Yup.string()
    .ensure()
    .required()
    .min(2)
    .max(100)
    .label("Question"),
  template_category: Yup.string()
    .ensure()
    .required()
    .min(2, "Category is a required field")
    .label("Category"),
  template_language: Yup.string()
    .ensure()
    .required()
    .min(2, "Language is a required field")
    .label("Language"),
  header_type: Yup.mixed().required().label("Header type"),
  header_value: Yup.mixed()
    .label("Header value")
    .when(["header_type"], (key1: any, schema: any) => {
      return key1?.value === "none"
        ? schema.notRequired()
        : schema.required(`${key1?.name} is a required field.`);
    }),

  body_value: Yup.string().ensure().required().min(1).max(1024).label("Body"),

  footer_value: Yup.string().ensure().max(60).label("Footer"),

  buttons_type: Yup.mixed().default("none").required().label("Button"),

  quick_buttons: Yup.array()
    .of(
      Yup.object({
        button_text: Yup.string()
          .ensure()
          .min(1)
          .max(60)
          .required()
          .label("Button Text"),
      })
    )
    .ensure()
    .max(3)
    .label("Quick replies")
    .when(["buttons_type"], (key1: any, schema: any) => {
      return key1?.value === "quick_reply"
        ? schema.min(1).required()
        : schema.optional();
    }),

  cta_buttons: Yup.array()
    .of(
      Yup.object({
        action_type: Yup.string()
          .ensure()
          .required()
          .min(2, "Action type is a required field")
          .max(60)
          .label("Action type"),
        button_text: Yup.string()
          .ensure()
          .required()
          .min(1)
          .max(60)
          .label("Button Text"),
        url_type: Yup.string()
          .ensure()
          .max(60)
          .label("Value type")
          .when(["action_type"], (key1: any, schema: any) => {
            return key1 === "URL"
              ? schema.required().min(2, "Value type is a required field")
              : schema;
          }),
        url: Yup.string()
          .ensure()
          .max(100)
          .label("Link")
          .when(
            ["action_type", "url_type"],
            (key1: any, key2: any, schema: any) => {
              if (key1 === "URL" && key2 === "dynamic") {
                return schema
                  .required()
                  .min(1, "URL is a required field")
                  .matches(VARIABLE_REGEX, "Dynamic variable required");
              }

              if (key1 === "URL" && key2 === "static") {
                return schema
                  .url()
                  .required()
                  .min(1, "URL is a required field");
              }

              return schema;
            }
          ),
        phone_number: Yup.string()
          .ensure()
          .max(19)
          .label("Mobile")
          .when(["action_type"], (key1: any, schema: any) => {
            return key1 === "PHONE_NUMBER"
              ? schema.required().min(1, "Invalid Mobile format")
              : schema;
          })
          .test("strongPhoneTest", null, (value: any, ctx: any) => {
            //* used to access nested objects
            let topParentArrays: any = ctx.from;
            let parentValues: any = topParentArrays?.at(-1)?.value;
            if (!parentValues || !value) {
              return true;
            }

            let mobileCountryFormat: any = parentValues?.mobileCountryFormat;
            let format: any = mobileCountryFormat?.format
              ?.replaceAll(".", "0")
              ?.replaceAll(" ", "")
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll("-", "")
              ?.replaceAll("+", "");
            let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;
            let formatWithoutDialCode: any = format?.substring(dialCodeLength);
            let valueWithoutDialCode: any = value?.substring(dialCodeLength);

            if (
              formatWithoutDialCode?.length === valueWithoutDialCode?.length
            ) {
              let count = 0;
              for (var i = 0; i < valueWithoutDialCode?.length; i++) {
                if (
                  valueWithoutDialCode.charAt(i) === 0 ||
                  valueWithoutDialCode.charAt(i) === "0"
                ) {
                  count = count + 1;
                }
              }
              if (formatWithoutDialCode?.length == count) {
                return ctx.createError({
                  message: "Please enter valid Mobile number",
                });
              } else {
                return true;
              }
            } else {
              // let button =
              //   parentValues?.button_type === "Call to action"
              //     ? parentValues?.cta_buttons
              //     : parentValues?.quick_buttons;
              // button &&
              //   button?.length &&
              //   button?.forEach((curItem: any) => {
              //     if (curItem?.action_type === "PHONE_NUMBER") {
              //       return ctx.createError({
              //         message: `Please enter valid Mobile format for ${mobileCountryFormat.name} ${formatWithoutDialCode?.length} digits required`,
              //       });
              //     } else {
              //       return true;
              //     }
              //   });
              return true;
              // return ctx.createError({
              //   message: `Please enter valid Mobile format for ${mobileCountryFormat.name} ${formatWithoutDialCode?.length} digits required`,
              // });
            }
          }),
      })
    )
    .ensure()
    .max(3)
    .label("Call to action")
    .when(["buttons_type"], (key1: any, schema: any) => {
      return key1 === "Call to action"
        ? schema.min(1).required()
        : schema.optional();
    })
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }
      let a: any = value?.[0]?.["action_type"];
      let b: any = value?.[1]?.["action_type"];
      if (
        parentValues?.buttons_type === "Call to action" &&
        a &&
        b &&
        a === b
      ) {
        return ctx.createError({
          message: `Different action type required for the buttons.`,
        });
      }

      return true;
    }),

  header_text: Yup.array().ensure(),
  body_text: Yup.array().ensure(),
  footer_text: Yup.array().ensure(),
  buttons_example: Yup.array().ensure(),

  variables_header: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(1)
    .label("Header variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }

      let variableLength: number = parentValues?.header_text?.length || 0;
      if (variableLength === value?.length) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Header variables sample data is a required field`,
          null,
          "form"
        );
      }
    }),
  variables_body: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(10)
    .label("Body variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }

      let variableLength: number = parentValues?.body_text?.length || 0;
      if (variableLength === value?.length) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Body variables sample data is a required field`,
          null,
          "form"
        );
      }
    }),
  variables_footer: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(1)
    .label("Footer variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }

      let variableLength: number = parentValues?.footer_text?.length || 0;
      if (variableLength === value?.length) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Footer variables sample data is a required field`,
          null,
          "form"
        );
      }
    }),
  variables_button: Yup.array()
    .of(Yup.string().required().min(1).max(30).label("Sample value"))
    .ensure()
    .max(1)
    .label("Button variables")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      //* used to access nested objects
      let topParentArrays: any = ctx.from;
      let parentValues: any = topParentArrays?.at(-1)?.value;
      if (!parentValues) {
        return true;
      }

      let variableLength: number = parentValues?.buttons_example?.length || 0;
      if (variableLength === value?.length) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Button variables sample data is a required field`,
          null,
          "form"
        );
      }
    }),
});

export const SMSBroadcastScheduleStep1Schema = Yup.object().shape({
  broadcast_name: Yup.string()
    .ensure()
    .required()
    .min(3)
    .max(100)
    .label("Name"),
  broadcast_template_id: Yup.string()
    .ensure()
    .required()
    .min(2, "Template is a required field")
    .label("Template"),
    sender_id: Yup.string()
    .ensure()
    .required()
    .min(2, "sender id is a required field")
    .label("Sender ID"),
});

export const SMSBroadcastScheduleStep2Schema = Yup.object().shape({
  // // broadcast_action: Yup.mixed().required().label("Action"),
  // // selected_rows: Yup.number().required().min(1,"Please select atleast 1 audience.").label("Contact"),
  // // marketing_list: Yup.string()
  // //   .ensure()
  // //   .label("List")
  // //   .when(["broadcast_action"], (key1: any, schema: any) => {
  // //     return key1?.value === "broadcast_marketing_list"
  // //       ? schema.required().min(2, "List is a required field")
  // //       : schema.optional();
  // //   }),

  // broadcast_action: Yup.mixed().required().label("Action"),
  // //selected_rows: Yup.array().required().min(1).label("Row selection"),
  // marketing_list: Yup.string()
  //   .ensure()
  //   .label("List")
  //   .when(["broadcast_action"], (key1: any, schema: any) => {
  //     return key1?.value === "broadcast_marketing_list"
  //       ? schema.required().min(1, "List is a required field")
  //       : schema.optional();
  //   }),

    broadcast_action: Yup.mixed()
      .required("Action is required")
      .label("Action"),
  
    formkey8: Yup.object().shape({
      totalContacts: Yup.number()
        .required("Total contacts is required")
        .min(1, "At least one contact must be present"),
    }),
  
    marketing_list: Yup.string()
      .ensure()
      .label("List")
      .when("broadcast_action", {
        is: (value :any) => value === "broadcast_marketing_list",
        then: (schema :any) => schema.required("List is a required field"),
        otherwise: (schema:any) => schema.optional(),
      }),
});
  
 

export const SMSBroadcastScheduleStep3Schema = Yup.object().shape({
  resolved_body_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .min(2, "Field is a required field")
          .max(60)
          .required()
          .label("Field"),
        value: Yup.string().ensure().min(1).max(60).required().label("Value"),
      })
    )
    .label("Body variables"),

  eligiable_contact: Yup.number().required().min(1,"Please select atleast 1 audience.").label("Contact"),

  resolved_header_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .max(60)
          .label("Field")
          .when(["type"], (key1: any, schema: any) => {
            return key1 !== "text"
              ? schema.optional().notRequired()
              : schema.required().min(2, "Field is a required field");
          }),
        value: Yup.string()
          .ensure()
          .min(1)
          .required()
          .label("Value")
          .when(["type"], (key1: any, schema: any) => {
            return key1 === "media" ? schema.url() : schema.required();
          }),
      })
    )
    .max(1)
    .label("Header variables"),
  resolved_buttons_variables: Yup.array()
    .of(
      Yup.object({
        value: Yup.string().ensure().min(1).max(60).required().label("Value"),
      })
    )
    .max(1)
    .label("Button variables"),
});

export const SMSBroadcastScheduleStep4Schema = Yup.object().shape({
  when_to_send: Yup.mixed().required().label("Action"),
  date: Yup.mixed()
    .label("Date")
    .when(["when_to_send"], (key1: any, schema: any) => {
      return key1?.value === "schedule_broadcast"
        ? schema.required()
        : schema.notRequired();
    }),
  time: Yup.mixed()
    .label("Time")
    .when(["when_to_send"], (key1: any, schema: any) => {
      return key1?.value === "schedule_broadcast"
        ? schema.required()
        : schema.notRequired();
    }),
});


import { GridColDef } from "@mui/x-data-grid";
import uniqid from "uniqid";
export const broadcastSendOptions: any[] = [
    {
      name: "Broadcast now",
      value: "broadcast_now",
      id: uniqid(),
    },
    {
      name: "Schedule Broadcast",
      value: "schedule_broadcast",
      id: uniqid(),
    },
  ];
  export const broadcastActionToggleOptions: IToggleFieldOptions[] = [
    {
      name: "Broadcast to Contacts",
      value: "broadcast_to_contacts",
      icon: "Group",
      id: uniqid(),
      disabled: false,
    },
    {
      name: "Broadcast Marketing list",
      value: "broadcast_marketing_list",
      icon: "BallotOutlined",
      id: uniqid(),
      disabled: false,
    },
  ];
  export const SmsColumns: GridColDef[] = [
    {
      field: "first_name", headerName: "First Name", width: 190, sortable: false,
    },
    {
      field: "last_name", headerName: "Last Name", width: 190, sortable: false,
    },
    {
      field: "email", headerName: "Primary Email", width: 280, sortable: false,
    },
    {
      field: "mobile_phone_number",
      headerName: "Mobile",
      sortable: false,
      width: 190,
    }
  ];
  export const marketColumns: GridColDef[] = [
    {
      field: "first_name", headerName: "First Name", width: 190, sortable: false,
    },
    {
      field: "last_name", headerName: "Last Name", width: 190, sortable: false,
    },
    {
      field: "email", headerName: "Primary Email", width: 280, sortable: false,
    },
    {
      field: "mobile_phone_number",
      headerName: "Mobile",
      sortable: false,
      width: 190,
    },
    // {
    //  // field: "sms_subscription_status",
    //  // headerName: "sms Subscription Status",
    //   sortable: false,
    //   width: 190
    // },
  ];

 export function convertToK(number:any) {
  const units = ['', 'K', 'M', 'B', 'T']; // Add more units if needed
  let unitIndex = 0;
  
  while (number >= 1000 && unitIndex < units.length - 1) {
      number /= 1000;
      unitIndex++;
  }
  
  return number + units[unitIndex];
}
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const AutomationWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
    border-bottom: 2px solid ${theme.palette.default.lightGrey};
  }
`;

export const AutomationSubWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  height: 61px;
  .header {
    height: auto;
  }
  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
  }
  .custom-btn {
    padding: 0;
  }
  .left-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .left-part {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .menu-right {
    display: flex;
    align-items: center;
  }
  .data-container {
    background: ${theme.palette.default.white};
    height: 70vh;
    margin: 1rem 1rem 0;
  }
  .image {
    padding-top: 24px;
    padding-left: 3rem;
  }
  .norecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
  }
  .data-details {
    display: flex;
    justify-content: center;
    padding-top: 120px;
    gap: 72px;
  }
  .data-details p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.darkGrey};
  }
  .Import-details {
    width: 640px;
  }
  .Import-details button {
    width: 148px;
  }
  .Import-details li {
    margin-bottom: 1rem;
  }
  .Import-details p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.black};
  }
  .button {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .button button {
    /* padding: 0; */
  }
  .norecord-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 128px;
  }
  .norecord-data p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.default.darkGrey};
    text-transform: capitalize;
  }
  .add-list {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;
  }
  .add-list button {
    width: 148px;
  }
  .add-list p {
    width: 1115px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${theme.palette.default.black};
  }
`;

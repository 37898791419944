import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SMSActions } from "./sms.actions";
// import { broadcastActions } from "./broadcast.actions";

interface initialStateTypes {
  data: {
    filteredData: any;
    smsTemplateListCount: any;
    getSMSAnalyticsListData: any;
    smsTemplateListData: any;
    smsAnalyticsListData: any;
    templateByIdData: any;
    tagListOptionsData: any;
    categoriesListOptionsData: any;
    categoriesPaginationData: any;
    SMSSenderIDListData: any;
    filteredDataTotalCount: number;

    postSMSBroadcastData: any;
    broadcastByIdData: any;
    templateUploadedData: any;
    testSMSModel: any;
    testSMSSucessMessage: any;
    deleteError: string;
    deleteSuccess: string;
    smsEnable: boolean;
    smsBroadcastData : any;
    smstemplatePagination: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    filteredData: null,
    smsTemplateListCount: null,
    smsTemplateListData: null,
    getSMSAnalyticsListData: null,
    smsAnalyticsListData: null,
    templateByIdData: null,
    tagListOptionsData: null,
    categoriesListOptionsData: [],
    categoriesPaginationData: {},
    SMSSenderIDListData: null,
    postSMSBroadcastData: null,
    broadcastByIdData: null,
    templateUploadedData: null,
    filteredDataTotalCount: 0,
    testSMSModel: false,
    testSMSSucessMessage: null,
    deleteError: "",
    deleteSuccess: "",
    smsEnable: false,
    smstemplatePagination: {},
    smsBroadcastData : {}
  },
  error: "",
  loading: false,
};

const SMSSlice = createSlice({
  name: "SMSSlice",
  initialState,
  reducers: {
    isSmsEnabled: (state: any, action: PayloadAction<any>) => {
      state.data.smsEnable = action.payload;
      return state;
    },
    resetTemplateData: (state: any, action: PayloadAction<any>) => {
      state.data.templateByIdData = null;
      state.data.testSMSModel = false;
      return state;
    },
    testSMSData: (state: any, action: PayloadAction<any>) => {
      state.data.testSMSModel = action.payload;
      return state;
    },
    resetError: (state: any, action: PayloadAction<any>) => {
      state.data.deleteError = action.payload;
      state.data.deleteSuccess = action.payload;

      return state;
    },
    setSmsBroadcastData : (state: any, action: PayloadAction<any>) => {
      state.data.smsBroadcastData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder

      //  GET SMS Template List Data

      .addCase(SMSActions.getSMSTemplateListData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      // action.payload?.data?.results;
      .addCase(
        SMSActions.getSMSTemplateListData.fulfilled,
        (state, action) => {
          state.data.smstemplatePagination = action.payload.data?.pagination;
          state.data.filteredData = action.payload.data?.results;
          state.data.smsTemplateListData = action.payload.data?.results;
          state.data.smsTemplateListCount = action?.payload?.data?.count;
          state.loading = false;
        }
      )
      .addCase(
        SMSActions.getSMSTemplateListData.rejected,
        (state, action) => {
          state.data.smsTemplateListData = [];
          state.error = action?.error?.message || "";
          state.loading = false;
        }
      )

      // get sms tag list data

      .addCase(
        SMSActions.getSMSTemplateTagListOptions.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      // action.payload?.data?.results;
      .addCase(
        SMSActions.getSMSTemplateTagListOptions.fulfilled,
        (state, action) => {
          state.data.tagListOptionsData = action.payload.data?.results;
        },
      )
      .addCase(
        SMSActions.getSMSTemplateTagListOptions.rejected,
        (state, action) => {
          state.loading = false;
          state.data.smsTemplateListData = [];
          state.error = action?.error?.message || "";
        },
      )
      //  GET SMS sender ID List Data

      .addCase(SMSActions.getSMSSenderIDListData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SMSActions.getSMSSenderIDListData.fulfilled, (state, action) => {
        state.data.SMSSenderIDListData = action.payload.data?.response;
        state.error = "";
        state.loading = false;
      })
      .addCase(SMSActions.getSMSSenderIDListData.rejected, (state, action) => {
        state.loading = false;
        state.data.SMSSenderIDListData = null;
        state.error = action?.error?.message || "";
      })

      //* template by id
      .addCase(SMSActions.getTemplateById.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SMSActions.getTemplateById.fulfilled, (state, action) => {
        state.data.templateByIdData = action.payload;
        state.error = "";
        state.loading = false;
      })
      .addCase(SMSActions.getTemplateById.rejected, (state, action) => {
        state.loading = false;
        state.data.templateByIdData = null;
        state.error = action?.error?.message || "";
      })

      //* post SMS broadcast data
      .addCase(SMSActions.postSMSBroadcast.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SMSActions.postSMSBroadcast.fulfilled, (state, action) => {
        state.data.postSMSBroadcastData = action?.payload?.data;

        state.error = "";
        state.loading = false;
      })
      .addCase(SMSActions.postSMSBroadcast.rejected, (state, action) => {
        state.loading = false;
        state.data.postSMSBroadcastData = null;
        state.error = action?.error?.message || "";
      })

      //get delete broadcast list
      .addCase(SMSActions.deleteSmsBroadcastById.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SMSActions.deleteSmsBroadcastById.fulfilled, (state, action) => {
        state.data.deleteSuccess =
          action?.payload?.data?.message || "Broadcast Deleted Successfully";
        state.data.deleteError = "";
        state.loading = false;
      })
      .addCase(
        SMSActions.deleteSmsBroadcastById.rejected,
        (state, action: any) => {
          state.loading = false;
          state.data.deleteSuccess = "";
          state.data.deleteError =
            action?.payload?.data?.message || "Failed to Delete Broadcast";
        },
      )

      //  GET SMS BroadCast List Data

      .addCase(SMSActions.getSMSAnalyticsListData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        SMSActions.getSMSAnalyticsListData.fulfilled,
        (state, action) => {
          state.data.filteredData = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(SMSActions.getSMSAnalyticsListData.rejected, (state, action) => {
        state.loading = false;
        state.data.filteredData = null;
        state.error = action?.error?.message || "";
      })

      //* SMS broadcast by id
      .addCase(SMSActions.getBroadcastById.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SMSActions.getBroadcastById.fulfilled, (state, action) => {
        state.data.broadcastByIdData = action.payload?.data;
        state.error = "";
        state.loading = false;
      })
      .addCase(SMSActions.getBroadcastById.rejected, (state, action) => {
        state.loading = false;
        state.data.broadcastByIdData = null;
        state.error = action?.error?.message || "";
      })

      //* uploadDocumentFileData
      .addCase(SMSActions.uploadSMSDocumentFile.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SMSActions.uploadSMSDocumentFile.fulfilled, (state, action) => {
        state.data.templateUploadedData = action.payload.data?.response;
        state.error = "";
        state.loading = false;
      })
      .addCase(SMSActions.uploadSMSDocumentFile.rejected, (state, action) => {
        state.loading = false;
        state.data.templateUploadedData = null;
        state.error = action?.error?.message || "";
      })

      //* categories list data
      .addCase(SMSActions.getSMSTemplateCategories.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        SMSActions.getSMSTemplateCategories.fulfilled,
        (state, action) => {
          state.data.categoriesListOptionsData = action.payload.data?.results;
          state.data.categoriesPaginationData = action.payload.data?.pagination;
        },
      )
      .addCase(
        SMSActions.getSMSTemplateCategories.rejected,
        (state, action) => {
          state.loading = false;
          state.data.smsTemplateListData = null;
          state.error = action?.error?.message || "";
        },
      )

      //* test sms send
      .addCase(SMSActions.postTestSMS.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SMSActions.postTestSMS.fulfilled, (state, action) => {
        state.data.testSMSSucessMessage = action.payload.data?.message;
      })
      .addCase(SMSActions.postTestSMS.rejected, (state, action) => {
        state.loading = false;
        state.data.testSMSSucessMessage = null;
        state.error = action?.error?.message || "";
      });
  },
});
export const { resetTemplateData, testSMSData, resetError, isSmsEnabled,setSmsBroadcastData } =
  SMSSlice.actions;
export default SMSSlice.reducer;

import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Drawer,
  List,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import type { RuleGroupType } from "react-querybuilder";
import AppButton from "../../AppButton";
import { AppMaterialIcons } from "../../AppMaterialIcons";
import AppErrorMessage from "../AppErrorMessage";
import {
  StyledQueryBuilderWrap, StyledModelHeader
} from "./Styled";
import "react-querybuilder/dist/query-builder.scss";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { contactListStore } from "../../../Store/Slices/ContactList/ContactList.selectors";
import { AppQueryBuilder } from "../../QueryBuilder";
import { StyledModelFooter } from "../../QueryBuilder/Styled";
import {
  enabledViewsListing,
  getFilterQuery,
  selectedContactViewState,
} from "../../../Store/Slices/Contacts/contacts.selector";
import { useQueryBuilderStore } from "../../../Store/Slices/QueryBuilder/QueryBuilder.selectors";
import { QueryBuilderActions } from "../../../Store/Slices/QueryBuilder/QueryBuilder.actions";
import { setFilterQuery } from "../../../Store/Slices/Contacts/contacts.slice";
// Utility function to convert custom fields
const convertCustomFields = (fields: any[], operators: any, options: any) => {
  let customFields: any = [];
  fields?.map((item: any, index: number) => {
    if (item?.type === "datetime") {
      item.type = "date";
    }
    if (item?.type === "dropdown" || item?.type === "multiselect") {
      item.type = "select";
    }
    if (item?.type === "radio") {
      item.type = "select";
    }
    if (item?.type === "checkbox") {
      item.type = "select";
    }
    let temp: any = {
      ...item,
      operators: operators?.[item?.type],
      valueEditorType: item?.type,
      values: options,
      inputType: item?.type,
      validator: item?.type === "number" ? "number" : "text",
    };
    customFields?.push(temp);
  });
  return customFields;
};
const initialQuery: RuleGroupType = {
  combinator: "$and",
  rules: [
    // {
    //     "id": "e0f147ef-b92a-4a86-9d8e-340c9efb380d",
    //       "field": "marketing_list",
    //       "operator": "in",
    //       "valueSource": "value",
    //    //"value":["MARKETING_LIST_4Xwikjd5"]
    //  "value":[],

    // }
  ],
};
interface Props {
  name: string;
  fields: any[];
  operators: any;
  toggle: (val?: boolean | undefined) => void;
  onChangeCallbackFn?: (value: any) => void;
  children?: React.ReactNode;
  [otherProps: string]: any;
  type?: any
}
const AppFormQueryBuilder: React.FC<Props> = ({
  name,
  isShowing,
  toggle,
  operators,
  fields,
  onChangeCallbackFn,
  type
}) => {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext<any>();
  const filterQuery = useAppSelector(getFilterQuery);
  const [optionsValue, setOptionsValue] = useState<any>([]);
  const [customFields, setCustomFields] = useState<any>(convertCustomFields(fields, operators, optionsValue));
  const [query, setQuery] = useState<any>(initialQuery);
  const [isDocumentReady, setIsDocumentReady] = useState<boolean>(false);
  const { allContactListData } = useAppSelector(contactListStore);
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch()
  const { queryBuilderFields, queryBuilderOperators, loading } =
    useAppSelector(useQueryBuilderStore);
  const selectedContactView: any = useAppSelector(selectedContactViewState);
  const enabledViewsData: any = useAppSelector(enabledViewsListing);
  const [userInput, setUserInput] = useState({
    name: selectedContactView?.name,
    filter_query: filterQuery ? filterQuery : initialQuery,
  });
  useEffect(() => {
    setUserInput({
      name: selectedContactView?.name,
      filter_query: filterQuery ? filterQuery : initialQuery,
    })
  }, [filterQuery])
  useEffect(() => {
    if (!isShowing) {
      setIsDocumentReady(false);
      return;
    }
    if (getIn(values, name)) {
      setQuery(getIn(values, name) || initialQuery);
    }
    setTimeout(() => {
      if (customFields?.length) {
        setIsDocumentReady(true);
      } else {
        showAlert("Something went wrong", "error");
        setIsDocumentReady(true);
      }
    }, 900);
  }, [isShowing]);
  useEffect(() => {
    if (allContactListData) {
      let optionsList: any = [];
      allContactListData?.options?.forEach((item: any) => {
        let temp: any = {
          label: item?.label,
          name: item?.value,
        };
        optionsList.push(temp);
      });
      setOptionsValue(optionsList);
      let tempt: any = [...customFields];
      tempt.filter((ele: any) => ele?.id === allContactListData.id)[0].values = [...optionsList];
      setCustomFields([...tempt])
    }
  }, [allContactListData]);
  useEffect(() => {
    let module_id = "other";
    let payload = {
      id: module_id,
    };
    dispatch(QueryBuilderActions.getQueryBuilderMappingFields(payload));
  }, [])
  const handleSaveBtn = async () => {
    setFieldValue(name, query);
    setFieldTouched(name, true);
    await dispatch(setFilterQuery(query));
    if (onChangeCallbackFn) {
      onChangeCallbackFn(query);
    }
    toggle(false); // Close the drawer after saving
  };
  const onQueryChangeCallbackFn = (filtersValue: any) => {
    setFieldValue(name, filtersValue);
    setQuery(filtersValue);
  };
  return (
    <Drawer
      anchor="right"
      open={isShowing}
      onClose={() => toggle(false)}
      sx={{
        width: 400,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "65%",
          height: "100%",
          boxSizing: "border-box",
          padding: "1rem",
        },
      }}
    >
      <StyledModelHeader>
        <Box>
          <div>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Filter Data
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "red", mt: 1, marginBottom: "20px" }}
            >
              {type === "whatsapp" ? "Whatsapp Subscription status must be Yes" : type === "sms" ? "SMS Subscription status must be Yes" : type === "email" ? "Email Subscription status must be Yes" : null}
            </Typography>
          </div>
        </Box>
        <IconButton onClick={() => toggle(false)}>
          <AppMaterialIcons
            iconName={"Close"}
            style={{ fontSize: "1.2rem" }}
          />
        </IconButton>
      </StyledModelHeader>
      {isDocumentReady && customFields?.length ? (
        <StyledQueryBuilderWrap>
          <div style={{ height: "calc(90vh - 120px)" }}>
            <AppQueryBuilder
              fields={queryBuilderFields}
              operators={operators}
              onChangeCallbackFn={onQueryChangeCallbackFn}
              defaultInputValueFromApi={
                userInput?.filter_query?.id ? userInput?.filter_query : initialQuery
              }
            />
          </div>
          <AppErrorMessage
            error={getIn(errors, name)}
            visible={getIn(touched, name)}
          />
        </StyledQueryBuilderWrap>
      ) : (
        <Backdrop sx={{ color: "#fff", zIndex: 99999999999 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <StyledModelFooter>
        <Box display="flex" sx={{
          position: "absolute",
          bottom: "28px",
          right: "13px"
        }} className="footer-btns">
          <AppButton
            onClick={() => handleSaveBtn()}
            className="saveBtn"
            variant=""
            sx={{}}
          >
            {" "}
            Save
          </AppButton>
        </Box>
      </StyledModelFooter>
    </Drawer>
  );
};

export default AppFormQueryBuilder;